import React, { useState, useMemo, useEffect } from "react";
import { Button } from 'primereact/button';
import StatusService from "../../app/service/statusService";
import UserService from "../../app/service/userService.js";
import { useNavigate } from 'react-router-dom';
import { messageError, messageSuccess } from "../../components/toastr";

const AttendanceDT = (props) => {
    const navigate = useNavigate();
    const [statuses, setStatuses] = useState([]);
    const userService = useMemo(() => new UserService(), []);

    const handleCheckboxPresenceChange = (id) => {
        props.onSelectMember(id);
    };

    const handleCheckboxExcuseChange = (id) => {
        props.onSelectMemberExcuse(id);
    };

    useEffect(() => {
        const fetchData = async () => {

            const statusService = new StatusService();
            const statusPromises = props.members.map(member => statusService.getById(member.status.id));
            const statuses = await Promise.all(statusPromises);
            setStatuses(statuses);
        };

        fetchData();
    }, [props.members]);

    const rows = props.members.map((member, index) => {
        const status = statuses[index] ? statuses[index].data.strDescription : "Carregando...";

        return (
            <tr key={member.id}>
                <td style={{ width: '15%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                    <input
                        type="checkbox"
                        style={{ transform: 'scale(1.6)' }}
                        onChange={() => handleCheckboxPresenceChange(member.id)}
                        checked={props.selectedMembers.includes(member.id)}
                        disabled={props.selectedMembersExcuse.includes(member.id) || !props.canEdit}
                    />
                </td>
                <td style={{ width: '15%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                    <input
                        type="checkbox"
                        style={{ transform: 'scale(1.6)' }}
                        onChange={() => handleCheckboxExcuseChange(member.id)}
                        checked={props.selectedMembersExcuse.includes(member.id)}
                        disabled={props.selectedMembers.includes(member.id) || !props.canEdit}
                    />
                </td>
                <td style={{ width: '15%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>{member.cim}</td>
                <td style={{ width: '15%', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>{member.lodgeDegree}</td>
                <td style={{ width: '40%', paddingLeft: '20px', paddingRight: '80px' }}>{member.name}</td>
            </tr>
        );
    });

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Presença</th>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Abono</th>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>CIM</th>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Grau</th>
                    <th scope="col" style={{ width: '40%', textAlign: 'center' }}>Nome</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};

export default AttendanceDT;
