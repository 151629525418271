import React, { useState, useEffect, useMemo } from "react";
import { Row, Col } from 'react-bootstrap';
import PRMTSService from "../../app/service/prmtsService";
import LodgeRoleService from '../../app/service/lodgeRoleService';
import MemberService from "../../app/service/memberService";
import EventService from '../../app/service/eventService';
import { messageError } from "../../components/toastr";
import moment from 'moment';
import TitleLMPHB from '../../components/titleLMPHB';
import Container from '../../components/container';
import Card from "../../components/card";

function Activities() {
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const lodgeRoleService = useMemo(() => new LodgeRoleService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const eventService = useMemo(() => new EventService(), []);
    const [rolesWithMembers, setRolesWithMembers] = useState([]);
    const [nextEvent, setNextEvent] = useState(null);
    const [listMembers, setListMembers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseRoles = await lodgeRoleService.getAll();
                const roleData = responseRoles.data;
                const filteredRoles = roleData.filter(role => role.id >= 1 && role.id <= 7).sort((a, b) => a.id - b.id);

                const rolesWithMembers = await Promise.all(filteredRoles.map(async (role) => {
                    try {
                        const memberResponse = await memberService.getMemberByLodgeRole(role.id);
                        return {
                            roleId: role.id,
                            role: role.description,
                            member: memberResponse.data ? memberResponse.data.name : '',
                            phone: memberResponse.data ? memberResponse.data.phoneCell : ''
                        };
                    } catch (error) {
                        return {
                            roleId: role.id,
                            role: role.description,
                            member: ''
                        };
                    }
                }));
                setRolesWithMembers(rolesWithMembers);
                // Aqui adicionamos o nome e telefone no listMembers corretamente
                const responseMembers = await memberService.getAll();
                const memberOptions = responseMembers.data.map(member => ({
                    label: member.name,
                    value: member.id,
                    phone: member.phoneCell // Inclui o telefone aqui
                }));
                setListMembers(memberOptions); // Atualizamos listMembers com os dados completos


                const responseEvent = await eventService.getNextEvent(); // Suponha que existe uma função para buscar o próximo evento
                if (responseEvent.data) {
                    const event = responseEvent.data[0];
                    setNextEvent({
                        ...event,
                        start: new Date(event.dtStart[0], event.dtStart[1] - 1, event.dtStart[2], event.dtStart[3], event.dtStart[4]),
                        end: new Date(event.dtEnd[0], event.dtEnd[1] - 1, event.dtEnd[2], event.dtEnd[3], event.dtEnd[4]),
                    });

                }

            } catch (error) {
                messageError('Erro ao buscar dados: ' + error.message);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Container>
                <TitleLMPHB />
                <Row className="w-700" style={{ marginTop: '20px', marginLeft: '13%' }}>
                    <Col md={4} style={{ justifyContent: 'flex' }}>

                        <Card title={`Próxima Sessão`} style={{ backgroundColor: '#fff8dc' }}>
                            <div style={{ backgroundColor: '#fff8dc' }}>
                                {nextEvent && (
                                    <>
                                        <p><strong>Título:</strong> {nextEvent.description}</p>
                                        <p><strong>Data da Sessão:</strong> {nextEvent.start.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
                                        <p><strong>Horário:</strong> {moment(nextEvent.start).format('HH:mm') + 'h'} às {moment(nextEvent.end).format('HH:mm') + 'h'}</p>
                                        <p><strong>Ordem do Dia:</strong> {nextEvent.orderDay && nextEvent.orderDay.split('\n').map((line, index) => (
                                            <span key={index}>{line}<br /></span>
                                        ))}</p>
                                    </>
                                )}
                            </div>

                        </Card>

                    </Col>


                    <Col md={6}>
                        <Card title={`Contato`}>
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th>Cargo</th>
                                        <th>Irmão</th>
                                        <th>Telefone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rolesWithMembers.map((roleWithMember, index) => (
                                        <tr key={index}>
                                            <td>{roleWithMember.role}</td>
                                            <td>{roleWithMember.member}</td>
                                            <td>{roleWithMember.phone}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Card>
                    </Col>


                </Row>

            </Container >
        </>
    );
}

export default Activities;
