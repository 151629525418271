import ApiService from '../apiservice';

class SpouseService extends ApiService {

    constructor() {
        super('/spouse');
    }

    new(spouse) {
        return this.post(`${this.apiurl}/new`, spouse);
    }

    update(spouse) {
        return this.put(`${this.apiurl}/${spouse.id}`, spouse);
    }

    getSpousesByBirthdayMonth(month) {
        return this.get(`${this.apiurl}/birthday?month=${month}`);
    }

    getSpousesByBirthdayPeriod(startDate, endDate) {
        return this.get(`${this.apiurl}/birthday-period?startDate=${startDate}&endDate=${endDate}`);
    }

    getSpousesByMarriagePeriod(startDate, endDate) {
        return this.get(`${this.apiurl}/marriage-period?startDate=${startDate}&endDate=${endDate}`);
    }

    getSpousesByMarriageMonth(month) {
        return this.get(`${this.apiurl}/marriage?month=${month}`);
    }

    getById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }
    getSpouseByMember(id) {
        return this.get(`${this.apiurl}/member/${id}`);
    }

    findSpouses(SpouseFilter) {
        if (SpouseFilter.name ||
            SpouseFilter.member
        ) {
            let params = `?`;
            let count = 0
            if (SpouseFilter.name) {
                params += `name=${SpouseFilter.name}`;
                count++;
            }

            if (SpouseFilter.member) {
                if (count > 0)
                    params += `&`
                params += `member=${SpouseFilter.member}`
                count++;
            }

            return this.get(`${this.apiurl}${params}`)
        }
        return this.get(`${this.apiurl}/all`);
    };

    deleteSpouse(id) {
        return this.delete(`${this.apiurl}/${id}`);
    }
}
export default SpouseService;