import React, { useState, useEffect, useMemo } from "react";
import { Table, Form, Modal, Button } from 'react-bootstrap';
import SelectMenu from "../../components/selectMenu";
import { messageError, messageSuccess } from "../../components/toastr";
import TaxesPaymentsService from "../../app/service/taxesPaymentsService";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TaxPayments = ({ taxes, members }) => {
    const [selectedMember, setSelectedMember] = useState('');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [paymentDate, setPaymentDate] = useState(new Date());
    const taxesPaymentsService = useMemo(() => new TaxesPaymentsService(), []);

    const yearOptions = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear; i >= currentYear - 5; i--) {
            years.push(i);
        }
        return years;
    }, []);

    useEffect(() => {
        if (selectedMember && selectedYear) {
            fetchFilteredPayments();
        } else {
            setFilteredPayments([]);
        }
    }, [selectedMember, selectedYear]);

    const fetchFilteredPayments = async () => {
        try {
            const response = await taxesPaymentsService.filterPayments(selectedMember, selectedYear);
            const payments = response.data || [];

            const convertedPayments = payments.map(payment => ({
                ...payment,
                dtExpire: payment.dtExpire ? new Date(payment.dtExpire) : null,
                dtPayment: payment.dtPayment ? new Date(payment.dtPayment) : null,
            }));

            setFilteredPayments(convertedPayments);
        } catch (error) {
            console.error("Erro ao buscar pagamentos filtrados:", error);
            const errorMessage = error.response?.data || 'Ocorreu um erro ao buscar pagamentos.';
            messageError(errorMessage);
        }
    };

    const groupedData = useMemo(() => {
        const groups = {};
        filteredPayments.forEach(payment => {
            const tax = taxes.find(t => t.id === payment.tax);
            const taxGroup = tax ? tax.taxGroup : 'Outros';
            const description = tax ? tax.description : 'N/A';
            const taxId = tax ? tax.id : null;

            if (!groups[taxGroup]) groups[taxGroup] = {};
            if (!groups[taxGroup][description]) {
                groups[taxGroup][description] = { taxId: taxId, months: Array(12).fill(null) };
            }

            if (payment.dtExpire) {
                const month = payment.dtExpire.getMonth(); // Mês (0-11)
                groups[taxGroup][description].months[month] = payment;
            }
        });

        return groups;
    }, [filteredPayments, taxes]);

    const capitalizeFirstLetter = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    const getTextColor = (payment) => {
        if (!payment || !payment.dtExpire) return 'black'; // Em aberto
        const today = new Date();
        if (payment.dtPayment) return 'green'; // Pago
        if (payment.dtExpire < today) return 'red'; // Atrasado
        return 'black'; // Em aberto
    };

    const handleCellClick = (payment) => {
        setSelectedPayment(payment);
        setPaymentDate(new Date());
        setShowModal(true);
    };

    const handleConfirmPayment = async () => {
        if (selectedPayment) {
            try {
                const updatedPayment = { ...selectedPayment, dtPayment: paymentDate };

                console.log("selectedPayment.id:: ", selectedPayment.id);
                await taxesPaymentsService.updatePayment(selectedPayment.id, updatedPayment);

                messageSuccess('Pagamento atualizado com sucesso!');
                setShowModal(false);
                fetchFilteredPayments();
            } catch (error) {
                console.error("Erro ao atualizar pagamento:", error);
                const errorMessage = error.response?.data || 'Ocorreu um erro ao atualizar o pagamento.';
                messageError(errorMessage);
            }
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', gap: '20px', marginBottom: '20px' }}>
                <Form.Group controlId="selectMember">
                    <Form.Label>Membro:</Form.Label>
                    <SelectMenu
                        style={{ width: "25vw", height: "5vh" }}
                        className="form-control"
                        list={[
                            { label: "Selecione", value: "" },
                            ...members.map(member => ({ label: member.name, value: member.id }))
                        ]}
                        value={selectedMember}
                        onChange={(e) => setSelectedMember(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="selectYear">
                    <Form.Label>Ano:</Form.Label>
                    <SelectMenu
                        style={{ width: "10vw", height: "5vh" }}
                        className="form-control"
                        list={yearOptions.map(year => ({ label: year.toString(), value: year }))}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
                    />
                </Form.Group>
            </div>

            {filteredPayments.length > 0 ? (
                <>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Grupo</th>
                                <th>Descrição</th>
                                {[...Array(12).keys()].map(i => (
                                    <th key={i} style={{ textAlign: 'center' }}>
                                        {capitalizeFirstLetter(
                                            new Date(0, i).toLocaleString("pt-BR", { month: "long" })
                                        )}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupedData)
                                .sort((a, b) => b.localeCompare(a)) // Ordenação decrescente dos grupos
                                .map(group => {
                                    const descriptions = groupedData[group];
                                    const sortedDescriptions = Object.entries(descriptions)
                                        .sort((a, b) => a[1].taxId - b[1].taxId); // Ordenação crescente por taxId

                                    const rowCount = sortedDescriptions.length;

                                    return sortedDescriptions.map(([description, data], index) => (
                                        <tr key={`${group}-${description}`}>
                                            {index === 0 && (
                                                <td
                                                    rowSpan={rowCount}
                                                    style={{ textAlign: 'center', verticalAlign: 'middle' }}
                                                >
                                                    {group}
                                                </td>
                                            )}
                                            <td className="align-middle">{description}</td>
                                            {data.months.map((payment, i) => (
                                                <td
                                                    key={i}
                                                    style={{ textAlign: 'center', color: getTextColor(payment), cursor: 'pointer' }}
                                                    onClick={() => handleCellClick(payment)}
                                                >
                                                    {payment?.amount > 0
                                                        ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.amount)
                                                        : ''}
                                                    <br />
                                                    {payment?.dtExpire
                                                        ? payment.dtExpire.toLocaleDateString('pt-BR')
                                                        : ''}
                                                </td>
                                            ))}
                                        </tr>
                                    ));
                                })}
                        </tbody>
                    </Table>
                    <div style={{ marginTop: '20px' }}>
                        <p>
                            <span style={{ color: 'green' }}>●</span> Pago<br />
                            <span style={{ color: 'red' }}>●</span> Atrasado<br />
                            <span style={{ color: 'black' }}>●</span> Em aberto
                        </p>
                    </div>
                </>
            ) : (
                <p>Nenhum pagamento encontrado para os critérios selecionados.</p>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Pagamento Realizado?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="paymentDate">
                        <Form.Label>Data do Pagamento</Form.Label>
                        <DatePicker
                            selected={paymentDate}
                            onChange={(date) => setPaymentDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleConfirmPayment}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TaxPayments;