//attendanceService.js:

import ApiService from '../apiservice';

class AttendanceService extends ApiService {
    constructor() {
        super('/attendance');
    }

    new(att) {
        return this.post(`${this.apiurl}/new`, att);
    }

    getById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }

    getAttByMember(member) {
        return this.get(`${this.apiurl}/member/${member}`);
    }

    getAttAllMemberBetweenDates(dates) {
        return this.get(`${this.apiurl}/member/between-dates`, dates);
    }

    getAttByEvent(event) {
        return this.get(`${this.apiurl}/event/${event}`);
    }

    getAttMemberEvent(att) {
        return this.get(`${this.apiurl}/member/${att.member}/event/${att.event}`);
    }

    getAttendanceByMemberAndEvents(memberId, eventIds) {
        return this.post(`${this.apiurl}/member/${memberId}/events`, eventIds);
    }

    add(att) {
        return this.post(`${this.apiurl}/add`, att);
    }

    remove(id) {
        return this.delete(`${this.apiurl}/${id}`);
    }

    getAttendanceByPeriod(startDate, endDate, presenceType) {
        return this.get(`${this.apiurl}/period?startDate=${startDate}&endDate=${endDate}&presenceType=${presenceType}`);
    }

    getAttendancesByMemberAndPeriod(memberId, startDate, endDate) {
        return this.get(`${this.apiurl}/member/${memberId}/period?startDate=${startDate}&endDate=${endDate}`);
    }

    getAttendanceDetailsByEvent(eventId) {
        return this.get(`${this.apiurl}/event/${eventId}/details`);
    }

    getAttendancesByMemberAndPeriod(memberId, startDate, endDate) {
        return this.get(`${this.apiurl}/member/${memberId}/period?startDate=${startDate}&endDate=${endDate}`);
    }

    registerAttendance(props) {
        return this.post(`${this.apiurl}/register/${props.eventId}/${props.memberId}`);
    }

    registerAttendanceVisitor(props) {
        return this.post(`${this.apiurl}/register/${props.eventId}/visitor/${props.visitorId}`);
    }

    validateTimeRegister(eventId) {
        return this.post(`${this.apiurl}/validateTimeRegister/${eventId}`);
    }

    getAttVisitorEvent(att) {
        return this.get(`${this.apiurl}/visitor/${att.visitor}/event/${att.event}`);
    }

    addVisitorAttendance(att) {
        return this.post(`${this.apiurl}/addVisitorAttendance`, att);
    }

    newVisitorAttendance(att) {
        return this.post(`${this.apiurl}/newVisitorAttendance`, att);
    }
    getByVisitorId(id) {
        return this.get(`${this.apiurl}/visitor/${id}`);
    }

    sendCertificateByEmail(data) {
        return this.post('/attendance/sendCertificate', data);
    }


}

export default AttendanceService;


