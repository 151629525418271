//MemberList.js:

import React, { useState, useEffect, useMemo, useContext } from "react";
import MemberService from "../../app/service/memberService";
import StatusService from "../../app/service/statusService";
import SpouseService from "../../app/service/spouseService";
import UserService from "../../app/service/userService.js";
import { messageSuccess, messageError } from "../../components/toastr";
import Card from "../../components/card";
import FormGroup from "../../components/form-group";
import FormRow from '../../components/form-row';
import Container from "../../components/container";
import SelectMenu from "../../components/selectMenu";
import { useNavigate } from 'react-router-dom';
import MemberDT from "./memberDT";
import { Dialog } from 'primereact/dialog';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ChildrenService from "../../app/service/childrenService.js";
import LodgeRoleService from "../../app/service/lodgeRoleService.js";
import DegreeService from "../../app/service/degreeService.js";
import MedalsService from "../../app/service/medalsService.js";
import TitleService from "../../app/service/titleService.js";
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { AuthContext } from '../../security/AuthContext';
import { pdfHeader } from '../../components/pdfHeaderPdfMake.js'; // Ajuste o caminho conforme necessário
import customVfs from "../../components/customVfs"; // Ajuste o caminho conforme necessário
import PRMTSService from '../../app/service/prmtsService.js'; // Se ainda não tiver importado
import RolePermissionService from "../../app/service/rolePermissionService";
import * as Constant from '../../config/constants';

const MemberList = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const memberService = useMemo(() => new MemberService(), []);
    const statusService = useMemo(() => new StatusService(), []);
    const degreeService = useMemo(() => new DegreeService(), []);
    const userService = useMemo(() => new UserService(), []);
    const spouseService = useMemo(() => new SpouseService(), []);
    const childrenService = useMemo(() => new ChildrenService(), []);
    const medalsService = useMemo(() => new MedalsService(), []);
    const titleService = useMemo(() => new TitleService(), []);
    const lodgeRole = useMemo(() => new LodgeRoleService(), []);
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);

    const [searchResults, setSearchResults] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [cim, setCim] = useState('');
    const [degree, setDegree] = useState('');
    const [status, setStatus] = useState(0); // Set default value to 0
    const [listAllStatus, setListAllStatus] = useState([]);
    const [listChildren, setListChildren] = useState([]);
    const [canEdit, setCanEdit] = useState(false);
    const [canViewAll, setCanViewAll] = useState(false);
    const [canViewOwn, setCanViewOwn] = useState(false);

    const getList = async () => {
        try {
            const response = await statusService.getAll();
            const data = response && response.data;
            setListAllStatus(mountList(data));
        } catch (error) {
            messageError(`Erro ao obter a lista de status. <br /> ${error.response ? error.response.data : 'Unknown error'}`);
        }
    };


    const mountList = (data) => {
        // Add the default option "SELECIONE" with value 0
        const defaultOption = { label: 'Selecione', value: 0 };
        return [defaultOption, ...data.map(item => ({ label: item.description, value: item.id }))];
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleRegisterNewMember = () => {
        navigate('/member-register');
        return;
    }

    const handleCIMChange = (event) => {
        setCim(event.target.value);
    };

    const handleDegreeChange = (event) => {
        setDegree(event.target.value);
    };

    const handleStatusChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setStatus(selectedValue);
    };

    const handleSearch = async () => {
        const memberFilter = {
            name: name,
            cim: cim,
            degree: degree,
            status: status
        };
        try {
            const response = await memberService.findMembers(memberFilter);
            if (Array.isArray(response.data) && response.data.length > 0) {

                // Ordenar os usuários de forma ascendente pelo nome
                const sortedMembers = response.data.sort((a, b) => a.name.localeCompare(b.name));
                setSearchResults(sortedMembers);
            } else {
                setSearchResults([]);
                messageError("Nenhum resultado encontrado.");
            }

        } catch (error) {
            messageError(`Erro ao realizar a busca. <br /> ${error.response.data}`);
        }

    };

    const handleClear = () => {
        setName('');
        setCim('');
        setDegree('');
        setStatus(0);
    }

    const formatAddress = (addr, number, compl, neighborhood, city, state, country) => {
        const parts = [addr, number, compl, neighborhood, city, state, country];
        const filteredParts = parts.filter(part => part && part.trim() !== ""); // Remove valores nulos, indefinidos e strings vazias
        return filteredParts.length > 0 ? filteredParts.join(', ') : "";
    };

    const formatBirthplace = (city, state, country) => {
        const parts = [city, state, country];
        const filteredParts = parts.filter(part => part && part.trim() !== ""); // Remove valores nulos, indefinidos e strings vazias
        return filteredParts.length > 0 ? filteredParts.join(', ') : "";
    };

    const infoMember = async (id) => {
        pdfMake.vfs = {
            ...pdfFonts.pdfMake.vfs,
            ...customVfs,
        };

        pdfMake.fonts = {
            Roboto: {
                normal: "Roboto-Regular.ttf",
                bold: "Roboto-Medium.ttf",
                italics: "Roboto-Italic.ttf",
                bolditalics: "Roboto-MediumItalic.ttf",
            },
            GreatVibes: {
                normal: "GreatVibes-Regular.ttf",
                bold: "GreatVibes-Regular.ttf",
                italics: "GreatVibes-Regular.ttf",
                bolditalics: "GreatVibes-Regular.ttf",
            },
        };

        try {
            const responseDayTime = await prmtsService.getByType('DAYTIME-SESSION');
            const dayTimeSessionDescription = responseDayTime.data ? responseDayTime.data.description : '';
            const { headerContent, headerStyles } = pdfHeader(dayTimeSessionDescription);

            const response = await memberService.getById(id);
            if (response && response.data) {
                const member = response.data;
                const status = await statusService.getById(member.status.id);
                const role = await lodgeRole.getById(member.lodgeRole);
                const degree = await degreeService.getDegreeById(member.lodgeDegree);
                let degreeF;

                if (member.lodgeDegreeF)
                    degreeF = await degreeService.getDegreeById(member.lodgeDegreeF);

                let spouseName = 'Não informado';
                let childrenNames = ['Não informado'];
                let famm = 'Não informado';
                if (member.famm) {
                    famm = member.famm;
                }
                try {
                    const responseSpouse = await spouseService.getSpouseByMember(member.id);
                    spouseName = responseSpouse.data ? responseSpouse.data.name : 'Não informado';
                } catch (error) {
                    messageError("Error (spouseService.getSpouseByMember)");
                }

                try {
                    const responseChildren = await childrenService.getChildrenByMember(member.id);
                    if (responseChildren.data && responseChildren.data.length > 0) {
                        childrenNames = responseChildren ? responseChildren.data.map(child => child.name) : 'Não informado';
                    }
                } catch (error) {
                    messageError("Error (childrenService.getChildrenByMember) ");
                }

                const responseMedals = await medalsService.getMedalsByMemberId(member.id);
                const medalsDetails = responseMedals.data && responseMedals.data.length > 0
                    ? await Promise.all(responseMedals.data.map(async (medal) => {
                        const title = await titleService.getById(medal.title);
                        return {
                            ...medal,
                            titleDetails: title.data,
                        };
                    }))
                    : [];

                // Ordenar as medalhas por dtReceived
                medalsDetails.sort((a, b) => new Date(a.dtReceived[0], a.dtReceived[1] - 1, a.dtReceived[2]) - new Date(b.dtReceived[0], b.dtReceived[1] - 1, b.dtReceived[2]));

                const medalsRows = medalsDetails.length > 0
                    ? medalsDetails.map((medal) => {
                        const date = new Date(medal.dtReceived[0], medal.dtReceived[1] - 1, medal.dtReceived[2]);
                        const formattedDate = format(date, 'dd/MM/yyyy', { locale: ptBR });

                        return [
                            { text: `${medal.titleDetails.agency} - ${formattedDate} - ${medal.titleDetails.description} - ${medal.titleDetails.minYears} anos`, alignment: 'left' }
                        ];
                    })
                    : [[{ text: 'Nenhum título / condecoração cadastrado.', alignment: 'left' }]];

                const formatCPF = (cpf) => cpf ? cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : "";
                const formatPhone = (phone) => phone ? phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : "";
                const formatDate = (dateArray) => {
                    if (!dateArray) return "";
                    const [year, month, day] = dateArray;
                    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
                };

                const formattedHomeAddress = formatAddress(
                    member.addrHome, member.numberHome, member.complHome,
                    member.neighborhoodHome, member.cityHome, member.stateHome, member.countryHome
                );
                const formattedWorkAddress = formatAddress(
                    member.addrWork, member.numberWork, member.complWork,
                    member.neighborhoodWork, member.cityWork, member.stateWork, member.countryWork
                );

                const formattedBirthplace = formatBirthplace(member.cityBorn, member.stateBorn, member.countryBorn);

                const safeValue = (value) => value ? value : "";

                let grauSimbólico = degree && degree.data ? `${degree.data.id} - ${degree.data.description}` : "Não informado";
                if (member.dtInstallation)
                    grauSimbólico = "3 - Mestre Instalado";

                const grauFilosófico = degreeF && degreeF.data ? `${degreeF.data.id} - ${degreeF.data.description}` : "Não informado";

                const memberInfo = [
                    {
                        table: {
                            widths: ['25%', '25%', '25%', '25%'],
                            body: [
                                [{ text: "Informações Loja", colSpan: 4, style: 'sectionHeader', alignment: 'center' }, {}, {}, {}],
                                [{ text: "CIM", bold: true }, safeValue(member.cim), { text: "Grau Simbólico", bold: true }, safeValue(grauSimbólico)],
                                [{ text: "Cargo", bold: true }, safeValue(role.data.description), { text: "Grau Filosófico", bold: true }, safeValue(grauFilosófico)],
                                [{ text: "Situação", bold: true }, safeValue(status.data.description), { text: "Comissão", bold: true }, safeValue(member.lodgeParty)]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['25%', '25%', '25%', '25%'],
                            body: [
                                [{ text: "Informações Pessoais", colSpan: 4, style: 'sectionHeader', alignment: 'center' }, {}, {}, {}],
                                [{ text: "Nome", bold: true }, safeValue(member.name), { text: "CPF", bold: true }, formatCPF(member.cpf)],
                                [{ text: "Email", bold: true }, safeValue(member.email), { text: "Doador de Órgãos", bold: true }, safeValue(member.donator)],
                                [{ text: "Pai", bold: true }, safeValue(member.fatherName), { text: "Mãe", bold: true }, safeValue(member.motherName)],
                                [{ text: "Local de Nascimento", bold: true }, formattedBirthplace, { text: "Data de Nascimento", bold: true }, formatDate(member.dtBorn)],
                                [{ text: "Estado Civil", bold: true }, safeValue(member.maritalStatus), { text: "Tipo Sanguíneo", bold: true }, safeValue(member.bloodType)],
                                [{ text: "Grau de Escolaridade", bold: true }, safeValue(member.educationDegree), {}, {}]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['25%', '25%', '25%', '25%'],
                            body: [
                                [{ text: "Contato", colSpan: 4, style: 'sectionHeader', alignment: 'center' }, {}, {}, {}],
                                [{ text: "Telefone Celular", bold: true }, safeValue(formatPhone(member.phoneCell)), {}, {}],
                                [{ text: "Contato de Emergencia 1", bold: true }, safeValue(member.nameEmergency1), { text: "Telefone", bold: true }, safeValue(formatPhone(member.phoneEmergency1))],
                                [{ text: "Contato de Emergencia 2", bold: true }, safeValue(member.nameEmergency2), { text: "Telefone", bold: true }, safeValue(formatPhone(member.phoneEmergency2))],
                                [{ text: "Contato de Emergencia 3", bold: true }, safeValue(member.nameEmergency3), { text: "Telefone", bold: true }, safeValue(formatPhone(member.phoneEmergency3))]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['25%', '25%', '25%', '25%'],
                            body: [
                                [{ text: "Histórico Maçônico", colSpan: 4, style: 'sectionHeader', alignment: 'center' }, {}, {}, {}],
                                [{ text: "Loja de Iniciação", bold: true }, safeValue(member.lodgeStart), { text: "Oriente de Iniciação", bold: true }, safeValue(member.lodgeStartState)],
                                [{ text: "Data de Iniciação", bold: true }, formatDate(member.dtLodgeStart), { text: "Data de Elevação", bold: true }, formatDate(member.dtElevation)],
                                [{ text: "Data de Exaltação", bold: true }, formatDate(member.dtExaltation), { text: "Data de Instalação", bold: true }, formatDate(member.dtInstallation)],
                                [{ text: "Data de Filiação", bold: true }, formatDate(member.dtFiliation), { text: "Data Remido", bold: true }, formatDate(member.dtRedemption)],
                                [{ text: "Anotações Diversas", bold: true }, { text: member.annotation, colSpan: 3 }, {}, {}]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['100%'],
                            body: [
                                [{ text: "Títulos e Condecorações", style: 'sectionHeader', alignment: 'center' }],
                                ...medalsRows,
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['25%', '25%', '25%', '25%'],
                            body: [
                                [{ text: "Documentos", colSpan: 4, style: 'sectionHeader', alignment: 'center' }, {}, {}, {}],
                                [{ text: "Identificação Civil/Profissional", bold: true }, safeValue(member.idCard), { text: "Órgão Emissor", bold: true }, safeValue(member.idAgency)],
                                [{ text: "UF", bold: true }, safeValue(member.idUF), { text: "Situação Profissional", bold: true }, safeValue(member.professionStatus)],
                                [{ text: "Profissão", bold: true }, safeValue(member.profession), { text: "Especialização", bold: true }, safeValue(member.professionSpec)],
                                [{ text: "Título de Eleitor", bold: true }, safeValue(member.idVote), { text: "Zona Eleitoral", bold: true }, safeValue(member.voteZone)],
                                [{ text: "Seção Eleitoral", bold: true }, { text: safeValue(member.voteSection), colSpan: 3 }, {}, {}]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['25%', '25%', '25%', '25%'],
                            body: [
                                [{ text: "Endereço Residencial", colSpan: 4, style: 'sectionHeader', alignment: 'center' }, {}, {}, {}],
                                formattedHomeAddress ? [{ text: formattedHomeAddress, colSpan: 4 }] : [{ text: " ", colSpan: 4 }]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['25%', '25%', '25%', '25%'],
                            body: [
                                [{ text: "Informação Profissional", colSpan: 4, style: 'sectionHeader', alignment: 'center' }, {}, {}, {}],
                                [{ text: "Local de trabalho", bold: true }, { text: `${safeValue(member.localWork)}` }, { text: "Cargo / Função", bold: true }, safeValue(member.workRole)],
                                formattedWorkAddress ? [{ text: "Endereço", bold: true }, { text: formattedWorkAddress, colSpan: 3 }] : [{ text: "Endereço", bold: true }, { text: " ", colSpan: 3 }]

                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['100%'],
                            body: [
                                [{ text: "Cunhada", style: 'sectionHeader', alignment: 'center' }],
                                [{ text: spouseName, colSpan: 1, bold: true }]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['100%'],
                            body: [
                                [{ text: "Sobrinhos", style: 'sectionHeader', alignment: 'center' }],
                                ...childrenNames.map(childName => [{ text: childName, colSpan: 1, bold: true }])
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    },
                    {
                        table: {
                            widths: ['100%'],
                            body: [
                                [{ text: "Beneficiário(a) FAMM", style: 'sectionHeader', alignment: 'center' }],
                                [{ text: famm, colSpan: 1, bold: true }]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        },
                        margin: [0, 10, 0, 10]
                    }
                ];

                const documentDefinition = {
                    content: [
                        ...headerContent,
                        { text: 'Informações do Irmão', style: 'header1' },
                        { text: member.name, style: 'header2' },
                        ...memberInfo
                    ],
                    styles: {
                        ...headerStyles, // Inclui os estilos do cabeçalho
                        header1: {
                            fontSize: 14,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 20, 0, 20],
                            font: 'Roboto',
                        },
                        header2: {
                            fontSize: 24,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 10, 0, 10],
                            font: 'Roboto',
                        },
                        sectionHeader: {
                            bold: true,
                            fontSize: 12,
                            margin: [0, 5, 0, 5],
                            fillColor: '#eeeeee',
                            font: 'Roboto',
                        },
                    },
                    defaultStyle: {
                        font: 'Roboto',
                    },
                };


                pdfMake.createPdf(documentDefinition).open();
            } else {
                messageError("Membro não encontrado.");
            }
        } catch (error) {
            messageError("Erro ao buscar dados do membro.");
        }
    };




    const editMember = async (id) => {
        try {
            const response = await memberService.getById(id);
            if (response && response.data.id) {
                const member = response.data;
                localStorage.setItem('member-edit', JSON.stringify(member.id));
                navigate('/member-register');
                return;
            } else {

                messageError("Membro não encontrado.");
            }
        } catch (error) {
            messageError("Erro ao buscar dados no banco.");
        }
    }

    const deleteMember = async (id) => {
        try {
            const response = await memberService.getById(id);
            if (response && response.data.id) {
                try {
                    // Tentativa de deletar o membro usando o ID obtido
                    await memberService.deleteMember(response.data.id);
                    messageSuccess("Membro deletado com sucesso!"); // Informar sucesso
                } catch (deleteError) {
                    if (deleteError.response && deleteError.response.status === 400) {
                        // Trata erros específicos de BadRequest
                        messageError(`Erro ao deletar o membro: ${deleteError.response.data}`);
                    } else {
                        // Trata outros erros de rede ou do servidor
                        messageError("Erro ao deletar o membro. Por favor, tente novamente mais tarde.");
                    }
                }
            } else {
                messageError("Membro não encontrado.");
            }
        } catch (error) {
            messageError("Erro ao buscar dados do membro.");
        }
    }


    const confirmDelete = (id) => {
        setSelectedUserId(id);
        setVisibleModal(true);
    };

    const handleConfirmation = (confirmed) => {
        setVisibleModal(false);

        if (confirmed) {
            deleteMember(selectedUserId);
        } else {
            setSelectedUserId(null);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                localStorage.removeItem('member-edit');
                if (isAuthenticated) {
                    if (user) {
                        if (user.role !== 1) {
                            const responseMember = await memberService.getById(user.member);

                            const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_ALL_MEMBER_INFO);
                            setCanEdit(responseCanEdit.data);

                            if (!responseCanEdit.data) {
                                const responseCanViewAll = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_ALL_MEMBER_INFO);
                                setCanViewAll(responseCanViewAll.data);

                                if (!responseCanViewAll.data) {
                                    const responseCanViewOwn = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_OWN_MEMBER_INFO);
                                    setCanViewOwn(responseCanViewOwn.data);
                                } else {
                                    setCanViewOwn(true);
                                }

                            } else {
                                setCanViewAll(true);
                                setCanViewOwn(true);
                            }


                        } else {
                            setCanEdit(true);
                            setCanViewAll(true);
                            setCanViewOwn(true);
                        }
                    }
                } else {
                    messageError("Acesso negado. Procure um administrador.")
                    navigate('/login');
                    return;
                }

                await getList();
                handleSearch();

            } catch (error) {
                if (error.response && error.response.status === 400) {
                    messageError('Requisição inválida. Por favor, tente novamente.');
                } else {
                    messageError('Erro ao buscar informações.');
                }
            }
        };

        fetchData();
    }, []); // Run once when the component mounts


    return (
        <Container>
            <Card title="Consultar Irmãos">
                <div className="row">
                    <div className="col-lg-3">
                        <FormGroup label="Nome: " htmlFor="inputName">
                            <input
                                className="form-control"
                                type="text"
                                id="exampleInputName1"
                                name="name"
                                value={name}
                                placeholder="Digite o Nome"
                                onChange={handleNameChange}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-lg-3">
                        <FormGroup label="CIM: " htmlFor="inputCim">
                            <input
                                className="form-control"
                                type="text"
                                id="exampleInputCim1"
                                name="cim"
                                value={cim}
                                placeholder="Digite o Cim"
                                onChange={handleCIMChange}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-lg-3">
                        <FormGroup label="Grau: " htmlFor="inputDegree">
                            <input
                                className="form-control"
                                type="text"
                                id="exampleInputName1"
                                name="degree"
                                value={degree}
                                placeholder="Digite o Grau"
                                onChange={handleDegreeChange}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-lg-3">
                        <FormGroup label="Situação: " htmlFor="selectStatus">
                            <SelectMenu
                                className="form-control"
                                list={listAllStatus}
                                value={status}
                                onChange={handleStatusChange}
                            />
                        </FormGroup>
                    </div>
                </div>

                <br />
                <button className="btn btn-success" onClick={handleSearch}>Buscar</button>
                {' '}
                <button className="btn btn-danger" onClick={handleClear}>Limpar</button>
                {' '}
                {visible && (
                    <>
                        <button className="btn btn-info" onClick={handleRegisterNewMember}>Cadastrar novo irmão</button>
                    </>
                )}
                <div className="row">
                    <div className="col-lg-12">
                        <MemberDT
                            members={searchResults}
                            deleteMember={confirmDelete}
                            editMember={editMember}
                            infoMember={infoMember}
                            canEdit={canEdit}
                            canViewAll={canViewAll}
                            canViewOwn={canViewOwn}
                        />
                    </div>
                </div>
                <Dialog
                    header="Confirmação"
                    visible={visibleModal}
                    style={{ width: '25vw' }}
                    onHide={() => handleConfirmation(false)}
                    modal={true}
                >
                    <p className="m-0">Deseja excluir o usuário?</p>
                    <br />
                    <div className="p-d-flex p-jc-end">
                        <button
                            className="btn btn-danger p-button-text"
                            onClick={() => handleConfirmation(true)}
                        >
                            Deletar
                        </button>
                        {' '}
                        <button
                            className="btn btn-secondary p-button-text"
                            onClick={() => handleConfirmation(false)}
                        >
                            Cancelar
                        </button>
                    </div>
                </Dialog>

            </Card>
        </Container>
    );
};

export default MemberList;
