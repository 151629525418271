import React, { useMemo } from "react";
import { Button } from 'primereact/button';

const UserDT = (props) => {
    const roleMap = useMemo(() => {
        const map = {};
        props.listRole.forEach(role => {
            map[role.value] = role.label;
        });
        return map;
    }, [props.listRole]);

    const statusMap = useMemo(() => {
        const map = {};
        props.listStatus.forEach(status => {
            map[status.value] = status.label;
        });
        return map;
    }, [props.listStatus]);

    const memberMap = useMemo(() => {
        const map = {};
        props.memberList.forEach(member => {
            map[member.value] = member.label;
        });
        return map;
    }, [props.memberList]);

    const rows = props.users.map(user => (
        <tr key={user.id}>
            <td>{user.email}</td>
            <td>{memberMap[user.member]}</td>
            <td>{roleMap[user.role]}</td>
            <td>{statusMap[user.status]}</td>
            <td>
                <Button
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    severity="success"
                    aria-label="Edit"
                    className="small-button btn btn-primary"
                    onClick={() => props.editUser(user.id)}
                />
                {' '}
                <Button
                    icon="pi pi-times"
                    rounded
                    outlined
                    severity="danger"
                    aria-label="Delete"
                    className="small-button btn btn-danger"
                    onClick={() => props.deleteUser(user.id)}
                />
            </td>
        </tr>
    ));

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Irmão</th>
                    <th scope="col">Cargo</th>
                    <th scope="col">Situação</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};

export default UserDT;
