import toastr from "toastr"

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": " toast-bottom-right",
    "preventDuplicates": true,
    "showDuration": "500",
    "hideDuration": "1000",
    "timeOut": "2000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

export function showMessage(title, msg, type) {
    toastr[type](msg, title)
}

export function messageSuccess(msg) {
    showMessage("Sucesso", msg, "success")
}

export function messageError(msg) {
    showMessage("Erro", msg, "error")
}

export function messageInfo(msg) {
    showMessage("Erro", msg, "info")
}

export function messageWarning(msg) {
    showMessage("Erro", msg, "warning")
}


