import React from "react";

const CustomSelect = (props, key) => {
    const options = props.list.map((option, key) => (
        <option key={key} value={option.value}>
            {option.label}
        </option>
    ));

    return (
        <select {...props}>
            {options}
        </select>
    );
};

export default CustomSelect;
