//UserService.js
import ApiService from '../apiservice';
import api from '../api';

class UserService extends ApiService {
    constructor() {
        super('/user');
    }

    login(credentials) {
        return api.post(`${this.apiurl}/auth`, credentials);
    }

    validate(token) {
        return api.get(`${this.apiurl}/token/${token}`);
    }

    hasAccess(id) {
        return api.get(`${this.apiurl}/access/${id}`);
    }

    deleteUser(id) {
        return api.delete(`${this.apiurl}/${id}`);
    }

    getById(id) {
        return api.get(`${this.apiurl}/${id}`);
    }

    getByMemberId(id) {
        return api.get(`${this.apiurl}/member/${id}`);
    }

    getByToken(token) {
        return api.get(`${this.apiurl}/get/token/${token}`);
    }

    new(user) {
        return api.post(`${this.apiurl}/new`, user);
    }

    new(user) {
        return api.post(`${this.apiurl}/new`, user);
    }

    update(user) {
        return api.put(`${this.apiurl}/${user.id}`, user);
    }

    getAll() {
        return api.get(`${this.apiurl}/all`);
    }

    findReport(UserFilter) {
        if (UserFilter != null) {
            let params = `?`;
            let count = 0

            if (UserFilter.email) {
                params += `email=${UserFilter.email}`;
                count++;
            }

            if (UserFilter.role) {
                if (count > 0)
                    params += `&`
                params += `role=${UserFilter.role}`
                count++;
            }

            if (UserFilter.status) {
                if (count > 0)
                    params += `&`
                params += `status=${UserFilter.status}`
            }

            if (UserFilter.member) {
                if (count > 0)
                    params += `&`
                params += `member=${UserFilter.member}`
            }
            return api.get(`${this.apiurl}${params}`)
        }
        return api.get(`${this.apiurl}/all`);
    }

    getRoleList() {
        return [
            { label: "SELECIONE", value: "" },
            { label: "IRMÃO", value: 0 },
            { label: "ADMINISTRADOR", value: 1 },
        ];
    }

    getStatusList() {
        return [
            { label: "SELECIONE", value: "" },
            { label: "ATIVO", value: "ATIVO" },
            { label: "INATIVO", value: "INATIVO" },
            { label: "SUSPENSO", value: "SUSPENSO" },
        ];
    }
}

export default UserService;
