import React, { useState, useEffect, useMemo } from "react";
import { Button } from 'primereact/button';
import UserService from "../../app/service/userService.js";
import { useNavigate } from 'react-router-dom';
import { messageSuccess, messageError } from "../../components/toastr";

const EventTypeDT = (props) => {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const userService = useMemo(() => new UserService(), []);
    const [rows, setRows] = useState([]);  // State para armazenar os rows
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (props.types.length === 0) return;

        const fetchData = async () => {
            processTypes(props.types);
        };

        fetchData();
    }, [props.types]);

    async function processTypes(types) {
        const visible = props.types.length > 0;

        const typesDetails = await Promise.all(types.map(async (type) => {

            return {
                ...type,
            };
        }));

        const newRows = typesDetails.map((type, index) => {
            return (
                <tr key={type.id}>
                    <td style={{ width: '1%', textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                    <td style={{ width: '1%', textAlign: 'left', verticalAlign: 'middle' }}>{type.type} {type.description}</td>
                    <td style={{ width: '5%', textAlign: 'center', verticalAlign: 'middle' }}>{type.presence}</td>
                    <td style={{ width: '5%', textAlign: 'center', verticalAlign: 'middle' }}>
                        <Button
                            icon="pi pi-pencil"
                            rounded
                            outlined
                            severity="danger"
                            className="small-button btn btn-info"
                            style={{ fontSize: '12px' }}
                            onClick={e => props.editEventType(type.id)}
                        />
                        {' '}
                        <Button
                            icon="pi pi-times"
                            rounded
                            outlined
                            severity="danger"
                            className="small-button btn btn-danger"
                            style={{ fontSize: '12px' }}
                            onClick={e => props.deleteEventType(type.id)}
                        />
                    </td>
                </tr>
            );
        });

        setRows(newRows);
    }

    return (
        <div style={{ border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '50%' }}>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th style={{ width: '1%', textAlign: 'center', verticalAlign: 'middle' }}>#</th>
                        <th style={{ width: '5%', textAlign: 'center', verticalAlign: 'middle' }}>Tipo de Sessão</th>
                        <th style={{ width: '5%', textAlign: 'center', verticalAlign: 'middle' }}>Presença<br />Obrigatória</th>
                        <th style={{ width: '5%', textAlign: 'center', verticalAlign: 'middle' }}>Ações</th>
                    </tr>

                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    );
};

export default EventTypeDT;
