import ApiService from '../apiservice';

class MedalsService extends ApiService {
    constructor() {
        super('/medals');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getMedalsByMemberId(id) {
        return this.get(`${this.apiurl}/member/${id}`);
    }

    new(medals) {
        return this.post(`${this.apiurl}/new`, medals);
    }

    deleteMedal(id) {
        return this.delete(`${this.apiurl}/${id}`);
    }
}

export default MedalsService;
