import React, { useState, useEffect, useMemo, useContext } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import SelectMenu from "../../components/selectMenu";
import { messageSuccess, messageError } from '../../components/toastr';
import Event_TypeService from '../../app/service/event_TypeService';
import MemberService from '../../app/service/memberService';
import UserService from '../../app/service/userService';
import { Button } from 'react-bootstrap';
import { AuthContext } from '../../security/AuthContext';
import RolePermissionService from "../../app/service/rolePermissionService";
import * as Constant from '../../config/constants';

const EventTypeRegister = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const eventTypeService = useMemo(() => new Event_TypeService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const userService = useMemo(() => new UserService(), []);
    const eventTypeEdit = localStorage.getItem('eventType-edit');

    const selectOption = { label: 'Selecione', value: 0 };
    const [visible, setVisible] = useState(false);

    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [presence, setPresence] = useState('S');

    const [typeError, setTypeError] = useState('');

    const Confirm = async () => {
        if (type == '') {
            setTypeError('Campo obrigatório')
            return;
        } else {
            setTypeError('')
        }

        const eventTypeData = {
            id: eventTypeEdit ? eventTypeEdit : null,
            type: type,
            description: description,
            presence: presence
        }

        if (eventTypeEdit) {
            await eventTypeService.update(eventTypeData).then((response) => {
                messageSuccess('Tipo de Sessão alterado com sucesso');
            }).catch((error) => {
                messageError(error.response.data);
                return;
            });
        } else {
            await eventTypeService.new(eventTypeData).then((response) => {
                messageSuccess('Tipo de Sessão registrado com sucesso');
            }).catch((error) => {
                messageError(error.response.data);
                return;
            });

        }
        localStorage.removeItem('eventType-edit');
        navigate("/config");

    }

    useEffect(() => {
        const fetchData = async () => {
            //Verificar se o usuário está logado
            if (isAuthenticated) {
                if (user) {
                    if (user.role !== 1) {
                        const responseMember = await memberService.getById(user.member);
                        const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_EVENT);
                        if (!responseCanEdit.data) {
                            messageError("Acesso negado. Procure um administrador.")
                            navigate('/login');
                        }
                    }
                }
            } else {
                messageError("Acesso negado. Procure um administrador.")
                navigate('/login');
            }

            if (eventTypeEdit) {
                const response = await eventTypeService.getById(eventTypeEdit);
                const eventTypeData = response.data;

                if (eventTypeData.type)
                    setType(eventTypeData.type)
                if (eventTypeData.description)
                    setDescription(eventTypeData.description)
                if (eventTypeData.presence)
                    setPresence(eventTypeData.presence)
            }
        }
        fetchData();
    }, []);
    return (
        <div>
            <Container>
                <div style={{}}>
                    <Card title="Tipos de Sessões">

                        <FormGroup
                            label="Tipo: "
                            htmlFor="inputType"
                            error={typeError}
                        >
                            <input
                                className={`form-control ${typeError ? 'is-invalid' : ''}`}
                                type="text"
                                id="inputType"
                                name="type"
                                value={type}
                                placeholder="Ex.: Sessão"
                                onChange={(e) => setType(e.target.value)}
                                style={{ width: '300px' }}
                            />
                            {typeError && (
                                <div className="invalid-feedback">
                                    {typeError}
                                </div>
                            )}
                        </FormGroup>
                        <br />
                        <FormGroup
                            label="Descrição: "
                            htmlFor="inputDescription"
                        >
                            <input
                                className="form-control"
                                type="text"
                                id="inputDescription"
                                name="description"
                                value={description}
                                placeholder="Ex.: Magna Exaltação"
                                onChange={(e) => setDescription(e.target.value)}
                                style={{ width: '300px' }}
                            />
                        </FormGroup>
                        <br />
                        <FormGroup label="Presença Obrigatória: " htmlFor="selectStatus">
                            <SelectMenu
                                className="form-control"
                                list={[
                                    { label: 'Sim', value: 'S' },
                                    { label: 'Não', value: 'N' }
                                ]}
                                value={presence}
                                onChange={(e) => setPresence(e.target.value)}
                                style={{ width: '300px' }}
                            />
                        </FormGroup>
                        <Button className="btn-sm btn btn-success" onClick={Confirm} style={{ fontSize: "14px", height: '35px', marginTop: '2vh', marginRight: '0.5vw' }}>
                            Confirmar
                        </Button>
                        <Button
                            className="btn-sm btn btn-danger"
                            onClick={() => navigate('/config')}
                            style={{ fontSize: "14px", height: '35px', marginTop: '2vh', marginRight: '0.5vw' }}
                        >
                            Cancelar
                        </Button>
                    </Card>
                </div>
            </Container >
        </div>

    );
}

export default EventTypeRegister;
