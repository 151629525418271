import ApiService from '../apiservice';

class Event_TypeService extends ApiService {
    constructor() {
        super('/event_type');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }

    new(type) {
        return this.post(`${this.apiurl}/new`, type);
    }

    update(type) {
        return this.put(`${this.apiurl}/${type.id}`, type);
    }

    deleteType(id) {
        return this.delete(`${this.apiurl}/${id}`);
    }
}

export default Event_TypeService;