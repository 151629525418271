//treausy.js

import React, { useState, useEffect, useMemo, useContext } from "react";
import { messageError, messageSuccess } from "../../components/toastr";
import Card from "../../components/card";
import Container from "../../components/container";
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { AuthContext } from '../../security/AuthContext';
import TaxesService from "../../app/service/taxesService.js";
import Taxes from "./taxes";
import TaxPayments from "./tax_payments.js";
import MemberService from "../../app/service/memberService.js";
import PaymentRegister from "./payment_register.js";

const Treasury = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const taxesService = useMemo(() => new TaxesService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const [activeTab, setActiveTab] = useState("taxes");
    const [taxes, setTaxes] = useState([]);
    const [members, setMembers] = useState([]);
    const [editedTaxes, setEditedTaxes] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("::0::");

                if (!isAuthenticated) {
                    messageError("Acesso negado. Procure um administrador.");
                    navigate('/login');
                    return;
                }
                console.log("::1::");

                const responseTaxes = await taxesService.getAll();
                let data = responseTaxes.data || [];
                data.sort((a, b) => a.id - b.id);
                setTaxes(data);
                console.log("::2:: Taxes carregados:", data);

                const responseMembers = await memberService.getAll();
                console.log("::3:: Resposta de membros:", responseMembers.data);

                const sortedMembers = (responseMembers.data || []).sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                console.log("::4:: sortedMembers:: ", sortedMembers);

                setMembers(sortedMembers);
                console.log("::5:: Membros definidos:", sortedMembers);
            } catch (error) {
                console.error('Erro ao carregar dados: ', error);
                messageError('Erro ao carregar dados.');
            }
        };

        fetchData();
    }, [isAuthenticated, navigate, taxesService]);

    const handleChange = (id, field, value) => {
        setEditedTaxes(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [field]: value
            }
        }));
    };

    const handleSaveChanges = async () => {
        try {
            const updates = Object.keys(editedTaxes).map(async (idStr) => {
                const id = parseInt(idStr, 10);
                const originalTax = taxes.find(t => t.id === id);
                const edited = editedTaxes[id];

                // Mescla o original com as alterações
                const updatedTax = { ...originalTax, ...edited };

                // Converter o campo "value" formatado para número
                // Caso venha como string, remover 'R$', '.' e trocar ',' por '.' para converter em número.
                if (typeof updatedTax.value === 'string') {
                    const numericString = updatedTax.value
                        .replace(/[R$\s.]/g, '')   // remove R$, espaços e pontos
                        .replace(',', '.');       // troca vírgula por ponto
                    updatedTax.value = parseFloat(numericString) || 0;
                }

                // Converte campo active para 'S' ou 'N'
                if (typeof updatedTax.active === 'boolean') {
                    updatedTax.active = updatedTax.active ? 'S' : 'N';
                }

                await taxesService.update(updatedTax);
            });

            await Promise.all(updates);
            messageSuccess("Alterações salvas com sucesso!");

            // Recarrega a lista do servidor
            const responseTaxes = await taxesService.getAll();
            let data = responseTaxes.data || [];
            data.sort((a, b) => a.id - b.id);
            setTaxes(data);
            setEditedTaxes({});

        } catch (error) {
            console.error("Erro ao salvar alterações: ", error);
            messageError("Erro ao salvar alterações.");
        }
    };

    const isSaveButtonDisabled = Object.keys(editedTaxes).length === 0;

    return (
        <Container>
            <Card title="Tesouraria" style={{ width: '100%' }}>
                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    id="treasury-tabs"
                    className="mb-3"
                >
                    <Tab eventKey="taxes" title={<span style={{ color: 'blue' }}>Taxas</span>}>
                        <div style={{ paddingTop: '2vh' }}>
                            {taxes.length === 0 && <p>Nenhuma taxa encontrada.</p>}
                            <div style={{ width: '100%', overflowX: 'auto' }}>
                                <Taxes
                                    taxes={taxes}
                                    editedTaxes={editedTaxes}
                                    onHandleChange={handleChange}
                                />
                            </div>
                            <Button
                                variant="primary"
                                onClick={handleSaveChanges}
                                disabled={isSaveButtonDisabled}
                            >
                                Salvar Alterações
                            </Button>
                        </div>
                    </Tab>
                    <Tab eventKey="payments" title={<span style={{ color: 'blue' }}>Pagamentos</span>}>
                        <div style={{ paddingTop: '2vh', width: '100%', overflowX: 'auto' }}>
                            <TaxPayments taxes={taxes} members={members} />
                        </div>
                    </Tab>
                    <Tab eventKey="includingTaxes" title={<span style={{ color: 'blue' }}>Inclusão de Despesa</span>}>
                        <div style={{ paddingTop: '2vh' }}>
                            <PaymentRegister taxes={taxes} members={members} />
                        </div>
                    </Tab>
                </Tabs>
            </Card>
        </Container>
    );
};

export default Treasury;
