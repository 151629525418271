// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import UserService from '../app/service/userService';
import MemberService from '../app/service/memberService';
import RolePermissionService from '../app/service/rolePermissionService';
import * as Constant from '../../src/config/constants';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const userService = new UserService();
    const memberService = new MemberService();
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);

    const location = useLocation(); // Get the current location
    const [authState, setAuthState] = useState({
        isAuthenticated: false,
        user: null,
        loading: true,
        currentRoute: location.pathname, // Initialize currentRoute
    });

    useEffect(() => {
        const initializeAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const isValid = await userService.validate(token);
                    if (isValid.data) {
                        const userResponse = await userService.getByToken(token);
                        if (userResponse.data.role === 1) {
                            setAuthState({
                                isAuthenticated: true,
                                user: userResponse.data,
                                loading: false,
                                currentRoute: location.pathname,
                            });
                        } else {
                            const responseMember = await memberService.getById(userResponse.data.member);
                            const hasPermission = await rolePermissionService.hasAccess(responseMember.data.lodgeRole, location.pathname);



                            setAuthState({
                                isAuthenticated: hasPermission.data,
                                user: userResponse.data,
                                loading: false,
                                currentRoute: location.pathname,
                            });
                        }

                    } else {
                        localStorage.removeItem('token');
                        setAuthState({ isAuthenticated: false, user: null, loading: false, currentRoute: location.pathname });
                    }
                } catch (error) {
                    localStorage.removeItem('token');
                    setAuthState({ isAuthenticated: false, user: null, loading: false, currentRoute: location.pathname });
                }
            } else {
                const hasPermission = await rolePermissionService.hasAccess(Constant.PUBLIC_ACCESS, location.pathname);
                if (hasPermission.data) {
                    setAuthState({
                        isAuthenticated: true,
                        user: null,
                        loading: false,
                        currentRoute: location.pathname, // Set currentRoute
                    });
                } else {
                    setAuthState({ isAuthenticated: false, user: null, loading: false, currentRoute: location.pathname });
                }
            }
        };

        initializeAuth();
    }, []);

    useEffect(() => {
        // Update the currentRoute whenever the location changes
        setAuthState((prevState) => ({ ...prevState, currentRoute: location.pathname }));
    }, [location]);

    const login = async (email, password) => {
        const response = await userService.login({ email, password });
        localStorage.setItem('token', response.data.token);
        const userResponse = await userService.getByToken(response.data.token);
        setAuthState({
            isAuthenticated: true,
            user: userResponse.data,
            loading: false,
            currentRoute: location.pathname, // Set currentRoute
        });
    };

    const logout = () => {
        localStorage.removeItem('token');
        setAuthState({ isAuthenticated: false, user: null, loading: false, currentRoute: location.pathname });
    };

    return (
        <AuthContext.Provider value={{ ...authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
