//TaxesService.js

import ApiService from '../apiservice';

class TaxesService extends ApiService {

    constructor() {
        super('/taxes');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }
    new(tax) {
        return this.post(`${this.apiurl}/new`, tax);
    }
    update(tax) {
        return this.put(`${this.apiurl}/${tax.id}`, tax);
    }
}

export default TaxesService;