import ApiService from '../apiservice';

class CityService extends ApiService {
    constructor() {
        super('/city');
    }

    getCity(filter) {

        if (filter != null) {
            let params = `?`;
            let count = 0

            if (filter.id) {
                if (count > 0)
                    params += `&`
                params += `id=${filter.id}`
                count++;
            }

            if (filter.name) {
                params += `name=${filter.name}`;
                count++;
            }

            if (filter.stateId) {
                params += `stateId=${filter.stateId}`;
                count++;
            }

            return this.get(`${this.apiurl}${params}`)
        }
        return this.get(`${this.apiurl}/all`);

    }

}
export default CityService;