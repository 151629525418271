import React, { useState, useEffect, useMemo, useContext } from "react";
import Container from '../../components/container';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import FormField from '../../components/form-field';
import FormRow from '../../components/form-row';
import SelectMenu from "../../components/selectMenu";
import MemberService from '../../app/service/memberService';
import CountryService from '../../app/service/countryService';
import StateService from '../../app/service/stateService';
import CityService from '../../app/service/cityService';
import StatusService from '../../app/service/statusService';
import LodgeRoleService from "../../app/service/lodgeRoleService.js";
import LodgePartyService from "../../app/service/lodgePartyService.js";
import UserService from "../../app/service/userService.js";
import { messageError, messageSuccess } from "../../components/toastr";
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Nav, NavItem, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DegreeService from "../../app/service/degreeService.js";
import TitleService from "../../app/service/titleService.js";
import MedalsService from "../../app/service/medalsService.js";
import MedalsDT from "../medals/medalsDT.js";
import { alignPropType } from "react-bootstrap/esm/types.js";
import { formatCPF, validateCPF } from '../../utils/cpfUtils.js';
import { AuthContext } from '../../security/AuthContext';
import RolePermissionService from "../../app/service/rolePermissionService";
import * as Constant from '../../config/constants';

const MemberRegister = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [visibleModal, setVisibleModal] = useState(false);
    const [visible, setVisible] = useState(false);
    const [bEdit, setBEdit] = useState(false);
    const memberService = useMemo(() => new MemberService(), []);
    const countryService = useMemo(() => new CountryService(), []);
    const stateService = useMemo(() => new StateService(), []);
    const cityService = useMemo(() => new CityService(), []);
    const statusService = useMemo(() => new StatusService(), []);
    const degreeService = useMemo(() => new DegreeService(), []);
    const userService = useMemo(() => new UserService(), []);
    const lodgeRoleService = useMemo(() => new LodgeRoleService(), []);
    const lodgePartyService = useMemo(() => new LodgePartyService(), []);
    const titleService = useMemo(() => new TitleService(), []);
    const medalsService = useMemo(() => new MedalsService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const [memberEdit, setMemberEdit] = useState('');
    const [memberEditId, setMemberEditId] = useState('');
    registerLocale('pt-BR', ptBR);
    setDefaultLocale('pt-BR');
    const [activeTab, setActiveTab] = useState('personalInfo');

    const storedMemberEdit = localStorage.getItem('member-edit');

    const [memberStatus, setMemberStatus] = useState(1);
    const [listStatus, setListStatus] = useState([]);
    const [listAllLodgeRoles, setListAllLodgeRoles] = useState([]);
    const [listAllTitles, setListAllTitles] = useState([]);
    const [listAllLodgeParties, setListAllLodgeParties] = useState([]);
    const [name, setName] = useState('');
    const [cpf, setCPF] = useState('');
    const [cim, setCIM] = useState('');
    const [email, setEmail] = useState('');
    const [lodgeRole, setLodgeRole] = useState(0);
    const [father, setFather] = useState('');
    const [mother, setMother] = useState('');
    const [degree, setDegree] = useState(0);
    const [degreeF, setDegreeF] = useState(0);
    const [statusReason, setStatusReason] = useState('');
    const [famm, setFamm] = useState('');

    const [lodgeParty, setLodgeParty] = useState(0);
    const [lodgeStart, setLodgeStart] = useState('');
    const [annotation, setAnnotation] = useState('');
    const [medals, setMedals] = useState('');
    const [title, setTitle] = useState('');
    const [lodgeStartState, setLodgeStartState] = useState('');
    const [lodgeStartDate, setLodgeStartDate] = useState(null);
    const [formattedLodgeStartDate, setFormattedLodgeStartDate] = useState(null);
    const [elevationDate, setElevationDate] = useState(null);
    const [formattedElevationDate, setFormattedElevationDate] = useState(null);
    const [exaltationDate, setExaltationDate] = useState(null);
    const [formattedExaltationDate, setFormattedExaltationDate] = useState(null);
    const [filiationDate, setFiliationDate] = useState(null);
    const [formattedFiliationDate, setFormattedFiliationDate] = useState(null);
    const [installationDate, setInstallationDate] = useState(null);
    const [formattedInstallationDate, setFormattedInstallationDate] = useState(null);
    const [redemptionDate, setRedemptionDate] = useState(null);
    const [formattedRedemptionDate, setFormattedRedemptionDate] = useState(null);
    const [listDegree, setListDegree] = useState([]);
    const [listDegreeF, setListDegreeF] = useState([]);
    const [receivedDate, setReceivedDate] = useState(null);
    const [formattedReceivedDate, setFormattedReceivedDate] = useState(null);

    const [dateBorn, setDateBorn] = useState(null);
    const [formattedDateBorn, setFormattedDateBorn] = useState(null);
    const [maritalStatus, setMaritalStatus] = useState('');
    const listMaritalStatus = memberService.getMaritalStatusList();
    const [bloodType, setBloodType] = useState('');
    const listBloodType = memberService.getBloodTypeList();
    const [donator, setDonator] = useState('');
    const listDonator = memberService.getDonatorList();
    const [education, setEducation] = useState('');
    const listEducation = memberService.getEducationList();

    //Nascimento
    const [countriesBorn, setCountriesBorn] = useState([]);
    const [statesBorn, setStatesBorn] = useState([]);
    const [citiesBorn, setCitiesBorn] = useState([]);
    const [countryBorn, setCountryBorn] = useState('');
    const [stateBorn, setStateBorn] = useState('');
    const [cityBorn, setCityBorn] = useState('');
    const [countryBornId, setCountryBornId] = useState('');
    const [stateBornId, setStateBornId] = useState('');
    const [cityBornId, setCityBornId] = useState('');

    //Endereço - Residência
    const [cepHome, setCepHome] = useState('');
    const [addrStreetHome, setAddrStreetHome] = useState('');
    const [addrNumberHome, setAddrNumberHome] = useState('');
    const [addrNeighborhoodHome, setAddrNeighborhoodHome] = useState('');
    const [addrComplHome, setAddrComplHome] = useState('');
    const [cityHome, setCityHome] = useState('');
    const [stateHome, setStateHome] = useState('');
    const [countryHome, setCountryHome] = useState('');

    //Endereço - Trabalho
    const [localWork, setLocalWork] = useState('');
    const [workRole, setWorkRole] = useState('');
    const [cepWork, setCepWork] = useState('');
    const [addrStreetWork, setAddrStreetWork] = useState('');
    const [addrNumberWork, setAddrNumberWork] = useState('');
    const [addrNeighborhoodWork, setAddrNeighborhoodWork] = useState('');
    const [addrComplWork, setAddrComplWork] = useState('');
    const [cityWork, setCityWork] = useState('');
    const [stateWork, setStateWork] = useState('');
    const [countryWork, setCountryWork] = useState('');

    //Contato
    const [phoneCell, setPhoneCell] = useState('');
    const [phoneEmergency1, setPhoneEmergency1] = useState('');
    const [nameEmergency1, setNameEmergency1] = useState('');
    const [phoneEmergency2, setPhoneEmergency2] = useState('');
    const [nameEmergency2, setNameEmergency2] = useState('');
    const [phoneEmergency3, setPhoneEmergency3] = useState('');
    const [nameEmergency3, setNameEmergency3] = useState('');
    //Documentos
    const [idCard, setIdCard] = useState('');
    const [idUF, setIdUF] = useState('');
    const [agency, setAgency] = useState('');
    const [idVote, setIdVote] = useState('');
    const [voteZone, setVoteZone] = useState('');
    const [voteSection, setVoteSection] = useState('');
    const [profession, setProfession] = useState('');
    const [professionSpec, setProfessionSpec] = useState('');
    const [professionStatus, setProfessionStatus] = useState('');
    const listProfessionStatus = memberService.getProfessionStatusList();

    const selectOption = { label: 'Selecione', value: '0' };

    var headers = new Headers();
    headers.append("X-CSCAPI-KEY", "API_KEY");
    var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
    };

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [cpfError, setCpfError] = useState('');
    const [cimError, setCimError] = useState('');
    const [degreeError, setDegreeError] = useState('');

    const handleNameChange = (inputName) => {
        setName(inputName)
        setNameError('')
    }

    const handleStatusChange = (status) => {
        setMemberStatus(status)
        if (status == 1)
            setStatusReason('')
    }

    const validateEmail = (inputEmail) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(inputEmail);
    };

    const handleEmailChange = async (inputEmail) => {

        setEmail(inputEmail);

        if (!validateEmail(inputEmail)) {
            setEmailError('Digite um email válido');

        } else {

            let lExistEmail = await memberService.validateEmail(inputEmail);

            if (lExistEmail.data < 0) {
                setEmailError('Email já cadastrado!');
            } else {
                setEmailError('');
            }
        }
    };

    const handleCpfChange = async (inputCpf) => {
        const formattedCpf = formatCPF(inputCpf);
        setCPF(formattedCpf)
        if (!validateCPF(inputCpf)) {
            setCpfError('Digite um CPF válido');
        } else {
            setCpfError('');
            let lExistCpf = await memberService.validateCpf(inputCpf)

            if (lExistCpf.data < 0 && !memberEdit) {
                setCpfError('CPF já cadastrado!');
            }
        }
    }

    const handleCimChange = async (inputCim) => {
        setCIM(inputCim)
        if (inputCim) {
            let lExistCim = await memberService.validateCim(inputCim)
            if (lExistCim.data < 0 && !bEdit) {
                setCimError('CIM já cadastrado!');
            } else {
                setCimError('');
            }
        }
    }

    const handleDegreeChange = (event) => {
        setDegree(event.target.value);
        if (event.target.value > 0)
            setDegreeError('')
    };

    const handleDegreeFChange = (event) => {
        setDegreeF(event.target.value);
    };

    const handleCountryBornChange = async (event) => {
        const selectedCountryBorn = event.target.value;

        setCountryBornId(selectedCountryBorn);

        const filter = {
            id: selectedCountryBorn
        };

        const country = await countryService.getCountry(filter)

        setCountryBorn(country.data[0].name)
        createListStates(selectedCountryBorn)
    };

    const createListStates = async (id) => {
        const selectOption = { label: 'Selecione', value: '0' };

        try {
            const filterStates = {
                countryId: id
            };
            const response = await stateService.getState(filterStates);
            const data = await response.data;

            const sortedStates = [
                selectOption,
                ...data.map(state => ({
                    label: state.name,
                    value: state.id
                })).sort((a, b) => a.label.localeCompare(b.label))
            ];
            setStatesBorn(sortedStates);
        } catch (error) {
            messageError("Não foi possível encontrar Estados para esse País.");
        }
    }

    const handleStateBornChange = async (event) => {
        const selectedStateBorn = event.target.value;
        setStateBornId(selectedStateBorn);

        const filter = {
            id: selectedStateBorn
        };
        const state = await stateService.getState(filter)
        setStateBorn(state.data[0].name)
        createListCities(selectedStateBorn)

    };

    const createListCities = async (id) => {
        try {
            const filterCities = {
                stateId: id
            };
            const response = await cityService.getCity(filterCities);
            const data = await response.data;
            const sortedCities = [
                selectOption,
                ...data
                    .map(city => ({
                        label: city.name,
                        value: city.id
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
            ];

            setCitiesBorn(sortedCities);

        } catch (error) {
            messageError("Não foi possível encontrar cidades para esse Estado.");
        }
    }

    const handleCityBornChange = async (event) => {
        const selectedCity = event.target.value;
        setCityBornId(selectedCity);

        const filter = {
            id: selectedCity
        };
        const city = await cityService.getCity(filter)
        setCityBorn(city.data[0].name)


    }

    const handleDateBornChange = (date) => {

        if (date && date instanceof Date && !isNaN(date)) {
            // Formate a data usando a função format do date-fns
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setDateBorn(date);  // Set the Date object directly
            setFormattedDateBorn(formattedDate);
        } else {
            setDateBorn(null);
        }
    };

    const handleLodgeStartDateChange = (date) => {

        if (date && date instanceof Date && !isNaN(date)) {
            // Formate a data usando a função format do date-fns
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setLodgeStartDate(date);  // Set the Date object directly
            setFormattedLodgeStartDate(formattedDate);
        } else {
            setLodgeStartDate(null);
        }
    };

    const handleElevationDateChange = (date) => {

        if (date && date instanceof Date && !isNaN(date)) {
            // Formate a data usando a função format do date-fns
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setElevationDate(date);  // Set the Date object directly
            setFormattedElevationDate(formattedDate);
        } else {
            setElevationDate(null);
        }
    };

    const handleExaltationDateChange = (date) => {

        if (date && date instanceof Date && !isNaN(date)) {
            // Formate a data usando a função format do date-fns
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setExaltationDate(date);  // Set the Date object directly
            setFormattedExaltationDate(formattedDate);
        } else {
            setExaltationDate(null);
        }
    };

    const handleFiliationDateChange = (date) => {

        if (date && date instanceof Date && !isNaN(date)) {
            // Formate a data usando a função format do date-fns
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setFiliationDate(date);  // Set the Date object directly
            setFormattedFiliationDate(formattedDate);
        } else {
            setFiliationDate(null);
        }
    };

    const handleInstallationDateChange = (date) => {

        if (date && date instanceof Date && !isNaN(date)) {
            // Formate a data usando a função format do date-fns
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setInstallationDate(date);  // Set the Date object directly
            setFormattedInstallationDate(formattedDate);
        } else {
            setInstallationDate(null);
        }
    };

    const handleRedemptionDateChange = (date) => {

        if (date && date instanceof Date && !isNaN(date)) {
            // Formate a data usando a função format do date-fns
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setRedemptionDate(date);  // Set the Date object directly
            setFormattedRedemptionDate(formattedDate);
        } else {
            setRedemptionDate(null);
        }
    };

    const handleReceivedDateChange = (date) => {

        if (date && date instanceof Date && !isNaN(date)) {
            // Formate a data usando a função format do date-fns
            const formattedDate = format(date, 'yyyy-MM-dd', { locale: ptBR });
            setReceivedDate(date);  // Set the Date object directly
            setFormattedReceivedDate(formattedDate);
        } else {
            setReceivedDate(null);
        }
    };

    const handleMaritalStatusChange = (event) => {
        setMaritalStatus(event.target.value);
    };

    const handleCepHomeChange = async (event) => {
        const newCep = event.target.value.replace(/\D/g, '');
        if (newCep.length === 8) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${newCep}/json/`);
                const data = response.data;
                if (!data.erro) {
                    const filterCity = {
                        name: data.localidade
                    };
                    const city = await cityService.getCity(filterCity)

                    const filterState = {
                        id: city.data[0].stateId
                    };

                    const state = await stateService.getState(filterState)

                    const filterCountry = {
                        id: state.data[0].countryId
                    };

                    const country = await countryService.getCountry(filterCountry)

                    setAddrStreetHome(data.logradouro || '');
                    setAddrNumberHome(data.complemento || '');
                    setAddrNeighborhoodHome(data.bairro || '');
                    setCityHome(data.localidade || '')
                    setStateHome(state.data[0].name || '')
                    setCountryHome(country.data[0].name || '')

                } else {
                    messageError("CEP não encontrado.");
                }
            } catch (error) {

                messageError("Erro ao consultar CEP.");
            }
        } else {
            setAddrStreetHome('');
            setAddrComplHome('');
            setCityHome('')
            setStateHome('')
            setCountryHome('')
        }

        // Atualize o estado do CEP
        setCepHome(formatCep(newCep));
    };

    const handleCepWorkChange = async (event) => {
        const newCep = event.target.value.replace(/\D/g, '');

        if (newCep.length === 8) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${newCep}/json/`);
                const data = response.data;

                if (!data.erro) {

                    const filterCity = {
                        name: data.localidade
                    };
                    const city = await cityService.getCity(filterCity)

                    const filterState = {
                        id: city.data[0].stateId
                    };

                    const state = await stateService.getState(filterState)

                    const filterCountry = {
                        id: state.data[0].countryId
                    };

                    const country = await countryService.getCountry(filterCountry)

                    setAddrStreetWork(data.logradouro || '');
                    setAddrNumberWork(data.complemento || '');
                    setAddrNeighborhoodWork(data.bairro || '');
                    setCityWork(data.localidade || '')
                    setStateWork(state.data[0].name || '')
                    setCountryWork(country.data[0].name || '')

                } else {
                    messageError("CEP não encontrado.");
                }
            } catch (error) {
                messageError("Erro ao consultar CEP.");
            }
        } else {
            setAddrStreetWork('');
            setAddrComplWork('');
            setCityWork('')
            setStateWork('')
            setCountryWork('')
        }

        // Atualize o estado do CEP
        setCepWork(formatCep(newCep));
    };

    const checkMandatoryFields = () => {

        if (name === '')
            setNameError('Nome inválido')
        if (cim === '')
            setCimError('Cim inválido!')
        if (cpf === '')
            setCpfError('CPF inválido')
        if (email === '')
            setEmailError('Email inválido')
        if (degree == 0) {
            setDegreeError('Grau inválido')
        }

        if (name === '' || cim === '' || cpf === '' || email === '' || degree === 0) {
            messageError('Preencha os campos obrigatórios.')
            return;
        } else {
            setVisibleModal(true);
        }

    }

    const handleConfirmation = async (confirmed) => {
        setVisibleModal(false);

        if (emailError || cpfError || nameError || cimError || degreeError) {
            messageError("Corrija os campos em vermelho.")
            return
        }


        if (confirmed) {
            Confirm();
        }
    };

    const cancelEdit = () => {
        localStorage.removeItem('member-edit');
        navigate('/member-list');
        return;
    }

    const Confirm = async () => {
        if (!bEdit) {
            await memberService.new({
                name: name,
                email: email,
                cpf: cpf,
                cim: cim,
                lodgeDegree: degree,
                lodgeDegreeF: degreeF,
                lodgeRole: lodgeRole,
                lodgeParty: lodgeParty,
                annotation: annotation,
                fatherName: father,
                motherName: mother,
                countryBorn: countryBorn,
                cityBorn: cityBorn,
                stateBorn: stateBorn,
                dtBorn: formattedDateBorn,
                maritalStatus: maritalStatus,
                bloodType: bloodType,
                donator: donator,
                addrHome: addrStreetHome,
                numberHome: addrNumberHome,
                neighborhoodHome: addrNeighborhoodHome,
                complHome: addrComplHome,
                cityHome: cityHome,
                stateHome: stateHome,
                countryHome: countryHome,
                cepHome: cepHome,
                addrWork: addrStreetWork,
                numberWork: addrNumberWork,
                neighborhoodWork: addrNeighborhoodWork,
                complWork: addrComplWork,
                cityWork: cityWork,
                stateWork: stateWork,
                countryWork: countryWork,
                cepWork: cepWork,
                phoneCell: phoneCell,
                nameEmergency1: nameEmergency1,
                phoneEmergency1: phoneEmergency1,
                nameEmergency2: nameEmergency2,
                phoneEmergency2: phoneEmergency2,
                nameEmergency3: nameEmergency3,
                phoneEmergency3: phoneEmergency3,
                idCard: idCard,
                idUF: idUF,
                idAgency: agency,
                idVote: idVote,
                voteZone: voteZone,
                voteSection: voteSection,
                profession: profession,
                professionSpec: professionSpec,
                professionStatus: professionStatus,
                lodgeStart: lodgeStart,
                lodgeStartState: lodgeStartState,
                dtLodgeStart: formattedLodgeStartDate,
                dtElevation: formattedElevationDate,
                dtExaltation: formattedExaltationDate,
                dtFiliation: formattedFiliationDate,
                dtInstallation: formattedInstallationDate,
                dtRedemption: formattedRedemptionDate,
                educationDegree: education,
                status: 1,
                statusReason: statusReason,
                famm: famm,
                localWork: localWork,
                workRole: workRole

            }).then(response => {
                messageSuccess('Usuário cadastrado com sucesso.')
            }).catch(error => {
                messageError(error.response.data)
            })
        } else {
            let tempDateBorn, tempLodgeStartDate, tempElevationDate, tempExaltationDate, tempFiliationDate, tempInstallationDate, tempRedemptionDate;
            if (dateBorn) {
                tempDateBorn = format(dateBorn, 'yyyy-MM-dd', { locale: ptBR });
            }
            if (lodgeStartDate) {
                tempLodgeStartDate = format(lodgeStartDate, 'yyyy-MM-dd', { locale: ptBR });
            }
            if (elevationDate) {
                tempElevationDate = format(elevationDate, 'yyyy-MM-dd', { locale: ptBR });
            }
            if (exaltationDate) {
                tempExaltationDate = format(exaltationDate, 'yyyy-MM-dd', { locale: ptBR });
            }
            if (filiationDate) {
                tempFiliationDate = format(filiationDate, 'yyyy-MM-dd', { locale: ptBR });
            }
            if (installationDate) {
                tempInstallationDate = format(installationDate, 'yyyy-MM-dd', { locale: ptBR });
            }
            if (redemptionDate) {
                tempRedemptionDate = format(redemptionDate, 'yyyy-MM-dd', { locale: ptBR });
            }

            let memberId =
                await memberService.update({
                    id: memberEditId,
                    name: name,
                    email: email,
                    cpf: cpf,
                    cim: cim,
                    lodgeDegree: degree,
                    lodgeDegreeF: degreeF,
                    lodgeRole: lodgeRole,
                    lodgeParty: lodgeParty,
                    fatherName: father,
                    motherName: mother,
                    countryBorn: countryBorn,
                    cityBorn: cityBorn,
                    stateBorn: stateBorn,
                    dtBorn: tempDateBorn,
                    maritalStatus: maritalStatus,
                    bloodType: bloodType,
                    donator: donator,
                    addrHome: addrStreetHome,
                    numberHome: addrNumberHome,
                    neighborhoodHome: addrNeighborhoodHome,
                    complHome: addrComplHome,
                    cityHome: cityHome,
                    stateHome: stateHome,
                    countryHome: countryHome,
                    cepHome: cepHome,
                    addrWork: addrStreetWork,
                    numberWork: addrNumberWork,
                    neighborhoodWork: addrNeighborhoodWork,
                    complWork: addrComplWork,
                    cityWork: cityWork,
                    stateWork: stateWork,
                    countryWork: countryWork,
                    cepWork: cepWork,
                    phoneCell: phoneCell,
                    nameEmergency1: nameEmergency1,
                    phoneEmergency1: phoneEmergency1,
                    nameEmergency2: nameEmergency2,
                    phoneEmergency2: phoneEmergency2,
                    nameEmergency3: nameEmergency3,
                    phoneEmergency3: phoneEmergency3,
                    idCard: idCard,
                    idUF: idUF,
                    idAgency: agency,
                    idVote: idVote,
                    voteZone: voteZone,
                    voteSection: voteSection,
                    profession: profession,
                    professionSpec: professionSpec,
                    professionStatus: professionStatus,
                    annotation: annotation,
                    lodgeStart: lodgeStart,
                    lodgeStartState: lodgeStartState,
                    dtLodgeStart: tempLodgeStartDate,
                    dtElevation: tempElevationDate,
                    dtExaltation: tempExaltationDate,
                    dtFiliation: tempFiliationDate,
                    dtInstallation: tempInstallationDate,
                    dtRedemption: tempRedemptionDate,
                    educationDegree: education,
                    status: memberStatus,
                    statusReason: statusReason,
                    famm: famm,
                    localWork: localWork,
                    workRole: workRole

                }).then(response => {
                    messageSuccess('Informações atualizadas com sucesso.')
                }).catch(error => {
                    messageError(error.response.data)
                })

        }
        navigate('/member-list')
        return;
    }

    const formatCep = (cep) => {
        return cep
            .replace(/\D/g, '') // Remove todos os caracteres não numéricos
            .replace(/^(\d{2})(\d)/, '$1.$2') // Insere um ponto após os dois primeiros dígitos
            .replace(/\.(\d{3})(\d)/, '.$1-$2'); // Insere um traço após os três dígitos seguintes
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, ''); // Remove qualquer caracter não numérico
        const match = cleaned.match(/^(\d{2})(\d{1,5})?(\d{0,4})$/);
        if (match) {
            const part1 = match[1] ? `(${match[1]})` : '';
            const part2 = match[2] ? ` ${match[2]}` : '';
            const part3 = match[3] ? `-${match[3]}` : '';
            return `${part1}${part2}${part3}`;
        }
        return phoneNumber; // Retorna o original se não houver correspondência
    };

    const handlePhoneCellChange = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhoneCell(formattedPhone); // Assume que você tem um estado chamado `phoneCell` para o telefone celular
    };

    const handlePhoneEmergency1Change = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhoneEmergency1(formattedPhone); // Assume que você tem um estado chamado `phoneCell` para o telefone celular
    };
    const handlePhoneEmergency2Change = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhoneEmergency2(formattedPhone); // Assume que você tem um estado chamado `phoneCell` para o telefone celular
    };
    const handlePhoneEmergency3Change = (e) => {
        const formattedPhone = formatPhoneNumber(e.target.value);
        setPhoneEmergency3(formattedPhone); // Assume que você tem um estado chamado `phoneCell` para o telefone celular
    };

    const handleSaveMedal = async () => {

        if (memberEdit) {
            await medalsService.new({
                title: title,
                member: memberEdit,
                dtReceived: formattedReceivedDate
            }).then(response => {
                messageSuccess('Título/Condecoração cadastrado com sucesso.')
                window.location.reload();
            }).catch(error => {
                messageError(error.response.data)
            })
        }
    };


    const deleteMedal = async (id) => {
        try {
            const response = await medalsService.deleteMedal(id); // Usar a função renomeada
            if (response.status === 204) { // Status 204 indica que a deleção foi bem-sucedida e não há conteúdo a retornar
                messageSuccess("Título / Condecoração deletado(a) com sucesso!");
                window.location.reload();
            } else {
                messageError("Título / Condecoração não encontrado(a).");
            }
        } catch (error) {
            messageError(error);
            if (error.response) {
                messageError(`Erro ao deletar Título / Condecoração`);
            } else {
                messageError("Erro ao conectar com o serviço de Títulos / Condecorações.");
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {

                //Verificar se o usuário está logado
                if (isAuthenticated) {
                    if (user.role === 1) {
                        setVisible(true);
                    } else {
                        const responseMember = await memberService.getById(user.member);
                        const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_ALL_MEMBER_INFO);
                        if (responseCanEdit.data) {
                            setVisible(true);
                        } else {
                            messageError("Você não possui acesso.")
                            navigate('/member-list');
                            return;

                        }
                    }

                    const countriesList = await countryService.getAll();
                    const data = await countriesList.data;

                    // Adicione a opção "Selecione" à lista de países
                    const selectOption = { label: 'Selecione', value: '0' };
                    const sortedCountries = [
                        selectOption,
                        ...data.map(country => ({
                            label: country.name,
                            value: country.id
                        })).sort((a, b) => a.label.localeCompare(b.label))
                    ];

                    setCountriesBorn(sortedCountries);

                    const statList = await statusService.getAll();
                    const dataStatus = await statList.data;
                    const listStats = [
                        ...dataStatus.map(stat => ({
                            label: stat.description,
                            value: stat.id
                        }))
                    ];

                    setListStatus(listStats);


                    const lodgeRoleList = await lodgeRoleService.getAll();

                    const listLodgeRoles = [
                        ...lodgeRoleList.data.map(role => ({
                            label: role.description,
                            value: role.id
                        }))
                    ];

                    setListAllLodgeRoles(listLodgeRoles);



                    const lodgePartyList = await lodgePartyService.getAll();
                    const listLodgeParties = [
                        selectOption,
                        ...lodgePartyList.data.map(party => ({
                            label: party.description,
                            value: party.id
                        }))
                    ];
                    setListAllLodgeParties(listLodgeParties);


                    const lodgeDegreeList = await degreeService.getAll();
                    const listLodgeDegree = [
                        { label: 'Selecione', value: 0 },  // Adiciona o item "Selecione" no início da lista
                        ...lodgeDegreeList.data
                            .filter(degree => degree.id <= 3)
                            .map(degree => ({
                                label: `${degree.id} - ${degree.description}`,
                                value: degree.id
                            }))
                    ];
                    setListDegree(listLodgeDegree);

                    const listLodgeDegreeF = [
                        { label: 'Selecione', value: 0 },  // Adiciona o item "Selecione" no início da lista
                        ...lodgeDegreeList.data
                            .filter(degree => degree.id >= 4)
                            .map(degree => ({
                                label: `${degree.id} - ${degree.description}`,
                                value: degree.id
                            }))
                    ];
                    setListDegreeF(listLodgeDegreeF);

                    const titleList = await titleService.getAll();
                    const listTitles = [
                        { label: 'Selecione', value: 0 },  // Adiciona o item "Selecione" no início da lista
                        ...titleList.data
                            .sort((a, b) => a.minYears - b.minYears)
                            .sort((a, b) => a.agency.localeCompare(b.agency))
                            .map(title => ({
                                label: `${title.agency} - ${title.description} - ${title.minYears} anos`,
                                value: title.id
                            }))
                    ];
                    setListAllTitles(listTitles);



                    if (storedMemberEdit) {
                        setMemberEdit(storedMemberEdit)
                        const response = await memberService.getById(JSON.parse(storedMemberEdit));

                        const memberEdit = response.data;
                        if (memberEdit) {
                            setMemberEditId(memberEdit.id)
                            setBEdit(true)
                            setName(memberEdit.name)
                            setCPF(formatCPF(memberEdit.cpf))
                            setEmail(memberEdit.email)
                            setFather(memberEdit.fatherName)
                            setMother(memberEdit.motherName)
                            setDonator(memberEdit.donator)
                            setMemberStatus(memberEdit.status)
                            setStatusReason(memberEdit.statusReason)

                            const filterCountryBorn = {
                                name: memberEdit.countryBorn
                            };
                            const tempCountry = await countryService.getCountry(filterCountryBorn)

                            let tempCountryId = tempCountry.data[0].id

                            setCountryBorn(memberEdit.countryBorn)
                            setCountryBornId(tempCountryId)
                            createListStates(tempCountryId)


                            const filterStateBorn = {
                                name: memberEdit.stateBorn
                            };
                            const tempState = await stateService.getState(filterStateBorn)

                            let tempStateId = tempState.data[0].id

                            setStateBorn(memberEdit.stateBorn)
                            setStateBornId(tempStateId)
                            createListCities(tempStateId)

                            const filterCityBorn = {
                                name: memberEdit.cityBorn
                            };
                            const tempCity = await cityService.getCity(filterCityBorn)

                            let tempCityId = tempCity.data[0].id

                            setCityBorn(memberEdit.cityBorn)
                            setCityBornId(tempCityId)
                            if (memberEdit.dtBorn) {
                                setDateBorn(new Date(memberEdit.dtBorn[0], memberEdit.dtBorn[1] - 1, memberEdit.dtBorn[2]))
                            }

                            setMaritalStatus(memberEdit.maritalStatus)
                            setBloodType(memberEdit.bloodType)
                            setEducation(memberEdit.educationDegree)
                            setPhoneCell(memberEdit.phoneCell)
                            setNameEmergency1(memberEdit.nameEmergency1)
                            setPhoneEmergency1(memberEdit.phoneEmergency1)
                            setNameEmergency2(memberEdit.nameEmergency2)
                            setPhoneEmergency2(memberEdit.phoneEmergency2)
                            setNameEmergency3(memberEdit.nameEmergency3)
                            setPhoneEmergency3(memberEdit.phoneEmergency3)
                            setCepHome(formatCep(memberEdit.cepHome))
                            setAddrStreetHome(memberEdit.addrHome)
                            setAddrNumberHome(memberEdit.numberHome)
                            setAddrNeighborhoodHome(memberEdit.neighborhoodHome)
                            setAddrComplHome(memberEdit.complHome)
                            setCityHome(memberEdit.cityHome)
                            setStateHome(memberEdit.stateHome)
                            setCountryHome(memberEdit.countryHome)
                            setCepWork(formatCep(memberEdit.cepWork))
                            setAddrStreetWork(memberEdit.addrWork)
                            setAddrNumberWork(memberEdit.numberWork)
                            setAddrNeighborhoodWork(memberEdit.neighborhoodWork)
                            setAddrComplWork(memberEdit.complWork)
                            setCityWork(memberEdit.cityWork)
                            setStateWork(memberEdit.stateWork)
                            setCountryWork(memberEdit.countryWork)
                            setCIM(memberEdit.cim)
                            setAnnotation(memberEdit.annotation)
                            setDegree(memberEdit.lodgeDegree)
                            setDegreeF(memberEdit.lodgeDegreeF)
                            setLodgeRole(memberEdit.lodgeRole)
                            setLodgeParty(memberEdit.lodgeParty)
                            setLodgeStart(memberEdit.lodgeStart)
                            setLodgeStartState(memberEdit.lodgeStartState)
                            if (memberEdit.dtLodgeStart) {
                                setLodgeStartDate(new Date(memberEdit.dtLodgeStart[0], memberEdit.dtLodgeStart[1] - 1, memberEdit.dtLodgeStart[2]))
                            }
                            if (memberEdit.dtElevation) {
                                setElevationDate(new Date(memberEdit.dtElevation[0], memberEdit.dtElevation[1] - 1, memberEdit.dtElevation[2]))
                            }
                            if (memberEdit.dtExaltation) {
                                setExaltationDate(new Date(memberEdit.dtExaltation[0], memberEdit.dtExaltation[1] - 1, memberEdit.dtExaltation[2]))
                            }
                            if (memberEdit.dtFiliation) {
                                setFiliationDate(new Date(memberEdit.dtFiliation[0], memberEdit.dtFiliation[1] - 1, memberEdit.dtFiliation[2]))
                            }
                            if (memberEdit.dtInstallation) {
                                setInstallationDate(new Date(memberEdit.dtInstallation[0], memberEdit.dtInstallation[1] - 1, memberEdit.dtInstallation[2]))
                            }
                            if (memberEdit.dtRedemption) {
                                setRedemptionDate(new Date(memberEdit.dtRedemption[0], memberEdit.dtRedemption[1] - 1, memberEdit.dtRedemption[2]))
                            }
                            setIdCard(memberEdit.idCard)
                            setIdUF(memberEdit.idUF)
                            setAgency(memberEdit.idAgency)
                            setProfession(memberEdit.profession)
                            setProfessionSpec(memberEdit.professionSpec)
                            setProfessionStatus(memberEdit.professionStatus)
                            setIdVote(memberEdit.idVote)
                            setVoteZone(memberEdit.voteZone)
                            setVoteSection(memberEdit.voteSection)
                            setFamm(memberEdit.famm)
                            setLocalWork(memberEdit.localWork)
                            setWorkRole(memberEdit.workRole)

                            const medals = await medalsService.getMedalsByMemberId(memberEdit.id)
                            setMedals(medals.data)
                        }
                    }

                } else {
                    navigate('/login');
                }

            } catch (error) {
                if (error.response && error.response.status === 400) {
                    messageError('Requisição inválida. Por favor, efetue o login como.');
                } else {
                    messageError('Erro ao buscar informações.');
                }
            }


        };

        fetchData();

    }, []);

    const renderTabContent = () => {
        switch (activeTab) {
            case 'personalInfo':
                return (
                    <>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <h5><b>Informações Pessoais</b></h5>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <>
                            <FormRow>
                                <FormField>
                                    <FormGroup
                                        label="Nome: *"
                                        htmlFor="inputName"
                                        error={nameError}
                                    >
                                        <input
                                            className={`form-control ${nameError ? 'is-invalid' : ''}`}
                                            type="text"
                                            id="inputName"
                                            name="name"
                                            value={name}
                                            placeholder="Digite o nome"
                                            onChange={(e) => handleNameChange(e.target.value)}
                                        />
                                        {nameError && (
                                            <div className="invalid-feedback">
                                                {nameError}
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup
                                        label="CPF: *"
                                        htmlFor="inputCpf"
                                        error={cpfError}
                                    >
                                        <input
                                            className={`form-control ${cpfError ? 'is-invalid' : ''}`}
                                            type="text"
                                            id="inputCpf"
                                            name="cpf"
                                            value={cpf}
                                            placeholder="Digite o CPF"
                                            onChange={(e) => handleCpfChange(e.target.value)}
                                        />
                                        {cpfError && (
                                            <div className="invalid-feedback">
                                                {cpfError}
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup
                                        label="Email: *"
                                        htmlFor="inputEmail"
                                        error={emailError}
                                    >
                                        <input
                                            className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                            type="email"
                                            id="inputEmail"
                                            name="email"
                                            value={email}
                                            placeholder="Digite o email"
                                            onChange={(e) => handleEmailChange(e.target.value)}
                                        />
                                        {emailError && (
                                            <div className="invalid-feedback">
                                                {emailError}
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormField>

                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Pai: " htmlFor="inputFather">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="fatherInput1"
                                            name="father"
                                            value={father}
                                            placeholder="Digite o nome do pai"
                                            onChange={(e) => setFather(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Mãe: " htmlFor="inputMother">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="motherInput1"
                                            name="mother"
                                            value={mother}
                                            placeholder="Digite o nome da mãe"
                                            onChange={(e) => setMother(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Doador de Órgãos: " htmlFor="selectDonator">
                                        <br />
                                        <SelectMenu
                                            className="form-control"
                                            list={listDonator}
                                            value={donator}
                                            onChange={(e) => setDonator(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="País de Nascimento: " htmlFor="inputCountryBorn">
                                        {/* Utilizando um componente de seleção para os países */}
                                        <SelectMenu
                                            className="form-control"
                                            list={countriesBorn}
                                            value={countryBornId}
                                            label={countryBorn}
                                            onChange={handleCountryBornChange}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Estado de Nascimento: " htmlFor="inputStateBorn">
                                        {/* Utilizando um componente de seleção para os estados */}
                                        <SelectMenu
                                            className="form-control"
                                            list={statesBorn}
                                            value={stateBornId}
                                            onChange={handleStateBornChange}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Cidade de Nascimento: " htmlFor="inputCityBorn">
                                        {/* Utilizando um componente de seleção para os estados */}
                                        <SelectMenu
                                            className="form-control"
                                            list={citiesBorn}
                                            value={cityBornId}
                                            onChange={handleCityBornChange}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Data de Nascimento: " htmlFor="inputDateBorn">
                                        <br />
                                        <DatePicker
                                            selected={dateBorn}
                                            onChange={handleDateBornChange}
                                            dateFormat="dd/MM/yyyy"
                                            showTimeSelect={false}
                                            locale="pt-BR"
                                            placeholderText="DD/MM/AAAA"
                                            value={dateBorn}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Estado Civil: " htmlFor="selectMaritalStatus">
                                        <br />
                                        <SelectMenu
                                            className="form-control"
                                            list={listMaritalStatus}
                                            value={maritalStatus}
                                            onChange={(e) => setMaritalStatus(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Tipo Sanguíneo: " htmlFor="selectBloodType">
                                        <br />
                                        <SelectMenu
                                            className="form-control"
                                            list={listBloodType}
                                            value={bloodType}
                                            onChange={(e) => setBloodType(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Grau de Escolaridade: " htmlFor="selectEducation">
                                        <br />
                                        <SelectMenu
                                            style={{ width: '33%' }} // Ajuste a largura conforme necessário
                                            className="form-control"
                                            list={listEducation}
                                            value={education}
                                            onChange={(e) => setEducation(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Beneficiário FAMM: " htmlFor="inputFamm">
                                        <br />
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="fammInput1"
                                            name="famm"
                                            placeholder=""
                                            value={famm}
                                            onChange={(e) => setFamm(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                        </>
                    </>
                );

            case 'contact':
                return (
                    <>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <h5><b>Contato</b></h5>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <>
                            <FormRow>
                                <FormField style={{ marginRight: '2px' }}> {/* Estilo inline para reduzir o espaço */}
                                    <label htmlFor="inputPhoneCell">Telefone Celular:</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        style={{ width: '22%' }}
                                        id="inputPhoneCell"
                                        name="phoneCell"
                                        placeholder="Digite o Celular"
                                        value={phoneCell}
                                        onChange={handlePhoneCellChange}
                                    />
                                </FormField>
                            </FormRow><br />

                            <FormRow>
                                <FormField style={{ margin: '10px 5px' }}>
                                    <FormGroup label="Contato de Emergência 1: " htmlFor="inputNameEmergency1">
                                        <input
                                            className="form-control"
                                            style={{ width: '90%' }}
                                            type="text"
                                            id="nameEmergencyInput1"
                                            name="nameEmergency1"
                                            placeholder="Digite o Nome"
                                            value={nameEmergency1}
                                            onChange={(e) => setNameEmergency1(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField style={{ margin: '10px 5px' }}>
                                    <FormGroup label="Telefone de Emergência 1: " htmlFor="inputPhoneEmergency1">
                                        <input
                                            className="form-control"
                                            style={{ width: '90%' }}
                                            type="text"
                                            id="phoneEmergencyInput1"
                                            name="phoneEmergency1"
                                            placeholder="Digite o numero do contato de Emergência"
                                            value={phoneEmergency1}
                                            onChange={handlePhoneEmergency1Change}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField></FormField>
                                <FormField></FormField>
                            </FormRow><br />

                            <FormRow>
                                <FormField style={{ margin: '10px 5px' }}>
                                    <FormGroup label="Contato de Emergência 2: " htmlFor="inputNameEmergency2">
                                        <input
                                            className="form-control"
                                            style={{ width: '90%' }}
                                            type="text"
                                            id="nameEmergencyInput2"
                                            name="nameEmergency"
                                            placeholder="Digite o Nome"
                                            value={nameEmergency2}
                                            onChange={(e) => setNameEmergency2(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField style={{ margin: '10px 5px' }}>
                                    <FormGroup label="Telefone de Emergência 2: " htmlFor="inputPhoneEmergency2">
                                        <input
                                            className="form-control"
                                            style={{ width: '90%' }}
                                            type="text"
                                            id="phoneEmergencyInput2"
                                            name="phoneEmergency2"
                                            placeholder="Digite o numero do contato de Emergência"
                                            value={phoneEmergency2}
                                            onChange={handlePhoneEmergency2Change}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField></FormField>
                                <FormField></FormField>
                            </FormRow><br />

                            <FormRow>
                                <FormField style={{ margin: '10px 5px' }}>
                                    <FormGroup label="Contato de Emergência 3: " htmlFor="inputNameEmergency3">
                                        <input
                                            className="form-control"
                                            style={{ width: '90%' }}
                                            type="text"
                                            id="nameEmergencyInput3"
                                            name="nameEmergency3"
                                            placeholder="Digite o Nome"
                                            value={nameEmergency3}
                                            onChange={(e) => setNameEmergency3(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField style={{ margin: '10px 5px' }}>
                                    <FormGroup label="Telefone de Emergência 3: " htmlFor="inputPhoneEmergency3">
                                        <input
                                            className="form-control"
                                            style={{ width: '90%' }}
                                            type="text"
                                            id="phoneEmergencyInput3"
                                            name="phoneEmergency3"
                                            placeholder="Digite o numero do contato de Emergência"
                                            value={phoneEmergency3}
                                            onChange={handlePhoneEmergency3Change}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField></FormField>
                                <FormField></FormField>
                            </FormRow><br />


                        </>
                    </>
                );

            case 'residentialAddress':
                return (
                    <>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <h5><b>Endereço Residencial</b></h5>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <>
                            <FormRow>
                                <FormField>
                                    <FormGroup label="CEP: " htmlFor="inputCepHome">
                                        <input
                                            style={{ width: '200px' }} // Ajuste a largura conforme necessário
                                            className="form-control"
                                            type="text"
                                            id="cepInput1"
                                            name="cep"
                                            placeholder="Digite o CEP"
                                            value={cepHome}
                                            onChange={handleCepHomeChange}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Rua: " htmlFor="inputaddrStreetHome">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrStreetHomeInput1"
                                            name="addrStreetHome"
                                            value={addrStreetHome}
                                            readOnly
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Número: " htmlFor="inputAddrNumber">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrNumberInput1"
                                            name="addrNumberHome"
                                            value={addrNumberHome}
                                            placeholder="Digite o número"
                                            onChange={(e) => setAddrNumberHome(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Bairro: " htmlFor="inputAddrNeighborhoodHome">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrNeighborhoodHomeInput1"
                                            name="addrNeighborhoodHome"
                                            value={addrNeighborhoodHome}
                                            placeholder="Digite o bairro"
                                            onChange={(e) => setAddrNeighborhoodHome(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Complemento: " htmlFor="inputAddrCompl">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrComplInput1"
                                            name="addrComplHome"
                                            value={addrComplHome}
                                            placeholder="Casa, apartamento, lote"
                                            onChange={(e) => setAddrComplHome(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Cidade: " htmlFor="inputcityHome">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrcityHomeInput1"
                                            name="cityHome"
                                            value={cityHome}
                                            readOnly
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Estado: " htmlFor="inputstateHome">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrstateHomeInput1"
                                            name="stateHome"
                                            value={stateHome}
                                            readOnly
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="País: " htmlFor="inputcountryHome">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrcountryHomeInput1"
                                            name="countryHome"
                                            value={countryHome}
                                            readOnly
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                        </>
                    </>
                );
            case 'workAddress':
                return (
                    <>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <h5><b>Endereço Profissional</b></h5>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <>
                            <FormRow>
                                <FormField>
                                    <FormGroup label="CEP: " htmlFor="inputCepWork">
                                        <input
                                            style={{ width: '200px' }} // Ajuste a largura conforme necessário
                                            className="form-control"
                                            type="text"
                                            id="cepInput1"
                                            name="cep"
                                            placeholder="Digite o CEP"
                                            value={cepWork}
                                            onChange={handleCepWorkChange}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Local de Trabalho: " htmlFor="inputLocalWork">
                                        <br />
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="localWorkInput1"
                                            name="localWork"
                                            placeholder="Indique o local de trabalho"
                                            value={localWork}
                                            onChange={(e) => setLocalWork(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Cargo / Função: " htmlFor="inputWorkRole">
                                        <br />
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="workRoleInput1"
                                            name="localWork"
                                            placeholder="Indique o cargo atual"
                                            value={workRole}
                                            onChange={(e) => setWorkRole(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Rua: " htmlFor="inputaddrStreetWork">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrStreetWorkInput1"
                                            name="addrStreetWork"
                                            value={addrStreetWork}
                                            readOnly
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Número: " htmlFor="inputAddrNumber">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrNumberInput1"
                                            name="addrNumberWork"
                                            value={addrNumberWork}
                                            placeholder="Digite o número"
                                            onChange={(e) => setAddrNumberWork(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Bairro: " htmlFor="inputAddrNeighborhoodWork">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrNeighborhoodWorkInput1"
                                            name="addrNeighborhoodWork"
                                            value={addrNeighborhoodWork}
                                            placeholder="Digite o bairro"
                                            onChange={(e) => setAddrNeighborhoodWork(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Complemento: " htmlFor="inputAddrCompl">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrComplInput1"
                                            name="addrComplWork"
                                            value={addrComplWork}
                                            placeholder="Casa, apartamento, lote"
                                            onChange={(e) => setAddrComplWork(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Cidade: " htmlFor="inputcityWork">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrcityWorkInput1"
                                            name="cityWork"
                                            value={cityWork}
                                            readOnly
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Estado: " htmlFor="inputstateWork">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrstateWorkInput1"
                                            name="stateWork"
                                            value={stateWork}
                                            readOnly
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="País: " htmlFor="inputcountryWork">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="addrCountryWorkInput1"
                                            name="countryWork"
                                            value={countryWork}
                                            readOnly
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                        </>
                    </>
                );
            case 'lodgeInfo':
                return (
                    <>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <h5><b>Informações Loja</b></h5>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <>
                            <FormRow>
                                <FormField>
                                    <FormGroup
                                        label="CIM: *"
                                        htmlFor="inputCim"
                                        error={cimError}
                                    >
                                        <input
                                            className={`form-control ${cimError ? 'is-invalid' : ''}`}
                                            type="text"
                                            id="inputCim"
                                            name="cim"
                                            value={cim}
                                            placeholder="Digite o CIM"
                                            onChange={(e) => handleCimChange(e.target.value)}
                                        />
                                        {cimError && (
                                            <div className="invalid-feedback">
                                                {cimError}
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Grau Simbólico: *" htmlFor="selectGrau" error={degreeError}>
                                        <br />
                                        <SelectMenu
                                            className={`form-control ${degreeError ? 'is-invalid' : ''}`}
                                            list={listDegree}
                                            value={degree}
                                            onChange={handleDegreeChange}
                                        />{degreeError && (
                                            <div className="invalid-feedback">
                                                {degreeError}
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Grau Filosófico: " htmlFor="selectGrau">
                                        <br />
                                        <SelectMenu
                                            className="form-control"
                                            list={listDegreeF}
                                            value={degreeF}
                                            onChange={handleDegreeFChange}
                                        />
                                    </FormGroup>
                                </FormField>

                                <FormField>
                                    <FormGroup label="Cargo na Loja: " htmlFor="inputLodgeRole">
                                        <br />
                                        <SelectMenu
                                            className="form-control"
                                            list={listAllLodgeRoles}
                                            value={lodgeRole}
                                            onChange={(e) => setLodgeRole(e.target.value)}
                                            disabled={!visible}
                                        />
                                    </FormGroup>
                                </FormField>

                            </FormRow >
                            <br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Comissão: " htmlFor="inputLodgeParty">
                                        <br />
                                        <SelectMenu
                                            className="form-control"
                                            list={listAllLodgeParties}
                                            value={lodgeParty}
                                            onChange={(e) => setLodgeParty(e.target.value)}
                                            disabled={!visible}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Loja de Iniciação: " htmlFor="inputLodgeStart">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="lodgeStartInput1"
                                            name="lodgeStart"
                                            value={lodgeStart}
                                            placeholder="Digite a loja de iniciação"
                                            onChange={(e) => setLodgeStart(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Oriente de Iniciação: " htmlFor="inputLodgeStartState">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="lodgeStartInput1"
                                            name="lodgeStartState"
                                            value={lodgeStartState}
                                            placeholder="Digite o oriente de iniciação"
                                            onChange={(e) => setLodgeStartState(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>

                            </FormRow >
                            <br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Data de Iniciação: " htmlFor="inputLodgeStartDate">
                                        <br />
                                        <DatePicker
                                            selected={lodgeStartDate}
                                            onChange={handleLodgeStartDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            showTimeSelect={false}
                                            locale="pt-BR"
                                            placeholderText="DD/MM/AAAA"
                                            value={lodgeStartDate}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Data de Elevação: " htmlFor="inputElevationDate">
                                        <br />
                                        <DatePicker
                                            selected={elevationDate}
                                            onChange={handleElevationDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            showTimeSelect={false}
                                            locale="pt-BR"
                                            placeholderText="DD/MM/AAAA"
                                            value={elevationDate}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Data de Exaltação: " htmlFor="inputExaltationDate">
                                        <br />
                                        <DatePicker
                                            selected={exaltationDate}
                                            onChange={handleExaltationDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            showTimeSelect={false}
                                            locale="pt-BR"
                                            placeholderText="DD/MM/AAAA"
                                            value={exaltationDate}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField></FormField>
                            </FormRow>
                            <br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Data de Filiação / Regularização: " htmlFor="inputFiliationDate">
                                        <br />
                                        <DatePicker
                                            selected={filiationDate}
                                            onChange={handleFiliationDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            showTimeSelect={false}
                                            locale="pt-BR"
                                            placeholderText="DD/MM/AAAA"
                                            value={filiationDate}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Data de Instalação: " htmlFor="inputInstallationDate">
                                        <br />
                                        <DatePicker
                                            selected={installationDate}
                                            onChange={handleInstallationDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            showTimeSelect={false}
                                            locale="pt-BR"
                                            placeholderText="DD/MM/AAAA"
                                            value={installationDate}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Data Remido: " htmlFor="inputRedemptionDate">
                                        <br />
                                        <DatePicker
                                            selected={redemptionDate}
                                            onChange={handleRedemptionDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            showTimeSelect={false}
                                            locale="pt-BR"
                                            placeholderText="DD/MM/AAAA"
                                            value={redemptionDate}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField></FormField>
                            </FormRow>
                            <br /><br />
                            {/*-----------------------------------------------------------------------------------------------------------*/}
                            <h5><b>Títulos e Condecorações</b></h5>
                            {/*-----------------------------------------------------------------------------------------------------------*/}
                            <div>
                                {visible && (
                                    <FormRow>
                                        <FormField>
                                            <FormGroup label="Títulos / Condecoração: " htmlFor="inputTitles">
                                                <SelectMenu
                                                    className="form-control"
                                                    list={listAllTitles}
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    disabled={!visible}
                                                />
                                            </FormGroup>
                                        </FormField>
                                        <div>
                                            <FormField>
                                                <FormGroup label="Data de Concessão: " htmlFor="inputReceivedDate">
                                                    <br />
                                                    <DatePicker
                                                        selected={receivedDate}
                                                        onChange={handleReceivedDateChange}
                                                        dateFormat="dd/MM/yyyy"
                                                        showTimeSelect={false}
                                                        locale="pt-BR"
                                                        placeholderText="DD/MM/AAAA"
                                                        value={receivedDate}
                                                    />
                                                </FormGroup>
                                            </FormField>
                                        </div>
                                        <FormField className="button-container">
                                            <Button className="btn-sm btn btn-info" onClick={handleSaveMedal} style={{ fontSize: "14px", height: '35px', marginTop: '2vh' }}>
                                                Adicionar
                                            </Button>
                                        </FormField>
                                    </FormRow>
                                )}

                                <br />

                                <MedalsDT
                                    medals={medals}
                                    visible={visible}
                                    deleteMedal={deleteMedal}
                                />

                            </div>



                            <br /> <br />
                            <div>
                                {/*-----------------------------------------------------------------------------------------------------------*/}
                                <h5><b>Observações</b></h5>
                                {/*-----------------------------------------------------------------------------------------------------------*/}
                                <FormRow>

                                    <FormField>
                                        <FormGroup label="Anotações Diversas: " htmlFor="inputAnnotation">
                                            <br />
                                            <textarea
                                                className="form-control"
                                                id="annotationInput1"
                                                name="annotation"
                                                value={annotation}
                                                placeholder="Escreva aqui"
                                                onChange={(e) => setAnnotation(e.target.value)}
                                                style={{ height: 'auto' }}
                                                rows="5" // define a altura inicial
                                            />
                                        </FormGroup>
                                    </FormField>

                                </FormRow>
                            </div>
                        </>
                    </>
                );
            case 'personalDocuments':
                return (
                    <>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <h5><b>Documentos</b></h5>
                        {/*-----------------------------------------------------------------------------------------------------------*/}
                        <>
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Documento de identificação Civil/Profissional: " htmlFor="inputIdCard">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="idCardInput1"
                                            name="idCard"
                                            placeholder="Digite o Número"
                                            value={idCard}
                                            onChange={(e) => setIdCard(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="UF do documento: " htmlFor="inputIdUF">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="idUFInput1"
                                            name="idUF"
                                            placeholder="Digite a sigla do estado"
                                            value={idUF}
                                            onChange={(e) => setIdUF(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Órgão Emissor: " htmlFor="inputAgency">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="agencyInput1"
                                            name="agency"
                                            placeholder="Digite o órgão emissor do documento"
                                            value={agency}
                                            onChange={(e) => setAgency(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Profissão: " htmlFor="inputProfession">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="professionInput1"
                                            name="profession"
                                            placeholder="Digite a profissão"
                                            value={profession}
                                            onChange={(e) => setProfession(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Especialização: " htmlFor="inputProfessionSpec">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="professionSpecInput1"
                                            name="professionSpec"
                                            placeholder="Digite a especialização"
                                            value={professionSpec}
                                            onChange={(e) => setProfessionSpec(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Situação Profissional: " htmlFor="inputProfessionStatus">
                                        <br />
                                        <SelectMenu
                                            className="form-control"
                                            list={listProfessionStatus}
                                            value={professionStatus}
                                            onChange={(e) => setProfessionStatus(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />
                            <FormRow>
                                <FormField>
                                    <FormGroup label="Título de Eleitor: " htmlFor="inputIdVote">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="idVoteInput1"
                                            name="idVote"
                                            placeholder="Digite o número do título de eleitor"
                                            value={idVote}
                                            onChange={(e) => setIdVote(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Zona Eleitoral: " htmlFor="inputVoteZone">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="voteZoneInput1"
                                            name="voteZone"
                                            placeholder="Digite a zona eleitoral"
                                            value={voteZone}
                                            onChange={(e) => setVoteZone(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                                <FormField>
                                    <FormGroup label="Seção Eleitoral: " htmlFor="inputVoteSection">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="voteSectionInput1"
                                            name="voteSection"
                                            placeholder="Digite a seção eleitoral"
                                            value={voteSection}
                                            onChange={(e) => setVoteSection(e.target.value)}
                                        />
                                    </FormGroup>
                                </FormField>
                            </FormRow><br />

                        </>
                    </>
                );
            case 'status':
                return (
                    <>
                        {bEdit && (
                            <div>
                                <FormRow>
                                    <FormField>
                                        <FormGroup label="Situação: " htmlFor="selectStatus">
                                            <br />
                                            <SelectMenu
                                                style={{ width: '200px' }}
                                                className="form-control"
                                                list={listStatus}
                                                value={memberStatus}
                                                onChange={(e) => handleStatusChange(e.target.value)}
                                            />
                                        </FormGroup>
                                    </FormField>
                                    <FormField>

                                    </FormField>
                                </FormRow><br />
                            </div>

                        )}
                        {memberStatus != 1 && (
                            <div>
                                <FormRow>
                                    <FormField>
                                        <FormGroup label="Motivo: " htmlFor="inputIdReason">
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                id="reasonInput1"
                                                name="statusReason"
                                                placeholder="Escreva o motivo"
                                                value={statusReason}
                                                onChange={(e) => setStatusReason(e.target.value)}
                                                style={{ height: 'auto' }}
                                                rows="5"
                                            />
                                        </FormGroup>
                                    </FormField>
                                </FormRow>
                            </div>
                        )}

                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            <Container>
                <Card title={memberEdit ? `Irmão - ${name} - ${cim}` : `Novo Irmão`}>

                    <Nav
                        variant="tabs"
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="personalInfo" style={{ color: activeTab === 'personalInfo' ? 'darkblue' : 'black' }}>Informações Pessoais</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="contact" style={{ color: activeTab === 'contact' ? 'darkblue' : 'black' }}>Contato</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="lodgeInfo" style={{ color: activeTab === 'lodgeInfo' ? 'darkblue' : 'black' }}>Loja</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="personalDocuments" style={{ color: activeTab === 'personalDocuments' ? 'darkblue' : 'black' }}>Documentos Pessoais</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="residentialAddress" style={{ color: activeTab === 'residentialAddress' ? 'darkblue' : 'black' }}>Endereço Residencial</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="workAddress" style={{ color: activeTab === 'workAddress' ? 'darkblue' : 'black' }}>Endereço Profissional</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="status" style={{ color: activeTab === 'status' ? 'darkblue' : 'black' }}>Situação</Nav.Link>
                        </Nav.Item>

                    </Nav>

                    {renderTabContent()}



                    <br />
                </Card >

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        className="btn btn-secondary p-button-text"
                        onClick={cancelEdit}
                    >
                        Cancelar
                    </button>

                    <button
                        className="btn btn-success p-button-text"
                        onClick={() => checkMandatoryFields()}
                        style={{ marginLeft: '10px' }}
                    >
                        Confirmar
                    </button>
                </div>

                <Dialog
                    header="Confirmação"
                    visible={visibleModal}
                    style={{ width: '25vw' }}
                    onHide={() => handleConfirmation(false)}
                    modal={true}
                >
                    <p className="m-0">Confirmar cadastro do usuário?</p>
                    <br />
                    <div className="p-d-flex p-jc-end">
                        <button
                            className="btn btn-success p-button-text"
                            onClick={() => handleConfirmation(true)}
                        >
                            Confirmar
                        </button>
                        {' '}
                        <button
                            className="btn btn-secondary p-button-text"
                            onClick={() => handleConfirmation(false)}
                        >
                            Cancelar
                        </button>
                    </div>
                </Dialog>
            </Container >
        </div >
    );
};

export default MemberRegister;
