import ApiService from '../apiservice';

class TitleService extends ApiService {

    constructor() {
        super('/title');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }

}

export default TitleService;