//Calendar.js:

import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { messageSuccess, messageError } from '../../components/toastr';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventService from '../../app/service/eventService';
import Event_TypeService from '../../app/service/event_TypeService';
import RolePermissionService from "../../app/service/rolePermissionService";
import UserService from '../../app/service/userService';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/pt-br';
import { Modal, Button } from 'react-bootstrap';
import MemberService from "../../app/service/memberService";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import QRCode from 'qrcode';
import fontkit from '@pdf-lib/fontkit';
import PRMTSService from '../../app/service/prmtsService';
import { AuthContext } from '../../security/AuthContext';
import { drawHeader } from '../../components/pdfHeader';
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import * as Constant from '../../config/constants';
import RegisterEvent from "./event-register";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const localizer = momentLocalizer(moment);
moment.locale('pt-br');

const messages = {
    allDay: 'Dia inteiro',
    previous: 'Anterior',
    next: 'Próximo',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'Nenhum evento nesta faixa de datas.',
};

const EventCalendar = () => {
    const navigate = useNavigate();
    const eventService = useMemo(() => new EventService(), []);
    const event_TypeService = useMemo(() => new Event_TypeService(), []);
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const [eventTypeList, setEventTypeList] = useState([]);
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false); // State for controlling modal visibility
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [visible, setVisible] = useState(false);
    const [memberName, setMemberName] = useState('');
    const [visibleQrCrode, setVisibleQrCrode] = useState(false);
    const [visibleAttendance, setVisibleAttendance] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleManager, setVisibleManager] = useState(false);
    const [showModalEventEdit, setShowModalEventEdit] = useState(false);
    const { user, isAuthenticated } = useContext(AuthContext);
    const mountList = (data) => {
        return data.map(item => ({ label: `${item.type} ${item.description}`, value: item.id }));
    }

    const handleEventSearch = useCallback(async () => {
        try {
            const response = await eventService.getAll();
            if (Array.isArray(response.data) && response.data.length > 0) {
                const formattedEvents = response.data.map(event => ({
                    id: event.id,
                    type: event.type.id,
                    title: (
                        <div>
                            <div>{event.description}</div>
                            {event.degree > 0 ? (
                                <div>Grau: {event.degree}</div>
                            ) : (
                                <div>&nbsp;</div>
                            )}
                        </div>
                    ),
                    orderDay: event.orderDay,
                    start: new Date(event.dtStart[0], event.dtStart[1] - 1, event.dtStart[2], event.dtStart[3], event.dtStart[4]),
                    end: new Date(event.dtEnd[0], event.dtEnd[1] - 1, event.dtEnd[2], event.dtEnd[3], event.dtEnd[4])
                }));

                setEvents(formattedEvents);
            } else {
                setEvents([]);
                messageError("Nenhum resultado encontrado.");
            }
        } catch (error) {
            messageError(`Erro ao realizar a busca. <br /> ${error.response.data}`);
        }
    }, [eventService]);

    const handleEventClick = async (eventPicked) => {
        localStorage.setItem('event-edit', JSON.stringify(eventPicked.id));
        const responseEvent = await eventService.getById(eventPicked.id)
        setSelectedEvent(eventPicked);
        setShowModal(true);
    }

    const handleAttendanceListClick = async (eventPicked) => {
        navigate('/attendance-list');
        return;
    }

    const handleQRCode = async (eventPicked) => {
        try {
            const qrCodeResponse = await eventService.generateQRCode(eventPicked);
            const qrCodeData = qrCodeResponse.data;

            // Fetch dayTimeSessionDescription
            const responseDayTime = await prmtsService.getByType('DAYTIME-SESSION');
            let dayTimeSessionDescription = responseDayTime.data ? responseDayTime.data.description : '';
            // Generate QR code image
            const qrCodeImage = await QRCode.toDataURL(qrCodeData, { width: 300 });

            // Create a new PDF document
            const pdfDoc = await PDFDocument.create();
            pdfDoc.registerFontkit(fontkit);
            const page = pdfDoc.addPage([600, 800]);

            // Load the custom font
            const fontUrl = `${process.env.PUBLIC_URL}/fonts/GreatVibes-Regular.ttf`; // Atualize este caminho para o caminho real do seu arquivo de fonte
            const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
            const fontGreatVibes = await pdfDoc.embedFont(fontBytes);

            // Embed standard fonts
            const fontRegular = await pdfDoc.embedFont(StandardFonts.Helvetica);
            const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
            const fonts = {
                fontGreatVibes,
                fontRegular,
                fontBold
            };

            let yPosition = await drawHeader(page, fonts, dayTimeSessionDescription);

            // Initial y position
            //yPosition = page.getHeight() - 50; // Start 50 units from top
            let lineSpacing = 50;

            let fontSize = 36;
            const fontSize2 = 10;

            fontSize = 24;
            yPosition -= fontSize + lineSpacing;
            const responseEvent = await eventService.getById(eventPicked)
            // Add text above the QR code
            let text = `${responseEvent.data.description} - ${moment(selectedEvent.start).format('DD/MM/YYYY')} `;
            const textWidth = fontBold.widthOfTextAtSize(text, fontSize);
            page.drawText(text, {
                x: (page.getWidth() - textWidth) / 2,
                y: yPosition,
                size: fontSize,
                font: fontBold,
                color: rgb(0, 0, 0),
            });
            lineSpacing = 50;
            yPosition -= fontSize2 + lineSpacing;

            text = `Ordem do dia:`;
            page.drawText(text, {
                x: (page.getWidth() - textWidth) / 2,
                y: yPosition,
                size: fontSize2,
                font: fontBold,
                color: rgb(0, 0, 0),
            });
            lineSpacing = 10;
            yPosition -= fontSize2 + lineSpacing;

            text = `${responseEvent.data.orderDay}`;
            page.drawText(text, {
                x: (page.getWidth() - textWidth) / 2,
                y: yPosition,
                size: fontSize2,
                font: fontRegular,
                color: rgb(0, 0, 0),
            });
            lineSpacing = 60;
            yPosition -= fontSize2 + lineSpacing;


            text = "Registre sua presença!";
            const textWidth5 = fontBold.widthOfTextAtSize(text, fontSize);
            page.drawText(text, {
                x: (page.getWidth() - textWidth5) / 2,
                y: yPosition - 100,
                size: fontSize,
                font: fontBold,
                color: rgb(0, 0, 0),
            });

            // Draw the QR code image in the center of the page
            const qrCodeImageBytes = await fetch(qrCodeImage).then(res => res.arrayBuffer());
            const qrCodeImageEmbed = await pdfDoc.embedPng(qrCodeImageBytes);

            const { width, height } = qrCodeImageEmbed.scale(1);

            page.drawImage(qrCodeImageEmbed, {
                x: (page.getWidth() - width) / 2,
                y: (page.getHeight() - height) / 2 - 250,
                width: width,
                height: height,
            });

            // Serialize the PDF document to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            // Create a Blob from the PDF bytes
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });

            // Open the PDF in a new tab
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (error) {
            console.error('Erro ao gerar o QR Code ou o PDF:', error);
        }
    };

    const editEvent = async (id) => {
        setShowModalEventEdit(true);
        //navigate('/event-register');
    }

    const clearCache = () => {
        localStorage.removeItem('event-edit')
    }

    const cancelEvent = async (id) => {
        const user = await userService.getByToken(localStorage.getItem("token"));

        clearCache();
        if (user.data.role !== 1 && !visibleEdit) {
            messageError("Você não possui acesso.")
            navigate('/home');
            return;
        } else {
            try {
                const response = await eventService.getById(id);
                if (response && response.data.id) {
                    eventService.delete(response.data.id)
                    messageSuccess("Evento cancelado com sucesso.");
                } else {
                    messageError("Evento não encontrado.");
                }
            } catch (error) {
                messageError("Erro ao buscar dados no banco.");
            }
        }
        window.location.reload()
    }


    const handleEventSaved = async () => {
        setShowModalEventEdit(false);
        setShowModal(false);
        await handleEventSearch();  // Atualiza a lista de eventos, se necessário
    };


    useEffect(() => {
        const fetchData = async () => {
            clearCache();
            try {
                localStorage.removeItem('event-edit');

                if (isAuthenticated) {
                    if (user) {
                        if (user.role !== 1) {
                            const responseMember = await memberService.getById(user.member);
                            const responseAttendanceList = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.VIEW_SCREEN_ATTENDANCE_LIST);
                            setVisibleAttendance(responseAttendanceList.data);
                            const responseQrCrode = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.GENERATE_QR_CODE);
                            setVisibleQrCrode(responseQrCrode.data);
                            const responseEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_EVENT);
                            setVisibleEdit(responseEdit.data);
                            const responseManage = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.MANAGE_CALENDAR);
                            setVisibleManager(responseManage.data);
                        } else {
                            setVisibleAttendance(true);
                            setVisibleQrCrode(true);
                            setVisibleEdit(true);
                            setVisibleManager(true);
                        }

                    }
                } else {
                    navigate('/login');
                }
                await handleEventSearch();
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    messageError('Requisição inválida. Por favor, tente novamente.');
                } else {
                    messageError('Erro ao buscar informações.');
                }
            }
        };

        fetchData();
    }, [handleEventSearch]);

    return (
        <>
            <div style={{ height: '500px' }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ width: '100%' }}
                    messages={messages}
                    onSelectEvent={event => handleEventClick(event)}
                    eventPropGetter={(event, start, end, isSelected) => {
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);

                        const style = {
                            color: 'white',
                            borderRadius: '0px',
                            border: start.toDateString() === today.toDateString() ? '2px solid gold' : 'none',
                            display: 'block',
                            cursor: 'pointer',
                        };

                        return { style };
                    }}
                />
            </div>

            <Modal show={showModal} onHide={() => { clearCache(); setShowModal(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes da Sessão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Render the details of the selected event */}
                    {selectedEvent && (
                        <div>
                            <p>{selectedEvent.title}</p>
                            <p>Data da Sessão: {selectedEvent.start.toLocaleDateString('pt-BR', { day: '2-digit', month: 'short', year: 'numeric' })}</p>
                            <p>Horário: {moment(selectedEvent.start).format('HH:mm') + 'h'} às {moment(selectedEvent.end).format('HH:mm') + 'h'}</p>
                            <p>Ordem do dia: {selectedEvent.orderDay ? (selectedEvent.orderDay.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))) : ''}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {/* Add a button to navigate to the attendance list page */}

                    {visibleAttendance && (
                        <Button variant="primary" onClick={() => {
                            setShowModal(false);
                            handleAttendanceListClick();
                        }}>
                            Lista de presença
                        </Button>
                    )}
                    {visibleQrCrode && (
                        <>
                            <Button variant="primary" onClick={() => {
                                setShowModal(false);
                                handleQRCode(selectedEvent.id);
                            }}>
                                QRCode
                            </Button>
                        </>
                    )}
                    {visibleEdit && (
                        <>
                            <Button variant="info" onClick={() => {
                                editEvent(selectedEvent.id);
                                setShowModal(false); // Close the modal when navigating
                            }}>
                                Editar
                            </Button>
                            <Button variant="danger" onClick={() => {
                                cancelEvent(selectedEvent.id);
                                setShowModal(false); // Close the modal when navigating
                            }}>
                                Excluir
                            </Button>
                        </>
                    )}

                    <Button variant="secondary" onClick={() => { clearCache(); setShowModal(false) }}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={showModalEventEdit}
                onHide={() => { clearCache(); setShowModalEventEdit(false) }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes do Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RegisterEvent onEventSaved={handleEventSaved} />
                </Modal.Body>
            </Modal>

            <br />
            {
                visibleManager && (
                    <>
                        <div>
                            <button className="btn btn-info" onClick={e => navigate('/event-register')}>Cadastrar novo evento</button>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default EventCalendar;