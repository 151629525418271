// constants.js
export const MEMBER_ACCESS = 0;
export const PUBLIC_ACCESS = 99;

//Gestão
export const VIEW_SCREEN_BOARD = 12;
export const EDIT_BOARD = 13;

//Calendário
export const GENERATE_QR_CODE = 7;
export const EDIT_EVENT = 8;
export const MANAGE_CALENDAR = 3
export const VIEW_SCREEN_CALENDAR = 10;

//Lista de Presença
export const VIEW_SCREEN_ATTENDANCE_LIST = 6;
export const EDIT_ATTENDANCE_LIST = 5;
export const PRINT_CERTIFICATE = 36;

//Membros
export const VIEW_SCREEN_MEMBER_LIST = 16;
export const VIEW_OWN_MEMBER_INFO = 17;
export const VIEW_ALL_MEMBER_INFO = 15;
export const EDIT_ALL_MEMBER_INFO = 14;

// Cunhadas
export const VIEW_SCREEN_SPOUSE_LIST = 35;
export const EDIT_SPOUSE = 19;

//Sobrinhos
export const VIEW_SCREEN_CHILDREN_LIST = 38;
export const EDIT_CHILDREN = 22;


//Relatórios
export const GENERATE_PRESENCE_BYMEMBER = 30; //presenceByMember
export const GENERATE_PRESENCE_BYPERIOD = 40; //presenceByPeriod
export const GENERATE_BIRTHDAY_BYPERIOD = 27; //birthdayByPeriod
export const GENERATE_BIRTHDAY_SPOUSES = 28; //birthdaySpouses
export const GENERATE_PRESENCE_BYSESSION = 29; //presenceBySession
export const GENERATE_PRESENCE_ALL = 25; //allReports
export const GENERATE_PRESENCE_OWN = 31; //ownPresenceByMember
export const VIEW_SCREEN_REPORT = 39; //presenceByMember

//Visitantes
export const VIEW_SCREEN_VISITOR_LIST = 32;
export const EDIT_VISITORS_INFO = 33;

//Página Inicial
export const VIEW_SCREEN_HOME = 9;
export const EDIT_DAYTIME_SESSION = 1;
export const EDIT_ABOUT = 42;


//Atividades
export const VIEW_SCREEN_ACTIVITIES = 11;

//Tesouraria
export const VIEW_SCREEN_TREASURY = 45;

