import ApiService from '../apiservice';

class RoleService extends ApiService {

    constructor() {
        super('/role');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }

    getByDescription(description) {
        return this.get(`${this.apiurl}/description/${description}`);
    }
}

export default RoleService;