// TaxesPaymentsService.js

import ApiService from '../apiservice';

class TaxesPaymentsService extends ApiService {

    constructor() {
        super('/taxes-payments'); // Certifique-se de que a base URL está correta
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    new(payment) {
        return this.post(`${this.apiurl}/new`, payment);
    }

    updatePayment(id, payment) {
        return this.put(`${this.apiurl}/${id}`, payment);
    }

    includeForAll(payment) {
        console.log(`Chegou aqui:::  ${this.apiurl}/include/all`)
        return this.post(`${this.apiurl}/include/all`, payment);
    }

    includeForOne(payment) {
        return this.post(`${this.apiurl}/include?member=${payment.member}`, payment);
    }

    // Novo método para filtragem
    filterPayments(memberId, year) {
        return this.get(`${this.apiurl}/filter?member=${memberId}&year=${year}`);
    }



}

export default TaxesPaymentsService;
