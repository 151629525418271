

import React from "react";

class Loading extends React.Component {
    render() {
        return (
            <>
                <style>
                    {`
                .loading-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.7);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 9999;
                }

                .loading-overlay.hidden {
                    display: none;
                }

                .loading-spinner {
                    border: 4px solid rgba(0, 0, 0, 0.1);
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    border-left-color: #09f;
                    animation: spin 1s ease infinite;
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }

                .loading-cursor {
                    cursor: progress!important;
                }
                `}
                </style>
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            </>
        )
    }
}

export default Loading;
