import React, { useState, useEffect, useMemo, useContext } from "react";
import Card from "../../components/card";
import Container from "../../components/container";
import SelectMenu from "../../components/selectMenu";
import { useNavigate } from 'react-router-dom';
import FormGroup from "../../components/form-group";
import Loading from "../../components/loading"
import MemberService from "../../app/service/memberService";
import EventService from "../../app/service/eventService";
import AttendanceService from "../../app/service/attendanceService";
import UserService from "../../app/service/userService.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import SpouseService from "../../app/service/spouseService.js";
import ChildrenService from "../../app/service/childrenService.js";
import { messageSuccess, messageError } from '../../components/toastr';
import { AuthContext } from '../../security/AuthContext';
import { pdfHeader } from '../../components/pdfHeaderPdfMake.js'; // Ajuste o caminho conforme necessário
import customVfs from "../../components/customVfs"; // Ajuste o caminho conforme necessário
import PRMTSService from '../../app/service/prmtsService';
import RolePermissionService from "../../app/service/rolePermissionService";
import * as Constant from '../../config/constants';

const Report = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    pdfMake.vfs = {
        ...pdfFonts.pdfMake.vfs,
        ...customVfs,
    };
    pdfMake.fonts = {
        Roboto: {
            normal: "Roboto-Regular.ttf",
            bold: "Roboto-Medium.ttf",
            italics: "Roboto-Italic.ttf",
            bolditalics: "Roboto-MediumItalic.ttf",
        },
        GreatVibes: {
            normal: "GreatVibes-Regular.ttf",
            bold: "GreatVibes-Regular.ttf",
            italics: "GreatVibes-Regular.ttf",
            bolditalics: "GreatVibes-Regular.ttf",
        },
    };
    const navigate = useNavigate();

    const startDateInitial = new Date();
    const currentDay = startDateInitial.getDay();
    const daysUntilNextMonday = (currentDay === 1) ? 0 : (8 - currentDay);
    startDateInitial.setDate(startDateInitial.getDate() + daysUntilNextMonday);

    const [startDateBirth, setStartDateBirth] = useState(startDateInitial);
    const [endDateBirth, setEndDateBirth] = useState(new Date(startDateInitial.getTime() + (6 * 24 * 60 * 60 * 1000)));
    const [startDateBirthSpouses, setStartDateBirthSpouses] = useState(startDateInitial);
    const [endDateBirthSpouses, setEndDateBirthSpouses] = useState(new Date(startDateInitial.getTime() + (6 * 24 * 60 * 60 * 1000)));
    const [dayTimeSessionDescription, setDayTimeSessionDescription] = useState("");
    const [month, setMonth] = useState(0);
    const [event, setEvent] = useState(0);
    const [byPeriodPresenceType, setByPeriodPresenceType] = useState(0);
    const [byEventType, setByEventType] = useState(0);
    const [date, setDate] = useState([]);
    const [eventDescriptionDate, setEventDescriptionDate] = useState("");
    const [member, setMember] = useState("");
    const [selectedMemberName, setSelectedMemberName] = useState("");
    const [selectedMemberDegree, setSelectedMemberDegree] = useState("");
    const [listEvents, setListEvents] = useState([]);
    const [listMembers, setListMembers] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startDateMemberAtt, setStartDateMemberAtt] = useState(new Date());
    const [endDateMemberAtt, setEndDateMemberAtt] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const memberService = useMemo(() => new MemberService(), []);
    const spouseService = useMemo(() => new SpouseService(), []);
    const childrenService = useMemo(() => new ChildrenService(), []);
    const eventService = useMemo(() => new EventService(), []);
    const attendanceService = useMemo(() => new AttendanceService(), []);
    const userService = useMemo(() => new UserService(), []);
    const prmtsService = useMemo(() => new PRMTSService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const [visible, setVisible] = useState(false);
    const [memberError, setMemberError] = useState('');
    const [eventError, setEventError] = useState('');
    const [allReports, setAllReports] = useState([]);
    const [presenceByMember, setPresenceByMember] = useState(false);
    const [ownPresenceByMember, setOwnPresenceByMember] = useState(false);
    const [presenceBySession, setPresenceBySession] = useState(false);
    const [presenceByPeriod, setPresenceByPeriod] = useState(false);
    const [birthdayByPeriod, setBirthdayByPeriod] = useState(false);
    const [birthdaySpouses, setBirthdaySpouses] = useState(false);


    registerLocale('pt-BR', ptBR);

    const listMonth = [
        { label: "Selecione", value: 0 },
        { label: "Janeiro", value: 1 },
        { label: "Fevereiro", value: 2 },
        { label: "Março", value: 3 },
        { label: "Abril", value: 4 },
        { label: "Maio", value: 5 },
        { label: "Junho", value: 6 },
        { label: "Julho", value: 7 },
        { label: "Agosto", value: 8 },
        { label: "Setembro", value: 9 },
        { label: "Outubro", value: 10 },
        { label: "Novembro", value: 11 },
        { label: "Dezembro", value: 12 }
    ];

    function getMonthName(month) {
        const months = [
            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
            "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
        ];
        return months[month - 1];
    }

    const handleBirthdayPeriodSpouses = async () => {
        setLoading(true);
        try {
            const formattedStartDate = format(startDateBirthSpouses, 'MM-dd');
            const formattedEndDate = format(endDateBirthSpouses, 'MM-dd');
            const responseSpouses = await spouseService.getSpousesByBirthdayPeriod(formattedStartDate, formattedEndDate);
            const spouses = responseSpouses.data;
            await generatePdfBirthdayPeriodSpouses(spouses);
        } catch (error) {
            messageError("Erro ao buscar cônjuges que fazem aniversário");
        } finally {
            setLoading(false);
        }
    };

    const generatePdfBirthdayPeriodSpouses = async (spouses) => {

        const formatAddress = (addr, number, compl, neighborhood, city, state, country, cepHome) => {
            const parts = [addr, number, compl, neighborhood, city, state, country, "CEP: " + cepHome];
            const filteredParts = parts.filter(part => part && part.trim() !== "");
            return filteredParts.length > 0 ? filteredParts.join(', ') : "";
        };
        const { headerContent, headerStyles } = pdfHeader(dayTimeSessionDescription);

        const formattedSpouses = await Promise.all(spouses.map(async (spouse) => {
            const dtBorn = new Date(spouse.dtBorn);
            try {
                const memberResponse = await memberService.getById(spouse.member);
                const member = memberResponse.data;
                const formattedHomeAddress = formatAddress(
                    member.addrHome, member.numberHome, member.complHome,
                    member.neighborhoodHome, member.cityHome, member.stateHome, member.countryHome, member.cepHome
                );

                const memberInfo = member.name + ' \n ' + member.phoneCell;
                return {
                    name: spouse.name,
                    dtBorn: dtBorn,
                    memberInfo,
                    formattedHomeAddress
                };
            } catch (error) {
                messageError("Não encontrado o irmão referente à " + spouse.name);
            }
        }));

        // Ordenar as esposas pelo mês e dia
        const sortedSpouses = formattedSpouses.sort((a, b) => {
            if (a.dtBorn.getMonth() === b.dtBorn.getMonth()) {
                return a.dtBorn.getDate() - b.dtBorn.getDate();
            }
            return a.dtBorn.getMonth() - b.dtBorn.getMonth();
        });

        const spouseRows = sortedSpouses.map(spouse => [
            spouse.name,
            `${spouse.dtBorn.getDate().toString().padStart(2, '0')}/${(spouse.dtBorn.getMonth() + 1).toString().padStart(2, '0')}`,
            spouse.memberInfo,
            spouse.formattedHomeAddress
        ]);

        const tableHeader = [
            { text: "Cunhada", bold: true, fillColor: '#eeeeee' },
            { text: "Data", bold: true, fillColor: '#eeeeee' },
            { text: "Irmão", bold: true, fillColor: '#eeeeee' },
            { text: "Endereço", bold: true, fillColor: '#eeeeee' }
        ];

        const table = {
            headerRows: 1,
            widths: ["auto", "auto", "auto", "auto"],
            body: [
                tableHeader,
                ...spouseRows.length > 0 ? spouseRows : [[{ text: "Nenhum aniversariante para este período.", colSpan: 4 }]]
            ],
        };

        const documentDefinition = {
            content: [
                ...headerContent,
                { text: `Aniversariantes no Período de ${format(startDateBirthSpouses, "dd/MM/yyyy")} a ${format(endDateBirthSpouses, "dd/MM/yyyy")}`, style: "subheader" },
                { table },
            ],
            styles: {
                ...headerStyles,
                subheader: {
                    fontSize: 14,
                    bold: true,
                    alignment: "center",
                    margin: [0, 0, 0, 10],
                    font: 'Roboto',
                },
            },
            footer: function (currentPage, pageCount) {
                return {
                    text: currentPage.toString() + '/' + pageCount,
                    alignment: 'right',
                    margin: [0, 0, 40, 0]
                };
            }
        };

        pdfMake.createPdf(documentDefinition).open();
    };

    const handleBirthdayPeriod = async () => {
        setLoading(true);
        try {
            const formattedStartDate = format(startDateBirth, 'MM-dd');
            const formattedEndDate = format(endDateBirth, 'MM-dd');
            const [responseMembers, responseSpouses, responseChildren, responseMarriage, responseInitiation, responseFiliation] = await Promise.all([
                memberService.getMembersByBirthdayPeriod(formattedStartDate, formattedEndDate),
                spouseService.getSpousesByBirthdayPeriod(formattedStartDate, formattedEndDate),
                childrenService.getChildrenByBirthdayPeriod(formattedStartDate, formattedEndDate),
                spouseService.getSpousesByMarriagePeriod(formattedStartDate, formattedEndDate),
                memberService.getMembersByInitiationPeriod(formattedStartDate, formattedEndDate),
                memberService.getMembersByFiliationPeriod(formattedStartDate, formattedEndDate)
            ]);
            const formattedStart = format(startDateBirth, "dd/MM/yyyy");
            const formattedEnd = format(endDateBirth, "dd/MM/yyyy");
            await generatePdfBirthdayByPeriod(responseMembers.data, responseSpouses.data, responseChildren.data, responseMarriage.data, responseInitiation.data, responseFiliation.data, formattedStart, formattedEnd);
        } catch (error) {
            messageError("Erro ao buscar membros que fazem aniversário");
        } finally {
            setLoading(false);
        }
    };

    const handleMemberChange = async (event) => {
        if (!event.target.value) {
            setMemberError('Campo Obrigatório')
        } else {
            setMemberError('')
        }

        setMember(event.target.value);
        if (event.target.value !== '') {
            const responseMember = await memberService.getById(event.target.value);
            setSelectedMemberName(responseMember.data.name);
            setSelectedMemberDegree(responseMember.data.lodgeDegree);
        }
    }

    const handleAttendanceByEvent = async () => {
        if (!event) {
            setEventError('Campo Obrigatório');
        } else {
            setEventError('');
            setLoading(true);
            try {
                // Obter detalhes de presença do evento
                const response = await attendanceService.getAttendanceDetailsByEvent(event);
                await generatePdfAttendanceByEvent(response.data);
            } catch (error) {
                messageError("Erro ao buscar eventos e presenças.");
            } finally {
                setLoading(false);
            }
        }
    };

    const generatePdfAttendanceByEvent = async (attendanceDetails) => {
        try {
            let countPresent = 0, countExcuse = 0, countVisitant = 0;
            const { headerContent, headerStyles } = pdfHeader(dayTimeSessionDescription);

            // Filtrar membros
            const filteredMembers = attendanceDetails.filter((item) => {
                if (byEventType === 1 && item.excuse === 'S') {
                    return false; // Ignorar abonados se o tipo for 1
                }
                if (item.visitorId != null) {
                    return false; // Excluir visitantes
                }
                return true;
            });

            // Mapear membros filtrados para construir tableBody
            const tableBody = filteredMembers.map((item, index) => {
                const presenceStatus = item.excuse === 'S' ? 'Abonado' : 'Presente';
                if (item.excuse === 'S') {
                    countExcuse++;
                } else {
                    countPresent++;
                }
                return [
                    index + 1,
                    item.memberName,
                    presenceStatus
                ];
            });
            const tableHeader = [
                { text: "Nº", bold: true },
                { text: "Nome do Membro", bold: true },
                { text: "Presença", bold: true }
            ];


            // Filtrar visitantes
            const filteredVisitors = attendanceDetails.filter((item) => item.visitorId != null);

            // Mapear visitantes filtrados para construir tableBodyVisitors
            const tableBodyVisitors = filteredVisitors.map((item, index) => {
                countVisitant++;
                return [
                    index + 1,
                    item.visitorName,
                    item.visitorLodge + ' - ' + item.visitorLodgeState
                ];
            });


            const table = {
                headerRows: 1,
                widths: ["auto", "*", "auto"],
                body: [tableHeader, ...tableBody],
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 0 : 1;
                    },
                    vLineWidth: function (i) {
                        return 0;
                    },
                }
            };

            const tableHeaderVisitor = [
                { text: "Nº", bold: true },
                { text: "Nome do Visitante", bold: true },
                { text: "Loja", bold: true, alignment: "center" }
            ];

            const tableVisitors = {
                headerRows: 1,
                widths: ["auto", "*", "*"],
                body: [tableHeaderVisitor, ...tableBodyVisitors],
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 0 : 1;
                    },
                    vLineWidth: function (i) {
                        return 0;
                    },
                }
            };

            // Obter detalhes do evento
            const eventResponse = await eventService.getById(event);
            const eventData = eventResponse.data;
            const formattedDate = new Date(eventData.dtStart[0], eventData.dtStart[1] - 1, eventData.dtStart[2]).toLocaleDateString('pt-BR');

            const documentDefinition = {
                content: [
                    ...headerContent,
                    { text: ' ', margin: [0, 5, 0, 5] },
                    { text: "Lista de Presenças por Sessão", style: "header" },
                    { text: `${eventData.description} - Grau: ${eventData.degree}`, style: "subheader" },
                    { text: `Data: ${formattedDate}`, style: "subheader" },
                    { text: ' ', margin: [0, 5, 0, 5] },
                    { text: `Irmãos`, style: "subheader" },
                    { table },
                    { text: `Quantidade de Presentes: ${countPresent}`, style: "footer" },
                    { text: `Quantidade de Abonados: ${countExcuse}` },
                    { text: ' ', margin: [0, 10, 0, 10] },
                    { text: `Visitantes`, style: "subheader" },
                    { table: tableVisitors },
                    { text: `Quantidade de Visitantes: ${countVisitant}`, style: "footer" }
                ],
                styles: {
                    ...headerStyles,
                    header: {
                        fontSize: 18,
                        bold: true,
                        alignment: "center",
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 14,
                        bold: true,
                        alignment: "center",
                        margin: [0, 0, 0, 10],
                    },
                    footer: {
                        fontSize: 12,
                        alignment: "left",
                        margin: [0, 15, 0, 5],
                    },
                },
                footer: function (currentPage, pageCount) {
                    return {
                        text: currentPage.toString() + '/' + pageCount,
                        alignment: 'right',
                        margin: [0, 0, 40, 0]
                    };
                }
            };

            pdfMake.createPdf(documentDefinition).open();
        } catch (error) {
            messageError("Erro ao gerar o PDF de presenças por sessão.");
        }
    };

    const generatePdfAttendanceMemberByPeriod = async (startDate, endDate) => {
        if (!member) {
            setMemberError("Campo obrigatório");
            return;
        } else {
            setMemberError('');
        }
        setLoading(true);
        try {
            const { headerContent, headerStyles } = pdfHeader(dayTimeSessionDescription);

            const formattedStartDate = format(startDate, "yyyy-MM-dd");
            const formattedEndDate = format(endDate, "yyyy-MM-dd");

            // Obter dados do membro
            const responseMember = await memberService.getById(member);
            const memberData = responseMember.data;

            // Obter todas as presenças do membro no período
            const responseAttendances = await attendanceService.getAttendancesByMemberAndPeriod(member, formattedStartDate, formattedEndDate);
            const attendances = responseAttendances.data;
            // Criar um mapa de presenças para acesso rápido
            const attendanceMap = {};
            attendances.forEach(attendance => {
                attendanceMap[attendance.event] = attendance;
            });

            // Obter os eventos no período
            const responseEvents = await eventService.getEventsBetweenDates(formattedStartDate, formattedEndDate);
            const events = responseEvents.data;
            const relevantEvents = [];
            const initialDate = memberData.dtFiliation
                ? new Date(memberData.dtFiliation[0], memberData.dtFiliation[1] - 1, memberData.dtFiliation[2])
                : new Date(memberData.dtLodgeStart[0], memberData.dtLodgeStart[1] - 1, memberData.dtLodgeStart[2]);

            const dateGrade2 = memberData.dtElevation ? new Date(memberData.dtElevation[0], memberData.dtElevation[1] - 1, memberData.dtElevation[2]) : null;
            const dateGrade3 = memberData.dtExaltation ? new Date(memberData.dtExaltation[0], memberData.dtExaltation[1] - 1, memberData.dtExaltation[2]) : null;

            // Processar os eventos
            for (const event of events) {
                const eventDate = new Date(event.dtStart[0], event.dtStart[1] - 1, event.dtStart[2]);

                let isValid = eventDate >= initialDate;
                if (event.degree === 2 && dateGrade2) {
                    isValid = eventDate >= dateGrade2 && isValid;
                } else if (event.degree === 3 && dateGrade3) {
                    isValid = eventDate >= dateGrade3 && isValid;
                }

                if (isValid && memberData.lodgeDegree >= event.degree && memberData.status.id === 1) {
                    const attendance = attendanceMap[event.id];
                    const attendanceStatus = attendance
                        ? (attendance.excuse === 'S' ? 'Abonado' : 'Presente')
                        : '';
                    relevantEvents.push({
                        description: event.description,
                        degree: event.degree,
                        dtStart: event.dtStart,
                        status: attendanceStatus,
                        presence: event.type.presence,
                        type: event.type.id,
                        orderDay: event.orderDay,
                    });
                }
            }

            // Filtrar e ordenar os eventos relevantes
            const mandatoryEvents = relevantEvents.filter(a => a.presence === "S");
            relevantEvents.sort((a, b) => {
                const dateA = new Date(a.dtStart[0], a.dtStart[1] - 1, a.dtStart[2]);
                const dateB = new Date(b.dtStart[0], b.dtStart[1] - 1, b.dtStart[2]);
                return dateA - dateB;
            });

            // Cálculo da porcentagem de presença
            const presentEventsCount = mandatoryEvents.filter(event => event.status === 'Presente' || event.status === 'Abonado').length;
            const totalEventsCount = mandatoryEvents.length;
            const attendancePercentage = totalEventsCount > 0 ? ((presentEventsCount / totalEventsCount) * 100).toFixed(2) : 0;

            // Construção da tabela para o relatório
            const tableBody = relevantEvents.map((data, index) => [
                { text: index + 1, alignment: 'center' },
                { text: data.description + (data.type == 7 ? ' - ' + data.orderDay : '') + (data.presence === 'N' ? "*" : ""), alignment: 'left' },
                { text: (data.degree > 0) ? data.degree : '', alignment: 'center' },
                { text: new Date(data.dtStart[0], data.dtStart[1] - 1, data.dtStart[2]).toLocaleDateString('pt-BR'), alignment: 'center' },
                { text: data.status, alignment: 'center' }
            ]);

            const tableHeader = [
                { text: "Nº", bold: true, fillColor: '#eeeeee', alignment: 'center' },
                { text: "Descrição", bold: true, fillColor: '#eeeeee', alignment: 'left' },
                { text: "Grau", bold: true, fillColor: '#eeeeee', alignment: 'center' },
                { text: "Data", bold: true, fillColor: '#eeeeee', alignment: 'center' },
                { text: "Presença", bold: true, fillColor: '#eeeeee', alignment: 'center' }
            ];

            const table = {
                headerRows: 1,
                widths: ["auto", "*", "auto", "auto", "auto"],
                body: [tableHeader, ...tableBody]
            };

            // Definição do documento PDF
            const documentDefinition = {
                content: [
                    ...headerContent,
                    { text: "Relatório de Presenças Individual por Período", style: "header" },
                    { text: `${memberData.name}`, style: "header" },
                    { text: `Período: ${startDate.toLocaleDateString('pt-BR')} - ${endDate.toLocaleDateString('pt-BR')}`, style: "subheader" },
                    { table },
                    { text: `* Presença não obrigatória` },
                    {
                        text: `Frequência: ${presentEventsCount} / ${totalEventsCount} (${attendancePercentage}%)`,
                        style: "summary"
                    }
                ],
                styles: {
                    ...headerStyles,
                    header: {
                        fontSize: 18,
                        bold: true,
                        alignment: "center",
                        margin: [0, 0, 0, 10],
                    },
                    subheader: {
                        fontSize: 14,
                        alignment: "center",
                        margin: [0, 0, 0, 10],
                    },
                    summary: {
                        fontSize: 12,
                        alignment: "left",
                        margin: [0, 10, 0, 0],
                    }
                },
                footer: function (currentPage, pageCount) {
                    return {
                        text: currentPage.toString() + '/' + pageCount,
                        alignment: 'right',
                        margin: [0, 0, 40, 0]
                    };
                }
            };

            pdfMake.createPdf(documentDefinition).open();
        } catch (error) {
            messageError("Erro ao gerar o relatório de presenças por período");
        } finally {
            setLoading(false);
        }
    };

    const generatePdfAttendanceByPeriod = async (startDate, endDate, byPeriodPresenceType) => {
        setLoading(true);
        try {
            const formattedStartDate = format(startDate, "yyyy-MM-dd");
            const formattedEndDate = format(endDate, "yyyy-MM-dd");

            const response = await attendanceService.getAttendanceByPeriod(formattedStartDate, formattedEndDate, byPeriodPresenceType);
            let attendanceData = response.data;

            console.log("response.data:: ", response.data);
            // Ordenar os dados pelo nome do membro (memberName) em ordem alfabética
            attendanceData = attendanceData.sort((a, b) => a.memberName.localeCompare(b.memberName));

            const tableBody = attendanceData.map((data) => [
                { text: data.memberCim || "", fillColor: data.attendancePercentage < 50 ? '#FFECE7' : '' },
                { text: data.memberName + (data.remido ? " ®" : ""), fillColor: data.attendancePercentage < 50 ? '#FFECE7' : '' },
                { text: data.mandatoryEvents, alignment: "center", fillColor: data.attendancePercentage < 50 ? '#FFECE7' : '' },
                { text: data.presentEvents, alignment: "center", fillColor: data.attendancePercentage < 50 ? '#FFECE7' : '' },
                { text: `${data.attendancePercentage.toFixed(2)}%`, alignment: "center", fillColor: data.attendancePercentage < 50 ? '#FFECE7' : '' },
                { text: ``, alignment: "center", fillColor: data.attendancePercentage < 50 ? '#FFECE7' : '' }
            ]);

            const tableHeader = [
                { text: "CIM", bold: true, fillColor: '#eeeeee', margin: [0, 8, 0, 5] },
                { text: "Nome", bold: true, fillColor: '#eeeeee', margin: [0, 8, 0, 5] },
                { text: "Sessões\nObrigatórias", bold: true, fillColor: '#eeeeee', alignment: "center" },
                { text: "Sessões\nPresentes", bold: true, fillColor: '#eeeeee', alignment: "center" },
                { text: "Presença", bold: true, fillColor: '#eeeeee', alignment: "center", margin: [0, 8, 0, 5] },
                { text: "Quite com\nTesouraria", bold: true, fillColor: '#eeeeee', alignment: "center" }
            ];

            const table = {
                headerRows: 1,
                widths: ["auto", "*", "auto", "auto", "auto", "auto"],
                body: [
                    tableHeader,
                    ...tableBody
                ],
            };

            const documentDefinition = {
                content: [
                    {
                        text: "Relatório de Presenças por Período" + (byPeriodPresenceType === 0 ? " com Abono" : " em Loja"), style: "header"
                    },
                    { text: `Período: ${startDate.toLocaleDateString('pt-BR')} - ${endDate.toLocaleDateString('pt-BR')}`, style: "header" },
                    { table },
                    { text: "Legenda:", bold: true, margin: [0, 10, 0, 5] },
                    { text: "® Remido: Membro com isenção." },
                    {
                        columns: [
                            {
                                width: 'auto',
                                canvas: [
                                    {
                                        type: 'rect',
                                        x: 0,
                                        y: 0,
                                        w: 10,
                                        h: 10,
                                        color: '#FFECE7',
                                        lineWidth: 1,
                                        lineColor: '#000000'
                                    }
                                ]
                            },
                            { text: " Presença inferior a 50%", margin: [5, 0, 0, 0] }
                        ],
                        margin: [0, 5, 0, 0]
                    },
                    { text: "Assinatura do Chanceler: ", margin: [0, 20, 0, 0] },
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        alignment: "center",
                        margin: [0, 0, 0, 20],
                    },
                },
                footer: function (currentPage, pageCount) {
                    return {
                        text: currentPage.toString() + '/' + pageCount,
                        alignment: 'right',
                        margin: [0, 0, 40, 0]
                    };
                }
            };

            pdfMake.createPdf(documentDefinition).getBlob((blob) => {
                const url = URL.createObjectURL(blob);
                const newWindow = window.open(url);
                if (!newWindow) {
                    alert("Por favor, permita pop-ups para este site.");
                }
            });
        } catch (error) {
            messageError("Erro ao gerar o relatório de presenças por período");
        } finally {
            setLoading(false);
        }
    };




    const generatePdfBirthdayByPeriod = async (members, spouses, children, marriages, initiations, filiations, formattedStartDate, formattedEndDate) => {
        const calculateAge = (birthDate, referenceDate) => {
            let age = referenceDate.getFullYear() - birthDate.getFullYear();
            const m = referenceDate.getMonth() - birthDate.getMonth();
            const d = referenceDate.getDate() - birthDate.getDate();
            if (m < 0 || (m === 0 && d < 0)) {
                age++;
            }
            return age;
        };
        const { headerContent, headerStyles } = pdfHeader(dayTimeSessionDescription);

        const formatTableData = async (data, memberType, dateField) => {
            if (data.length === 0) {
                return [[{ text: "Nenhum aniversariante neste período", colSpan: 4, alignment: 'center' }]];
            }

            const formattedData = await Promise.all(data.map(async (person, index) => {
                const date = new Date(person[dateField]);
                const referenceDate = new Date();
                const nextAnniversary = new Date(referenceDate.getFullYear(), date.getMonth(), date.getDate());
                const formattedDate = date.toLocaleDateString('pt-BR');
                const age = calculateAge(date, nextAnniversary);
                let name = person.name;
                if (memberType === "spouse" || memberType === "child") {
                    const memberResponse = await memberService.getById(person.member);
                    name = `${person.name} - ${memberResponse.data.name}`;
                }
                if (dateField === "dtMarry") {
                    const memberResponse = await memberService.getById(person.member);
                    name = `${memberResponse.data.name} - ${person.name}`;
                }
                return {
                    index: index + 1,
                    name: name,
                    formattedDate: formattedDate,
                    age: age,
                    date: date
                };
            }));

            return formattedData.sort((a, b) => {
                if (a.date.getMonth() === b.date.getMonth()) {
                    return a.date.getDate() - b.date.getDate();
                }
                return a.date.getMonth() - b.date.getMonth();
            }).map((person, index) => [
                index + 1,
                person.name,
                person.formattedDate,
                { text: person.age, alignment: 'center' }
            ]);
        };

        const memberTableData = await formatTableData(members, "member", "dtBorn");
        const spouseTableData = await formatTableData(spouses, "spouse", "dtBorn");
        const childrenTableData = await formatTableData(children, "child", "dtBorn");
        const weddingAnniversaryData = await formatTableData(marriages, "spouse", "dtMarry");
        const initiationAnniversaryData = await formatTableData(initiations, "member", "dtLodgeStart");
        const filiationAnniversaryData = await formatTableData(filiations, "member", "dtFiliation");

        const tableHeader = [
            { text: "Nº", bold: true },
            { text: "Nome", bold: true },
            { text: "Data", bold: true, alignment: 'center' },
            { text: "Idade", bold: true, alignment: 'center' }
        ];

        const table = {
            headerRows: 1,
            widths: ["auto", "*", "auto", "auto"],
            body: [
                tableHeader,
                [{ text: "Aniversários dos Irmãos", colSpan: 4, bold: true, fillColor: '#eeeeee', alignment: 'center' }, {}, {}, {}],
                ...memberTableData,
                [{ text: "Aniversários das Cunhadas", colSpan: 4, bold: true, fillColor: '#eeeeee', alignment: 'center' }, {}, {}, {}],
                ...spouseTableData,
                [{ text: "Aniversários dos Sobrinhos", colSpan: 4, bold: true, fillColor: '#eeeeee', alignment: 'center' }, {}, {}, {}],
                ...childrenTableData,
                [{ text: "Aniversários de Casamento", colSpan: 4, bold: true, fillColor: '#eeeeee', alignment: 'center' }, {}, {}, {}],
                ...weddingAnniversaryData,
                [{ text: "Aniversários de Iniciação", colSpan: 4, bold: true, fillColor: '#eeeeee', alignment: 'center' }, {}, {}, {}],
                ...initiationAnniversaryData,
                [{ text: "Aniversários de Filiação / Regularização", colSpan: 4, bold: true, fillColor: '#eeeeee', alignment: 'center' }, {}, {}, {}],
                ...filiationAnniversaryData
            ],
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 0 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
            }
        };

        const documentDefinition = {
            content: [
                ...headerContent,

                { text: "Lista de Aniversariantes por Período", style: "header" },
                { text: `Período: ${formattedStartDate} - ${formattedEndDate}`, style: "subheader" },

                //Aniversários dos Irmãos
                {
                    table: {
                        headerRows: 1,
                        widths: ["auto", "*", "auto", "auto"],
                        body: [
                            [{ text: "Aniversários dos Irmãos", colSpan: 4, style: "title" }, {}, {}, {}],
                            ...memberTableData,
                        ],
                    },
                },
                { text: '', margin: [0, 20] },

                //Aniversários das Cunhadas
                {
                    table: {
                        headerRows: 1,
                        widths: ["auto", "*", "auto", "auto"],
                        body: [
                            [{ text: "Aniversários das Cunhadas", colSpan: 4, style: "title" }, {}, {}, {}],
                            ...spouseTableData,
                        ],
                    },
                },
                { text: '', margin: [0, 20] },

                //Aniversários dos Sobrinhos
                {
                    table: {
                        headerRows: 1,
                        widths: ["auto", "*", "auto", "auto"],
                        body: [
                            [{ text: "Aniversários dos Sobrinhos", colSpan: 4, style: "title" }, {}, {}, {}],
                            ...childrenTableData,
                        ],
                    },
                },
                { text: '', margin: [0, 20] },

                //Aniversários de Casamento
                {
                    table: {
                        headerRows: 1,
                        widths: ["auto", "*", "auto", "auto"],
                        body: [
                            [{ text: "Aniversários de Casamento", colSpan: 4, style: "title" }, {}, {}, {}],
                            ...weddingAnniversaryData,
                        ],
                    },
                },
                { text: '', margin: [0, 20] },

                //Aniversários de Iniciação
                {
                    table: {
                        headerRows: 1,
                        widths: ["auto", "*", "auto", "auto"],
                        body: [
                            [{ text: "Aniversários de Iniciação", colSpan: 4, style: "title" }, {}, {}, {}],
                            ...initiationAnniversaryData,
                        ],
                    },
                },
                { text: '', margin: [0, 20] },

                //Aniversários de Filiação / Regularização
                {
                    table: {
                        headerRows: 1,
                        widths: ["auto", "*", "auto", "auto"],
                        body: [
                            [{ text: "Aniversários de Filiação / Regularização", colSpan: 4, bold: true, fillColor: '#eeeeee', alignment: 'center' }, {}, {}, {}],
                            ...filiationAnniversaryData
                        ],
                    },
                },
            ],
            styles: {

                ...headerStyles,

                header: {
                    fontSize: 18,
                    bold: true,
                    alignment: "center",
                    margin: [0, 0, 0, 20],
                },
                subheader: {
                    fontSize: 14,
                    alignment: "center",
                    margin: [0, 0, 0, 10],
                },
                title: {
                    fontSize: 14,
                    bold: true,
                    fillColor: '#eeeeee',
                    alignment: 'center'
                }
            },
            footer: function (currentPage, pageCount) {
                return {
                    text: currentPage.toString() + '/' + pageCount,
                    alignment: 'right',
                    margin: [0, 0, 40, 0]
                };
            }
        };

        pdfMake.createPdf(documentDefinition).open();
    };

    const handleEventChange = async (event) => {
        setEvent(event.target.value);
        const responseEvent = await eventService.getById(event.target.value);
        setEventDescriptionDate(responseEvent.data.description + " - " + formatDate(responseEvent.data.dtStart));
    }

    const formatDate = (dateArray) => {
        const [year, month, day] = dateArray;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        return `${formattedDay} / ${formattedMonth} / ${year}`;
    };

    useEffect(() => {
        const fetchData = async () => {

            try {

                if (isAuthenticated) {

                    if (user) {
                        if (user.role !== 1) {
                            const responseMember = await memberService.getById(user.member);

                            const responseAllReports = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.GENERATE_PRESENCE_ALL);
                            setAllReports(responseAllReports.data);
                            if (!responseAllReports.data) {

                                const responsePresenceByMember = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.GENERATE_PRESENCE_BYMEMBER);
                                setPresenceByMember(responsePresenceByMember.data);

                                if (!responsePresenceByMember.data) {
                                    const responseOwnPresenceByMember = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.GENERATE_PRESENCE_OWN);
                                    setOwnPresenceByMember(responseOwnPresenceByMember.data);

                                } else {
                                    setOwnPresenceByMember(true);
                                }

                                const responsePresenceBySession = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.GENERATE_PRESENCE_BYSESSION);
                                setPresenceBySession(responsePresenceBySession.data);

                                const responsePresenceByPeriod = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.GENERATE_PRESENCE_BYPERIOD);
                                setPresenceByPeriod(responsePresenceByPeriod.data);

                                const responseBirthdayByPeriod = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.GENERATE_BIRTHDAY_BYPERIOD);
                                setBirthdayByPeriod(responseBirthdayByPeriod.data);

                                const responseBirthdaySpouses = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.GENERATE_BIRTHDAY_SPOUSES);
                                setBirthdaySpouses(responseBirthdaySpouses.data);

                            } else {
                                setPresenceByMember(true);
                                setOwnPresenceByMember(true);
                                setPresenceBySession(true);
                                setBirthdayByPeriod(true);
                                setBirthdaySpouses(true);
                            }

                        } else {
                            setAllReports(true);
                            setPresenceByMember(true);
                            setOwnPresenceByMember(true);
                            setPresenceBySession(true);
                            setBirthdayByPeriod(true);
                            setBirthdaySpouses(true);
                        }
                    } else {
                        setAllReports(false);
                        setPresenceByMember(false);
                        setOwnPresenceByMember(false);
                        setPresenceBySession(false);
                        setBirthdayByPeriod(false);
                        setBirthdaySpouses(false);
                        return;
                    }

                    if (user.member) {
                        const member = await memberService.getById(user.member);
                        setMember(member.data.id)
                        setSelectedMemberName(member.data.name);
                        setSelectedMemberDegree(member.data.lodgeDegree);
                    }

                    const responseDayTime = await prmtsService.getByType('DAYTIME-SESSION');
                    setDayTimeSessionDescription(responseDayTime.data ? responseDayTime.data.description : '');
                    const [response, response2] = await Promise.all([
                        memberService.getAll(),
                        eventService.getAll()
                    ]);

                    const sortedMembers = response.data.sort((a, b) => a.name.localeCompare(b.name));
                    setListMembers(sortedMembers);

                    const eventsList = response2.data;
                    const today = new Date();
                    const filteredEvents = eventsList.filter(event => {
                        const eventDate = new Date(event.dtStart[0], event.dtStart[1] - 1, event.dtStart[2], event.dtStart[3], event.dtStart[4]);
                        return eventDate <= today && event.degree != null && event.degree > 0;
                    });

                    filteredEvents.sort((a, b) => {
                        const dateA = new Date(a.dtStart[0], a.dtStart[1] - 1, a.dtStart[2], a.dtStart[3], a.dtStart[4]);
                        const dateB = new Date(b.dtStart[0], b.dtStart[1] - 1, b.dtStart[2], b.dtStart[3], b.dtStart[4]);
                        return dateB - dateA;
                    });

                    setListEvents(filteredEvents);

                } else {
                    navigate('/login');
                }

            } catch (error) {
                messageError("Erro ao buscar membros");
            }
        };
        fetchData();
    }, [memberService, eventService, userService, navigate]);

    return (
        <Container>
            {loading && (<Loading></Loading>)}
            <Card title="Relatórios">
                {(allReports || presenceBySession || presenceByPeriod || presenceByMember || birthdayByPeriod || birthdaySpouses || ownPresenceByMember) ? (
                    <>
                        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            {(allReports || birthdayByPeriod || birthdaySpouses) ? (
                                <div className="Aniversários" style={{ borderBottom: "15px solid #ddd" }}>
                                    <h4>Aniversários</h4>
                                    {(birthdayByPeriod || allReports) && (
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderBottom: "5px solid #ddd", paddingBottom: "20px", paddingTop: "10px" }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                <FormGroup label="Data de Início:" htmlFor="startDate">
                                                    <br />
                                                    <DatePicker
                                                        selected={startDateBirth}
                                                        onChange={date => setStartDateBirth(date)}
                                                        dateFormat="dd/MM"
                                                        locale="pt-BR"
                                                        className="form-control"
                                                    />
                                                </FormGroup>
                                                <FormGroup label="Data de Fim:" htmlFor="endDate">
                                                    <br />
                                                    <DatePicker
                                                        selected={endDateBirth}
                                                        onChange={date => setEndDateBirth(date)}
                                                        dateFormat="dd/MM"
                                                        locale="pt-BR"
                                                        className="form-control"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <button className="btn btn-info" onClick={handleBirthdayPeriod} style={{ marginLeft: "20px" }}>Aniversários por Período</button>
                                        </div>
                                    )}

                                    {(birthdaySpouses || allReports) && (
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderBottom: "5px solid #ddd", paddingBottom: "20px", paddingTop: "10px" }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                <FormGroup label="Data de Início:" htmlFor="startDate">
                                                    <br />
                                                    <DatePicker
                                                        selected={startDateBirthSpouses}
                                                        onChange={date => setStartDateBirthSpouses(date)}
                                                        dateFormat="dd/MM"
                                                        locale="pt-BR"
                                                        className="form-control"
                                                    />
                                                </FormGroup>
                                                <FormGroup label="Data de Fim:" htmlFor="endDate">
                                                    <br />
                                                    <DatePicker
                                                        selected={endDateBirthSpouses}
                                                        onChange={date => setEndDateBirthSpouses(date)}
                                                        dateFormat="dd/MM"
                                                        locale="pt-BR"
                                                        className="form-control"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <button className="btn btn-info" onClick={handleBirthdayPeriodSpouses} style={{ marginLeft: "20px" }}>Aniversários das Cunhadas</button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                null
                            )}
                            {(allReports || presenceBySession || presenceByPeriod || presenceByMember || ownPresenceByMember) ? (

                                <div className="Presença">
                                    <h4>Presenças</h4>
                                    {(presenceBySession || allReports) && (
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderBottom: "5px solid #ddd", paddingBottom: "10px" }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: '30px' }}>
                                                <FormGroup
                                                    label="Sessão:"
                                                    htmlFor="selectEvent"
                                                    error={eventError}
                                                >
                                                    <SelectMenu
                                                        className={`form-control ${eventError ? 'is-invalid' : ''}`} // Adicionar classe de erro
                                                        list={[{ label: "Selecione", value: "" }, ...listEvents.map(item => (
                                                            { label: `${formatDate(item.dtStart)} - ${item.description} - Grau: ${item.degree}`, value: item.id }
                                                        ))]}
                                                        value={event}
                                                        onChange={(e) => { setEvent(e.target.value); setEventError(''); }} // Limpar erro ao mudar
                                                    />
                                                    {eventError && (
                                                        <div className="invalid-feedback">
                                                            {eventError}
                                                        </div>
                                                    )}
                                                </FormGroup>

                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", gap: '30px', paddingLeft: '2vw' }}>
                                                <FormGroup
                                                    label="Tipo: *"
                                                    htmlFor="inputByEventType"
                                                >
                                                    <br />
                                                    <input
                                                        className="form-check-input 2"
                                                        type="radio"
                                                        name="optionsRadios1"
                                                        id="optionsRadios1"
                                                        value={byEventType}
                                                        checked={byEventType === 0}
                                                        onChange={(e) => setByEventType(0)}
                                                    />
                                                    <label
                                                        className="form-check-label 2"
                                                        htmlFor="optionsRadios1"
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        Frequência com abono
                                                    </label>
                                                    <br />
                                                    <input
                                                        className="form-check-input 2"
                                                        type="radio"
                                                        name="optionsRadios1"
                                                        id="optionsRadios2"
                                                        value={byEventType}
                                                        checked={byEventType === 1}
                                                        onChange={(e) => setByEventType(1)}
                                                    />
                                                    <label
                                                        className="form-check-label 2"
                                                        htmlFor="optionsRadios2"
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        Frequência em loja
                                                    </label>
                                                </FormGroup>
                                            </div>
                                            <button className="btn btn-info" onClick={handleAttendanceByEvent} style={{ marginLeft: "20px" }}>Presenças por Sessão</button>
                                        </div>
                                    )}

                                    {(presenceByPeriod || allReports) && (
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderBottom: " 5px solid #ddd", paddingBottom: "10px", paddingTop: "10px" }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: '10px' }}>
                                                <FormGroup label="Data de Início:" htmlFor="startDate">
                                                    <br />
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={date => setStartDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        locale="pt-BR"
                                                        className="form-control"
                                                    />
                                                </FormGroup>
                                                <FormGroup label="Data de Fim:" htmlFor="endDate">
                                                    <br />
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={date => setEndDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        locale="pt-BR"
                                                        className="form-control"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", gap: '30px', paddingLeft: '2vw' }}>
                                                <FormGroup
                                                    label="Tipo: *"
                                                    htmlFor="inputByPeriodPresenceTypee"
                                                >
                                                    <br />
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="optionsRadios2"
                                                        id="optionsRadios11"
                                                        value={byPeriodPresenceType}
                                                        checked={byPeriodPresenceType === 0}
                                                        onChange={(e) => setByPeriodPresenceType(0)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="optionsRadios11"
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        Frequência com abono
                                                    </label>
                                                    <br />
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="optionsRadios2"
                                                        id="optionsRadios22"
                                                        value={byPeriodPresenceType}
                                                        checked={byPeriodPresenceType === 1}
                                                        onChange={(e) => setByPeriodPresenceType(1)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="optionsRadios22"
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        Frequência em loja
                                                    </label>
                                                </FormGroup>
                                            </div>
                                            <button className="btn btn-info" onClick={() => generatePdfAttendanceByPeriod(startDate, endDate, byPeriodPresenceType)} style={{ marginLeft: "20px" }}>Presenças por Período</button>
                                        </div>
                                    )}



                                    <div>
                                        {(presenceByMember || ownPresenceByMember || allReports) && (
                                            <>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", borderBottom: "3px solid #ddd", paddingBottom: "10px" }}>

                                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                        <FormGroup label="Data de Início:" htmlFor="startDate">
                                                            <br />
                                                            <DatePicker
                                                                selected={startDateMemberAtt}
                                                                onChange={date => setStartDateMemberAtt(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                locale="pt-BR"
                                                                className="form-control"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup label="Data de Fim:" htmlFor="endDate">
                                                            <br />
                                                            <DatePicker
                                                                selected={endDateMemberAtt}
                                                                onChange={date => setEndDateMemberAtt(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                locale="pt-BR"
                                                                className="form-control"
                                                            />
                                                        </FormGroup>
                                                    </div>


                                                    {(presenceByMember || allReports) ? (
                                                        <div style={{ display: "flex", flexDirection: "column", gap: "10px", paddingLeft: '2vw' }}>
                                                            <FormGroup
                                                                label="Irmão:"
                                                                htmlFor="selectMember"
                                                                error={memberError}
                                                            >
                                                                <SelectMenu
                                                                    className={`form-control ${memberError ? 'is-invalid' : ''}`}
                                                                    list={[{ label: "Selecione", value: "" }, ...listMembers.map(member => ({ label: member.name, value: member.id }))]}
                                                                    value={member || ""}
                                                                    onChange={handleMemberChange}
                                                                />
                                                                {memberError && (
                                                                    <div className="invalid-feedback">
                                                                        {memberError}
                                                                    </div>
                                                                )}
                                                            </FormGroup>
                                                        </div>
                                                    ) : (
                                                        <div style={{ display: "flex", flexDirection: "column", gap: "10px", paddingLeft: '2vw' }}>
                                                            <FormGroup label="Irmão:" htmlFor="selectMember">
                                                                <SelectMenu
                                                                    className="form-control"
                                                                    list={[{ label: "Selecione", value: "" }, ...listMembers.map(member => ({ label: member.name, value: member.id }))]}
                                                                    value={member}
                                                                    onChange={handleMemberChange}
                                                                    disabled={true}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    )}
                                                    <button className="btn btn-info" onClick={() => generatePdfAttendanceMemberByPeriod(startDateMemberAtt, endDateMemberAtt)} style={{ marginLeft: "20px" }}>Presenças por Irmão</button>

                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                            ) : (
                                null
                            )}

                        </div>
                    </>) : (
                    <h4>Você não tem permissão para acessar nenhum relatório</h4>
                )}

            </Card>
        </Container >
    );
};

export default Report;


