// Função para formatar CPF
export const formatCPF = (cpf) => {
    return cpf
        .replace(/\D/g, '') // Remove todos os caracteres não numéricos
        .replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto depois do terceiro dígito
        .replace(/(\d{3})(\d)/, '$1.$2') // Coloca um ponto depois dos próximos três dígitos
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um traço antes dos dois últimos dígitos
};

// Função para validar CPF
export const validateCPF = (inputCpf) => {

    const cpf = inputCpf.replace(/[\.\-]/g, '');
    // Verificar se o CPF possui 11 dígitos
    if (cpf.length !== 11) {
        return false;
    }

    // Verificar se todos os dígitos são iguais
    if (/^(\d)\1+$/.test(cpf)) {
        return false;
    }

    // Calcular o primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let digito1 = 11 - (soma % 11);
    if (digito1 > 9) {
        digito1 = 0;
    }

    // Verificar o primeiro dígito verificador
    if (parseInt(cpf.charAt(9)) !== digito1) {
        return false;
    }

    // Calcular o segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    let digito2 = 11 - (soma % 11);
    if (digito2 > 9) {
        digito2 = 0;
    }

    // Verificar o segundo dígito verificador
    if (parseInt(cpf.charAt(10)) !== digito2) {
        return false;
    }

    // CPF válido
    return true;

}