import React from "react";

function FormRow(props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {props.children}
        </div>
    )
}

export default FormRow;