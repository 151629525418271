import React, { useState, useEffect } from 'react';
import { Table, Form } from 'react-bootstrap';

const monthOptions = [
    { label: 'Janeiro', value: 1 },
    { label: 'Fevereiro', value: 2 },
    { label: 'Março', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Maio', value: 5 },
    { label: 'Junho', value: 6 },
    { label: 'Julho', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Setembro', value: 9 },
    { label: 'Outubro', value: 10 },
    { label: 'Novembro', value: 11 },
    { label: 'Dezembro', value: 12 }
];

const formatCurrency = (value) => {
    if (value === '' || value === null || value === undefined) return '';
    const number = typeof value === 'string'
        ? parseFloat(value.replace(/[R$\s.]/g, '').replace(',', '.'))
        : value;
    if (isNaN(number)) return '';
    return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};

const parseCurrency = (value) => {
    if (value === '' || value === null || value === undefined) return '';
    const number = typeof value === 'string'
        ? parseFloat(value.replace(/[R$\s.]/g, '').replace(',', '.'))
        : value;
    if (isNaN(number)) return '';
    return number;
};

const Taxes = ({ taxes, editedTaxes, onHandleChange }) => {
    const [focusedField, setFocusedField] = useState(null);
    const [originalValue, setOriginalValue] = useState({});
    const [parcelValues, setParcelValues] = useState({}); // Estado para "Valor das Parcelas"

    // Inicializar "Valor das Parcelas" com base nos valores iniciais
    useEffect(() => {
        const initialParcelValues = {};
        taxes.forEach(tax => {
            const value = parseCurrency(tax.value);
            const installments = parseInt(tax.installments, 10) || 1;
            initialParcelValues[tax.id] = installments > 0 ? formatCurrency(value / installments) : '';
        });
        setParcelValues(initialParcelValues);
    }, [taxes]);

    const getFieldValue = (tax, field) => {
        if (editedTaxes[tax.id] && editedTaxes[tax.id][field] !== undefined) {
            return editedTaxes[tax.id][field];
        }
        return tax[field];
    };

    const handleValueFocus = (tax) => {
        setFocusedField(tax.id);
        const currentValue = getFieldValue(tax, 'value');
        // Guardar o valor original formatado
        const formattedCurrentValue = formatCurrency(currentValue);
        setOriginalValue(prev => ({ ...prev, [tax.id]: formattedCurrentValue }));

        // Ao focar, remover formatação e mostrar valor bruto
        let raw = '';
        if (typeof currentValue === 'number') {
            raw = currentValue.toString().replace('.', ',');
        } else {
            // Remove formatações 'R$', etc., caso existam
            raw = (currentValue || '').replace(/[R$\s]/g, '');
        }
        onHandleChange(tax.id, 'value', raw);
    };

    const handleValueBlur = (tax) => {
        setFocusedField(null);
        const currentValue = getFieldValue(tax, 'value');

        // Se está vazio, restaurar o valor original
        if (currentValue === '' || currentValue === null || currentValue === undefined) {
            const originalFormattedValue = originalValue[tax.id] || '';
            onHandleChange(tax.id, 'value', originalFormattedValue);
            return;
        }

        // Formatar o valor ao perder o foco
        const formatted = formatCurrency(currentValue);
        onHandleChange(tax.id, 'value', formatted);

        // Atualizar "Valor das Parcelas" com base no novo valor
        const valueNumber = parseCurrency(currentValue);
        const installments = parseInt(getFieldValue(tax, 'installments'), 10) || 1;
        const newParcelValue = installments > 0 ? formatCurrency(valueNumber / installments) : '';
        setParcelValues(prev => ({ ...prev, [tax.id]: newParcelValue }));
    };

    const handleValueChange = (tax, e) => {
        let rawValue = e.target.value;
        onHandleChange(tax.id, 'value', rawValue);

        // Atualizar "Valor das Parcelas" com base no novo valor
        const valueNumber = parseCurrency(rawValue);
        const installments = parseInt(getFieldValue(tax, 'installments'), 10) || 1;
        if (installments > 0 && !isNaN(valueNumber)) {
            const newParcelValue = formatCurrency(valueNumber / installments);
            setParcelValues(prev => ({ ...prev, [tax.id]: newParcelValue }));
        } else {
            setParcelValues(prev => ({ ...prev, [tax.id]: '' }));
        }
    };

    const handleInstallmentsChange = (tax, e) => {
        const newInstallments = parseInt(e.target.value, 10) || 1;
        onHandleChange(tax.id, 'installments', newInstallments);

        // Atualizar "Valor das Parcelas" com base no novo número de parcelas
        const value = parseCurrency(getFieldValue(tax, 'value'));
        if (newInstallments > 0 && !isNaN(value)) {
            const newParcelValue = formatCurrency(value / newInstallments);
            setParcelValues(prev => ({ ...prev, [tax.id]: newParcelValue }));
        } else {
            setParcelValues(prev => ({ ...prev, [tax.id]: '' }));
        }
    };

    const handleParcelValueChange = (tax, e) => {
        let rawParcelValue = e.target.value;
        // Atualizar o estado local de "Valor das Parcelas"
        setParcelValues(prev => ({ ...prev, [tax.id]: rawParcelValue }));

        // Calcular o novo "Valor" com base nas parcelas
        const parcelNumber = parseCurrency(rawParcelValue);
        const installments = parseInt(getFieldValue(tax, 'installments'), 10) || 1;
        if (!isNaN(parcelNumber) && installments > 0) {
            const newValue = formatCurrency(parcelNumber * installments);
            onHandleChange(tax.id, 'value', newValue);
        } else {
            onHandleChange(tax.id, 'value', '');
        }
    };

    const taxGroups = [...new Set(taxes.map(t => t.taxGroup))];

    const renderGroupTable = (group) => {
        const groupTaxes = taxes.filter(t => t.taxGroup === group);

        return (
            <div key={group} style={{ marginBottom: '2rem' }}>
                <h5>{group}</h5>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th>Valor Total</th>
                            <th>Valor das Parcelas</th> {/* Nova coluna adicionada */}
                            <th>Parcelas</th>
                            <th>Dia do Vencimento</th>
                            <th>Mês de Início da Cobrança</th>

                            <th style={{ textAlign: 'center' }}>Ativo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupTaxes.map(tax => {
                            const valueField = getFieldValue(tax, 'value');
                            const displayValue = (focusedField === tax.id)
                                ? valueField
                                : (formatCurrency(valueField) || '');

                            const displayInstallmentValue = parcelValues[tax.id] || '';

                            return (
                                <tr key={tax.id}>
                                    <td>
                                        <Form.Control
                                            as="textarea"
                                            rows={1}
                                            style={{ resize: 'vertical' }}
                                            value={getFieldValue(tax, 'description') || ''}
                                            onChange={(e) => onHandleChange(tax.id, 'description', e.target.value)}
                                            size="sm"
                                        />
                                    </td>
                                    <td style={{ width: '10vw' }}>
                                        <Form.Control
                                            type="text"
                                            value={displayValue}
                                            onFocus={() => handleValueFocus(tax)}
                                            onBlur={() => handleValueBlur(tax)}
                                            onChange={(e) => handleValueChange(tax, e)}
                                            size="sm"
                                            style={{ width: '100%' }}
                                        />
                                    </td>
                                    <td style={{ width: '12vw' }}> {/* Define largura adequada */}
                                        <Form.Control
                                            type="text"
                                            value={displayInstallmentValue}
                                            onChange={(e) => handleParcelValueChange(tax, e)}
                                            size="sm"
                                            style={{ width: '100%' }}
                                        />
                                    </td>
                                    <td style={{ width: '80px' }}>
                                        <Form.Control
                                            type="number"
                                            value={getFieldValue(tax, 'installments') || ''}
                                            onChange={(e) => handleInstallmentsChange(tax, e)}
                                            size="sm"
                                            style={{ width: '100%' }}
                                        />
                                    </td>
                                    <td>
                                        <Form.Select
                                            value={getFieldValue(tax, 'dayExpire') || ''}
                                            onChange={(e) => onHandleChange(tax.id, 'dayExpire', e.target.value)}
                                            size="sm"
                                        >
                                            <option value="" disabled>Selecione um dia</option>
                                            {Array.from({ length: 28 }, (_, i) => i + 1).map((day) => (
                                                <option key={day} value={day}>
                                                    {day}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </td>
                                    <td>
                                        <Form.Select
                                            value={getFieldValue(tax, 'startMonth') || ''}
                                            onChange={(e) => onHandleChange(tax.id, 'startMonth', parseInt(e.target.value, 10))}
                                            size="sm"
                                        >
                                            <option value="">Selecione um mês</option>
                                            {monthOptions.map(m => (
                                                <option key={m.value} value={m.value}>{m.label}</option>
                                            ))}
                                        </Form.Select>
                                    </td>

                                    <td className="text-center">
                                        <Form.Check
                                            type="checkbox"
                                            checked={
                                                getFieldValue(tax, 'active') === 'S' ||
                                                getFieldValue(tax, 'active') === true
                                            }
                                            onChange={(e) => onHandleChange(tax.id, 'active', e.target.checked)}
                                            style={{ transform: 'scale(1.2)' }}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    };

    return (
        <>
            {taxGroups.map(group => renderGroupTable(group))}
        </>
    );
};

export default Taxes;