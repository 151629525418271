import ApiService from '../apiservice';

class VisitorService extends ApiService {

    constructor() {
        super('/visitor');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getAllByEvent(eventId) {
        return this.get(`${this.apiurl}/allByEvent/${eventId}`);
    }

    new(visitor) {
        return this.post(`${this.apiurl}/new`, visitor);
    }

    update(visitor) {
        return this.put(`${this.apiurl}/${visitor.id}`, visitor);
    }

    getByCim(cim) {
        return this.get(`${this.apiurl}/cim/${cim}`);
    }

    getById(id) {
        return this.get(`${this.apiurl}/id/${id}`);
    }

    deleteVisitor(id) {
        return this.delete(`${this.apiurl}/${id}`);
    }

    validateCpf(cpf) {
        return this.get(`${this.apiurl}/cpf/${cpf}`);
    }

    validateCim(cim) {
        return this.get(`${this.apiurl}/validate-cim/${cim}`);
    }

    findVisitors(visitorFilter) {
        if (visitorFilter.name ||
            visitorFilter.cim) {
            let params = `?`;
            let count = 0
            if (visitorFilter.name) {
                params += `name=${visitorFilter.name}`;
                count++;
            }

            if (visitorFilter.cim) {
                if (count > 0)
                    params += `&`
                params += `cim=${visitorFilter.cim}`
                count++;
            }

            return this.get(`${this.apiurl}${params}`)
        }
        return this.get(`${this.apiurl}/all`);
    };
}
export default VisitorService;