import ApiService from '../apiservice';

class LodgePartyService extends ApiService {

    constructor() {
        super('/lodgeparty');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getPartyById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }

    getByDescription(description) {
        return this.get(`${this.apiurl}/description/${description}`);
    }
}

export default LodgePartyService;