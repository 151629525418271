// VisitorAttendanceDT.js

import React from 'react';
import { Button } from 'primereact/button';

const VisitorAttendanceDT = ({ visitors, onSelectVisitor, selectedVisitors, onPrintCertificate, onEditVisitor, canEdit, canPrint }) => {
    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col" style={{ width: '10%', textAlign: 'center' }}>Presença</th>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Nome</th>
                    <th scope="col" style={{ width: '10%', textAlign: 'center' }}>CIM</th>
                    <th scope="col" style={{ width: '10%', textAlign: 'center' }}>Grau</th>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Loja</th>
                    <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Oriente</th>
                    {canPrint && (
                        <th scope="col" style={{ width: '15%', textAlign: 'center' }}>Certificado</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {visitors.map((visitor) => (
                    <tr key={visitor.id}>
                        <td style={{ textAlign: 'center' }}>
                            <input
                                type="checkbox"
                                style={{ transform: 'scale(1.6)' }}
                                checked={selectedVisitors.includes(visitor.id)}
                                onChange={() => onSelectVisitor(visitor.id)}
                                disabled={!canEdit}
                            />
                        </td>
                        <td style={{ textAlign: 'center' }}>{visitor.name}</td>
                        <td style={{ textAlign: 'center' }}>{visitor.cim}</td>
                        <td style={{ textAlign: 'center' }}>{visitor.lodgeDegree}</td>
                        <td style={{ textAlign: 'center' }}>{visitor.lodge}</td>
                        <td style={{ textAlign: 'center' }}>{visitor.lodgeState}</td>
                        {canPrint && (
                            <td style={{ textAlign: 'center' }}>
                                <Button
                                    icon="pi pi-print"
                                    rounded outlined severity="success"
                                    aria-label="Edit"
                                    className="small-button btn btn-primary"
                                    style={{ fontSize: '12px', padding: '3px 5px' }}
                                    onClick={() => onPrintCertificate(visitor.id)}
                                    visible={canPrint}
                                    disabled={!canPrint}
                                />
                                <Button
                                    icon="pi pi-pencil"
                                    rounded outlined severity="success"
                                    aria-label="Search"
                                    className="small-button btn btn-info"
                                    style={{ fontSize: '12px', padding: '3px 5px' }}
                                    onClick={e => onEditVisitor(visitor.id)}
                                />
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default VisitorAttendanceDT;
