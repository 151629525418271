import ApiService from '../apiservice';

class AppFunctionsService extends ApiService {

    constructor() {
        super('/app-functions');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }

    getByDescription(description) {
        return this.get(`${this.apiurl}/description/${description}`);
    }

    new(appFunction) {
        return this.post(`${this.apiurl}/new`, appFunction);
    }

    deleteFunction(id) {
        return this.delete(`${this.apiurl}/${id}`);
    }

    saveFunction(appFunction) {
        return this.post(this.apiurl, appFunction);
    }

    updateFunction(appFunction) {
        return this.put(`${this.apiurl}/update`, appFunction);
    }

}

export default AppFunctionsService;