import React from "react";

function FormField(props) {
    return (
        <div style={{ flex: 1, marginRight: '10px' }}>
            {props.children}
        </div>
    )
}

export default FormField;