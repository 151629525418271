import ApiService from '../apiservice';

class PRMTSService extends ApiService {

    constructor() {
        super('/prmts');
    }

    getByType(type) {
        return this.get(`${this.apiurl}/${type}`);
    }
    new(prmts) {
        return this.post(`${this.apiurl}/new`, prmts);
    }
    update(prmts) {
        return this.put(`${this.apiurl}/${prmts.type}`, prmts);
    }
}

export default PRMTSService;