import React from 'react';
import 'bootswatch/dist/flatly/bootstrap.css';
import '../styles/custom.css';
import AppRoutes from '../routes/app-routes';
import Container from '../components/container';
import NavBar from '../components/navbar';
import 'toastr/build/toastr.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css"

class App extends React.Component {

  render() {
    return (
      <>
        <NavBar />
        <Container>
          <AppRoutes />
        </Container>
      </>
    )
  }
}

export default App;
