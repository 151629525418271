//user-list.js

import React, { useState, useEffect, useMemo, useContext } from "react";
import Card from "../../components/card";
import FormGroup from "../../components/form-group";
import Container from "../../components/container";
import SelectMenu from "../../components/selectMenu";
import UserDT from "./userDT";
import UserService from "../../app/service/userService";
import RoleService from "../../app/service/roleService";
import StatusService from "../../app/service/statusService";
import MemberService from "../../app/service/memberService";
import { messageError, messageSuccess } from "../../components/toastr";
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import Loading from "../../components/loading";
import { AuthContext } from '../../security/AuthContext';

const UserList = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchResults, setSearchResults] = useState([]);
    const userLogged = JSON.parse(localStorage.getItem("user_logged"));
    const userService = useMemo(() => new UserService(), []);
    const roleService = useMemo(() => new RoleService(), []);
    const statusService = useMemo(() => new StatusService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [listRole, setListRole] = useState([]);
    const [listStatus, setListStatus] = useState([]);

    const [member, setMember] = useState('');
    const [memberList, setMemberList] = useState([]);
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [status, setStatus] = useState('');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleMemberChange = (event) => {
        setMember(event.target.value);
    };

    // eslint-disable-next-line
    const handleSearch = async () => {
        const userFilter = {
            email: email,
            role: role,
            status: status,
            member: member
        };

        try {
            const response = await userService.findReport(userFilter);
            if (Array.isArray(response.data) && response.data.length > 0) {

                const sortedUsers = response.data.sort((a, b) => a.email.localeCompare(b.email));
                setSearchResults(sortedUsers);
            } else {
                setSearchResults([]);
                messageError("Nenhum resultado encontrado.");
            }
        } catch (error) {
            messageError(`${error.response.data}`);
        }
    };

    const handleClear = () => {
        setEmail('');
        setRole('');
        setStatus('');
        setMember('')
    };

    const editUser = async (id) => {
        try {
            const response = await userService.getById(id);
            if (response && response.data.id) {
                const user = response.data.id;
                localStorage.setItem('user-edit', JSON.stringify(user));
                navigate('/user-register'); // Assuming this is the route for editing a user
                return;
            } else {
                messageError("Usuário não encontrado.");
            }
        } catch (error) {
            messageError("Erro ao carregar os dados do usuário.");
        }
    }

    const confirmDelete = (id) => {
        setSelectedUserId(id);
        setVisible(true);
    };

    const handleConfirmation = (confirmed) => {
        setVisible(false);

        if (confirmed) {
            deleteUser(selectedUserId);
        } else {
            setSelectedUserId(null);
        }
    };
    const handleAddNewUser = () => {
        navigate('/user-register');
        return;
    }

    const deleteUser = (id) => {
        userService
            .deleteUser(id)
            .then((response) => {
                messageSuccess("Usuário deletado com sucesso.");
                handleClear();
                handleSearch();
            })
            .catch((error) => {
                messageError("Não foi possível deletar este usuário.");
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            localStorage.removeItem('user-edit');
            try {
                // Verificar se o usuário está logado
                if (isAuthenticated) {
                    const accessLevel = await userService.hasAccess(user.id);
                    if (user.role != 1 && accessLevel.data != 1) {
                        editUser(user.id)
                    }
                } else {
                    navigate('/login');
                }

                const responseRole = await roleService.getAll();
                if (responseRole && responseRole.data) {
                    // Mapeamento dos dados
                    let mappedRoles = responseRole.data.map(role => ({ label: role.description, value: role.id }));
                    // Adicionando o item "Selecione" no início da lista
                    mappedRoles = [{ label: "Selecione", value: 0 }, ...mappedRoles];
                    setListRole(mappedRoles);
                }

                const responseStatus = await statusService.getAll();
                if (responseStatus && responseStatus.data) {
                    // Mapeamento dos dados
                    let mappedStatus = responseStatus.data.map(status => ({ label: status.description, value: status.id }));
                    // Adicionando o item "Selecione" no início da lista
                    mappedStatus = [{ label: "Selecione", value: 0 }, ...mappedStatus];
                    setListStatus(mappedStatus);
                }

                //Lista todos os membros
                const response = await memberService.getAll();
                const selectOption = { label: 'Selecione', value: '' };
                const listMembers = [
                    selectOption,
                    ...response.data.map(item => ({
                        label: item.name,
                        value: item.id
                    })).sort((a, b) => a.label.localeCompare(b.label))
                ];
                setMemberList(listMembers);

                await handleSearch();
                setLoading(false);
            } catch (error) {
                messageError("Erro ao carregar os usuários.");
            }
        };

        fetchData();
    }, []);

    return (

        <Container>
            <Card title="Consultar">
                {loading ? (
                    <Loading loading={loading} />
                ) : (
                    <>
                        <div className="row">
                            <div className="col-lg-3">
                                <FormGroup label="Email: " htmlFor="inputEmail">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="exampleInputName1"
                                        name="email"
                                        value={email}
                                        placeholder="Digite o Email"
                                        onChange={handleEmailChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-lg-3">
                                <FormGroup label="Cargo: *" htmlFor="selectRole">
                                    <br />
                                    <SelectMenu
                                        className="form-control"
                                        list={listRole}
                                        value={role}
                                        onChange={handleRoleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-lg-3">
                                <FormGroup label="Situação: *" htmlFor="selectStatus">
                                    <br />
                                    <SelectMenu
                                        className="form-control"
                                        list={listStatus}
                                        value={status}
                                        onChange={handleStatusChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-lg-3">
                                <FormGroup label="Irmão: " htmlFor="selectMember">
                                    <SelectMenu
                                        className="form-control"
                                        list={memberList}
                                        value={member}
                                        onChange={handleMemberChange}
                                    />
                                </FormGroup>
                            </div>
                        </div>

                        <br />
                        <button className="btn btn-success" onClick={handleSearch}>Buscar</button>
                        {' '}
                        <button className="btn btn-danger" onClick={handleClear}>Limpar</button>
                        {' '}
                        <button className="btn btn-info" onClick={handleAddNewUser}>Cadastrar novo usuário</button>
                        <br /><br />
                        <div className="row">
                            <div className="col-lg-12">
                                <UserDT
                                    users={searchResults}
                                    deleteUser={confirmDelete}
                                    editUser={editUser}
                                    listRole={listRole}
                                    listStatus={listStatus}
                                    memberList={memberList}
                                />

                            </div>
                        </div>

                        <Dialog
                            header="Confirmação"
                            visible={visible}
                            style={{ width: '25vw' }}
                            onHide={() => handleConfirmation(false)}
                            modal={true}
                        >
                            <p className="m-0">Deseja excluir o usuário?</p>
                            <br />
                            <div className="p-d-flex p-jc-end">
                                <button
                                    className="btn btn-danger p-button-text"
                                    onClick={() => handleConfirmation(true)}
                                >
                                    Deletar
                                </button>
                                {' '}
                                <button
                                    className="btn btn-secondary p-button-text"
                                    onClick={() => handleConfirmation(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </Dialog>
                    </>
                )}
            </Card>
        </Container >
    );
}

export default UserList;
