//RolePermissionService.js:

import ApiService from '../apiservice';

class RolePermissionService extends ApiService {

    constructor() {
        super('/role-permission');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getPermissionsByRole(roleId) {
        return this.get(`${this.apiurl}/permissionsByRole?roleId=${roleId}`);
    }

    getById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }

    hasPermission(roleId, functionId) {
        return this.get(`${this.apiurl}/permission?roleId=${roleId}&functionId=${functionId}`);
    }

    hasAccess(roleId, endpoint) {
        return this.get(`${this.apiurl}/access?roleId=${roleId}&endpoint=${endpoint}`);
    }


    changePermission(permission) {
        return this.put(`${this.apiurl}/changePermission`, permission);
    }

    updateFunction(appFunction) {
        return this.put(`${this.apiurl}/update`, appFunction);
    }

}

export default RolePermissionService;