import React, { useState, useEffect, useMemo } from "react";
import { Table, Button } from 'react-bootstrap';
import FormGroup from "../../components/form-group";
import SelectMenu from "../../components/selectMenu";
import { messageSuccess, messageError } from "../../components/toastr";
import TaxesPaymentsService from "../../app/service/taxesPaymentsService";
import DatePicker from 'react-datepicker'; // Biblioteca para seleção de data
import 'react-datepicker/dist/react-datepicker.css'; // Estilos do DatePicker
import { Button as PrimeButton } from 'primereact/button'; // Importando o botão do PrimeReact

const monthOptions = [
    { label: 'Janeiro', value: 1 },
    { label: 'Fevereiro', value: 2 },
    { label: 'Março', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Maio', value: 5 },
    { label: 'Junho', value: 6 },
    { label: 'Julho', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Setembro', value: 9 },
    { label: 'Outubro', value: 10 },
    { label: 'Novembro', value: 11 },
    { label: 'Dezembro', value: 12 }
];

const formatCurrency = (value) => {
    if (value === '' || value === null || value === undefined) return '';
    const number = typeof value === 'string'
        ? parseFloat(value.replace(/[R$\s.]/g, '').replace(',', '.'))
        : value;
    if (isNaN(number)) return '';
    return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
};

const PaymentRegister = ({ taxes, members }) => {
    const [selectedMember, setSelectedMember] = useState('');
    const [selectedTax, setSelectedTax] = useState('');
    const [addedTaxes, setAddedTaxes] = useState([]);
    const taxesPaymentsService = useMemo(() => new TaxesPaymentsService(), []);

    const handleMemberChange = (event) => {
        setSelectedMember(event.target.value);
    };

    const handleTaxChange = (event) => {
        setSelectedTax(event.target.value);
    };

    const handleAddTax = () => {
        if (selectedTax) {
            const taxId = parseInt(selectedTax, 10);
            const taxObj = taxes.find(t => t.id === taxId);

            if (taxObj && !addedTaxes.find(t => t.id === taxObj.id)) {
                setAddedTaxes(prev => [...prev, { ...taxObj, dtPayment: null }]);
            }
        }
    };

    const handleRemoveTax = (id) => {
        setAddedTaxes(prev => prev.filter(t => t.id !== id));
    };

    const handleDateChange = (date, id) => {
        setAddedTaxes(prev => prev.map(tax =>
            tax.id === id ? { ...tax, dtPayment: date } : tax
        ));
    };

    const handleConfirm = () => {
        if (selectedMember === '') {
            messageError('Selecione um membro.');
            return;
        }

        if (addedTaxes.length === 0) {
            messageError('Nenhuma taxa adicionada.');
            return;
        }

        // Preparar o payload com dtPayment formatada ou null
        const payment = {
            taxes: addedTaxes.map(tax => ({
                id: tax.id,
                dtPayment: tax.dtPayment ? tax.dtPayment.toISOString().split('T')[0] : null // Formato YYYY-MM-DD ou null
            })),
            member: selectedMember
        };


        console.log("selectedMember:: ", selectedMember);
        const request = (selectedMember === '0')
            ? taxesPaymentsService.includeForAll(payment)
            : taxesPaymentsService.includeForOne(payment);

        request
            .then(response => {
                // Exibir mensagem de sucesso
                messageSuccess('Pagamentos incluídos com sucesso!');

                // Limpar o estado após inclusão
                setSelectedMember('');
                setAddedTaxes([]);
                setSelectedTax('');
            })
            .catch(error => {
                // Tratar erro, exibir mensagem de erro
                const errorMessage = error.response && error.response.data
                    ? error.response.data
                    : 'Ocorreu um erro ao incluir pagamentos.';

                messageError(errorMessage);
                console.error("Erro ao incluir pagamentos:", error);
            });
    };

    useEffect(() => {
        // Caso queira carregar dados adicionais ou tratar algo ao alterar `taxes` ou `members`
    }, [taxes, members]);

    return (
        <div style={{ marginTop: '2rem', marginLeft: '3%', marginRight: '3%' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end', gap: '20px', marginBottom: '20px' }}>
                <FormGroup label="Irmão:" htmlFor="selectMember">
                    <SelectMenu
                        style={{ width: "15vw", height: "5vh" }}
                        className="form-control"
                        list={[
                            { label: "Selecione", value: "" },
                            { label: "Todos", value: 0 },
                            ...members.map(member => ({ label: member.name, value: member.id }))
                        ]}
                        value={selectedMember}
                        onChange={handleMemberChange}
                    />
                </FormGroup>

                <FormGroup label="Taxa:" htmlFor="selectTax">
                    <SelectMenu
                        style={{ width: "20vw", height: "5vh" }}
                        className="form-control"
                        list={[
                            { label: "Selecione", value: "" },
                            ...taxes.map(tax => ({ label: tax.taxGroup + ' - ' + tax.description, value: tax.id }))
                        ]}
                        value={selectedTax}
                        onChange={handleTaxChange}
                    />
                </FormGroup>

                <div>
                    <Button variant="primary" onClick={handleAddTax}>Adicionar</Button>
                </div>
            </div>

            {addedTaxes.length > 0 && (
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Grupo</th>
                            <th>Descrição</th>
                            <th>Valor</th>
                            <th>Dia do Vencimento</th>
                            <th>Mês de Início da Cobrança</th>
                            <th>Parcelas</th>
                            <th>Data de Pagamento</th> {/* Nova Coluna */}
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addedTaxes.map(tax => (
                            <tr key={tax.id}>
                                <td>{tax.taxGroup}</td>
                                <td>{tax.description}</td>
                                <td>{formatCurrency(tax.value)}</td>
                                <td>{tax.dayExpire}</td>
                                <td>{monthOptions.find(m => m.value === tax.startMonth)?.label || ''}</td>
                                <td>{tax.intallments}</td>
                                <td>
                                    <DatePicker
                                        selected={tax.dtPayment}
                                        onChange={(date) => handleDateChange(date, tax.id)}
                                        dateFormat="yyyy-MM-dd"
                                        placeholderText="Selecione a data"
                                        isClearable
                                        className="form-control"
                                    />
                                </td>
                                <td>
                                    <PrimeButton
                                        icon="pi pi-times"
                                        rounded outlined severity="danger"
                                        aria-label="Cancel"
                                        className="small-button btn btn-danger"
                                        style={{ fontSize: '12px', padding: '3px 5px' }}
                                        onClick={e => handleRemoveTax(tax.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            {addedTaxes.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <Button variant="success" onClick={handleConfirm}>
                        Confirmar Inclusão
                    </Button>
                </div>
            )}
        </div>
    );

};

export default PaymentRegister;