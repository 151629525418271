// Unauthorized.js
import React from 'react';

const Unauthorized = () => {
    return (
        <div>
            <h1>Acesso Negado</h1>
            <p>Você não tem permissão para acessar esta página.</p>
        </div>
    );
};

export default Unauthorized;
