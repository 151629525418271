import ApiService from '../apiservice';

class ChildrenService extends ApiService {

    constructor() {
        super('/children');
    }

    new(child) {
        return this.post(`${this.apiurl}/new`, child);
    }

    update(child) {
        return this.put(`${this.apiurl}/${child.id}`, child);
    }

    getById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }

    getChildrenByBirthdayMonth(month) {
        return this.get(`${this.apiurl}/birthday?month=${month}`);
    }

    getChildrenByBirthdayPeriod(startDate, endDate) {
        return this.get(`${this.apiurl}/birthday-period?startDate=${startDate}&endDate=${endDate}`);
    }


    getChildrenByMember(id) {
        return this.get(`${this.apiurl}/member/${id}`);
    }
    findChildren(ChildrenFilter) {
        if (ChildrenFilter.name ||
            ChildrenFilter.member
        ) {
            let params = `?`;
            let count = 0
            if (ChildrenFilter.name) {
                params += `name=${ChildrenFilter.name}`;
                count++;
            }

            if (ChildrenFilter.member) {
                if (count > 0)
                    params += `&`
                params += `member=${ChildrenFilter.member}`
                count++;
            }

            return this.get(`${this.apiurl}${params}`)
        }
        return this.get(`${this.apiurl}/all`);
    };

    deleteChildren(id) {
        return this.delete(`${this.apiurl}/${id}`);
    }
}
export default ChildrenService;