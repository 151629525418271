//eventService.js:

import ApiService from '../apiservice';

class EventService extends ApiService {
    constructor() {
        super('/event');
        this.api = new ApiService(); // Cria uma nova instância de ApiService
    }

    getById(id) {
        return this.api.get(`${this.apiurl}/${id}`);
    }

    getAll() {
        return this.api.get(`${this.apiurl}/all`);
    }

    new(event) {
        return this.api.post(`${this.apiurl}/new`, event);
    }

    update(event) {
        return this.api.put(`${this.apiurl}/${event.id}`, event);
    }

    findLowerThanMemberDegree(degree) {
        return this.api.get(`${this.apiurl}/member-lodge-degree/${degree}`);
    }

    async delete(id) {
        return this.api.delete(`${this.apiurl}/${id}`);
    }

    getEventsBetweenDates(startDate, endDate) {
        return this.get(`${this.apiurl}/between-dates?start=${startDate}&end=${endDate}`);
    }

    getNextEvent() {
        return this.get(`${this.apiurl}/next-event`);
    }

    generateQRCode(eventId) {
        return this.get(`${this.apiurl}/generate-qr/${eventId}`);
    }

}

export default EventService;
