import React, { useState, useEffect, useMemo, useContext } from "react";
import MemberService from "../../app/service/memberService";
import StatusService from "../../app/service/statusService";
import SpouseService from "../../app/service/spouseService";
import UserService from "../../app/service/userService.js";
import { messageSuccess, messageError } from "../../components/toastr";
import Card from "../../components/card";
import FormGroup from "../../components/form-group";
import FormRow from '../../components/form-row';
import Container from "../../components/container";
import SelectMenu from "../../components/selectMenu";
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ChildrenService from "../../app/service/childrenService.js";
import ChildrenDT from "./ChildrenDT.js";
import { AuthContext } from '../../security/AuthContext';
import RolePermissionService from "../../app/service/rolePermissionService";
import * as Constant from '../../config/constants';

const ChildrenList = () => {
    const memberService = useMemo(() => new MemberService(), []);
    const userService = useMemo(() => new UserService(), []);
    const childrenService = useMemo(() => new ChildrenService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const navigate = useNavigate();

    const { user, isAuthenticated } = useContext(AuthContext);

    const [name, setName] = useState('');
    const [member, setMember] = useState('');
    const [memberList, setMemberList] = useState([]);
    const [canEdit, setCanEdit] = useState(false);

    const mountList = (data) => {
        // Add the default option "SELECIONE" with value 0
        const defaultOption = { label: 'Selecione', value: '' };
        return [defaultOption, ...data.map(item => ({ label: item.description, value: item.id }))];
    }
    const handleRegisterNewChildren = () => {
        navigate('/children-register');
        return;
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleMemberChange = (event) => {
        setMember(event.target.value);
    };

    const handleSearch = async () => {
        const childrenFilter = {
            name: name,
            member: member
        };

        try {
            const response = await childrenService.findChildren(childrenFilter);
            if (Array.isArray(response.data) && response.data.length > 0) {

                // Ordenar os usuários de forma ascendente pelo nome
                const sortedChildren = response.data.sort((a, b) => a.name.localeCompare(b.name));

                setSearchResults(sortedChildren);
            } else {
                setSearchResults([]);
                messageError("Nenhum resultado encontrado.");
            }


        } catch (error) {
            messageError(`Erro ao realizar a busca. <br /> ${error.response.data}`);
        }

    };

    const handleClear = () => {
        setName('');
        setMember('');
        handleSearch();
    }

    const editChildren = async (id) => {
        localStorage.setItem("children-edit", id)
        navigate("/children-register")
    }

    const deleteChildren = async (id) => {
        try {
            const response = await childrenService.deleteChildren(id);
            if (response.status === 200) { // Supondo que o serviço retorne um status HTTP 200 em caso de sucesso
                messageSuccess("Sobrinho deletado com sucesso!");
            } else {
                messageError("Sobrinho não encontrada."); // Mudança para uma mensagem mais apropriada
            }
            handleSearch()
        } catch (error) {
            if (error.response) {
                // Assume-se que a mensagem de erro seja útil e derivada do servidor
                messageError(`Erro ao deletar sobrinho: ${error.response.data.message}`);
            } else {
                messageError("Erro ao conectar com o serviço de sobrinhos.");
            }
        }
    }


    const confirmDelete = (id) => {
        setSelectedUserId(id);
        setVisibleModal(true);
    };

    const handleConfirmation = (confirmed) => {
        setVisibleModal(false);

        if (confirmed) {
            deleteChildren(selectedUserId);
            handleSearch()
        } else {
            setSelectedUserId(null);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                localStorage.removeItem('children-edit');
                if (isAuthenticated) {
                    if (user) {
                        if (user.role !== 1) {
                            const responseMember = await memberService.getById(user.member);
                            const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_CHILDREN);
                            setCanEdit(responseCanEdit.data);
                        } else {
                            setVisible(true);
                        }
                    }
                } else {
                    messageError("Acesso negado. Procure um administrador.")
                    navigate('/login');
                }

                handleSearch();
                //Lista todos os membros
                const response = await memberService.getAll();
                const selectOption = { label: 'Selecione', value: '' };
                const listMembers = [
                    selectOption,
                    ...response.data.map(item => ({
                        label: item.name,
                        value: item.id
                    })).sort((a, b) => a.label.localeCompare(b.label))
                ];
                setMemberList(listMembers);

            } catch (error) {
                if (error.response && error.response.status === 400) {
                    messageError('Requisição inválida. Por favor, efetue o login.');
                } else {
                    messageError('Erro ao buscar informações.');
                }
            }
        };

        fetchData();
    }, []); // Run once when the component mounts


    return (
        <Container>
            <Card title="Consultar Sobrinhos">
                <div className="row">
                    <div className="col-lg-3">
                        <FormGroup label="Nome: " htmlFor="inputName">
                            <input
                                className="form-control"
                                type="text"
                                id="exampleInputName1"
                                name="name"
                                value={name}
                                placeholder="Digite o Nome"
                                onChange={handleNameChange}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-lg-3">
                        <FormGroup label="Irmão: " htmlFor="selectMember">
                            <SelectMenu
                                className="form-control"
                                list={memberList}
                                value={member}
                                onChange={handleMemberChange}
                            />
                        </FormGroup>
                    </div>
                </div>

                <br />
                <button className="btn btn-success" onClick={handleSearch}>Buscar</button>
                {' '}
                <button className="btn btn-danger" onClick={handleClear}>Limpar</button>
                {' '}
                {visible && (
                    <>
                        <button className="btn btn-info" onClick={handleRegisterNewChildren}>Cadastrar novo sobrinho</button>
                    </>
                )}
                <br /><br />
                <div className="row">
                    <div className="col-lg-12">
                        <ChildrenDT
                            children={searchResults}
                            editChildren={editChildren}
                            deleteChildren={confirmDelete}
                            canEdit={canEdit}
                        />
                    </div>
                </div>
                <Dialog
                    header="Confirmação"
                    visible={visibleModal}
                    style={{ width: '25vw' }}
                    onHide={() => handleConfirmation(false)}
                    modal={true}
                >
                    <p className="m-0">Deseja excluir o usuário?</p>
                    <br />
                    <div className="p-d-flex p-jc-end">
                        <button
                            className="btn btn-danger p-button-text"
                            onClick={() => handleConfirmation(true)}
                        >
                            Deletar
                        </button>
                        {' '}
                        <button
                            className="btn btn-secondary p-button-text"
                            onClick={() => handleConfirmation(false)}
                        >
                            Cancelar
                        </button>
                    </div>
                </Dialog>

            </Card>
        </Container>
    );
};

export default ChildrenList;
