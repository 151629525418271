//MemberDT.js:

import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button } from 'primereact/button';
//import StatusService from "../../app/service/statusService";
import UserService from "../../app/service/userService.js";
import { useNavigate } from 'react-router-dom';
import { messageError } from "../../components/toastr";
import { AuthContext } from '../../security/AuthContext';

const calculateAge = (birthDateString) => {
    if (!birthDateString) {
        return null;
    }

    // Converter a string em um objeto Date
    const birthDateObj = new Date(birthDateString);
    if (isNaN(birthDateObj)) {
        return null;
    }

    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }

    return age;
};


const formatWithLeadingZero = (value) => {
    return value < 10 ? `${value}` : value;
};

const formatDate = (dateString) => {
    if (!dateString) {
        return "Data de nascimento não disponível";
    }

    const [ano, mes, dia] = dateString.split('-');
    const formattedDia = formatWithLeadingZero(dia);
    const formattedMes = formatWithLeadingZero(mes);

    return `${formattedDia}/${formattedMes}/${ano}`;
};


const MemberDT = (props) => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const userService = useMemo(() => new UserService(), []);
    const [visible, setVisible] = useState(false);

    const rows = props.members.map((member, index) => {
        const age = calculateAge(member.dtBorn);
        const status = member.statusDescription ? member.statusDescription : "Não informado";
        return (
            <tr key={member.id} style={{ height: '30px' }}>
                <td style={{ padding: '5px', width: 'auto' }}>{index + 1}</td>
                <td style={{ padding: '5px', width: 'auto' }}>{member.cim}</td>
                <td style={{ padding: '5px', paddingLeft: '20px', width: 'auto' }}>{member.name}</td>
                <td style={{ padding: '5px', width: 'auto' }}>{member.email}</td>
                <td style={{ padding: '5px', width: 'auto' }}>{member.phoneCell}</td>
                <td style={{ padding: '5px', width: 'auto' }}>{member.lodgeDegree} {member.dtInstallation !== null ? `- MI` : ""}</td>
                <td style={{ padding: '5px', paddingLeft: '1vw', width: 'auto' }}>{member.dtRedemption !== null ? "Sim " : "Não"}</td>
                <td style={{ padding: '5px', width: 'auto' }}>{formatDate(member.dtBorn)} {age !== null ? `- ${age} anos` : ""}</td>
                <td style={{ padding: '5px', paddingLeft: '20px', padding: '5px', width: 'auto' }}>{status}</td>
                <td style={{ padding: '5px', width: 'auto' }}>
                    {(props.canViewAll || (user != null && user.member === member.id && props.canViewOwn)) && (
                        <>
                            <Button
                                icon="pi pi-file-pdf"
                                rounded outlined severity="success"
                                aria-label="Edit"
                                className="small-button btn btn-primary"
                                style={{ fontSize: '12px', padding: '3px 5px' }}
                                onClick={e => props.infoMember(member.id)}
                            />

                        </>
                    )}
                    {' '}
                    {props.canEdit && (
                        <>
                            <Button
                                icon="pi pi-pencil"
                                rounded outlined severity="success"
                                aria-label="Edit"
                                className="small-button btn btn-info"
                                style={{ fontSize: '12px', padding: '3px 5px' }}
                                onClick={e => props.editMember(member.id)}
                            />
                            {' '}
                            <Button
                                icon="pi pi-times"
                                rounded outlined severity="danger"
                                aria-label="Cancel"
                                className="small-button btn btn-danger"
                                style={{ fontSize: '12px', padding: '3px 5px' }}
                                onClick={e => props.deleteMember(member.id)}
                            />
                        </>

                    )}
                </td>
            </tr>
        );
    });

    return (
        <>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col" style={{ width: 'auto' }}>#</th>
                        <th scope="col" style={{ width: 'auto' }}>CIM</th>
                        <th scope="col" style={{ paddingLeft: '35px', width: 'auto' }}>Nome</th>
                        <th scope="col" style={{ width: 'auto' }}>Email</th>
                        <th scope="col" style={{ width: 'auto' }}>Celular</th>
                        <th scope="col" style={{ width: 'auto' }}>Grau</th>
                        <th scope="col" style={{ width: 'auto' }}>Remido</th>
                        <th scope="col" style={{ width: 'auto' }}>Data de Nasc. / Idade</th>
                        <th scope="col" style={{ width: 'auto' }}>Situação</th>
                        <th scope="col" style={{ paddingLeft: '1.5vw', width: 'auto' }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            <div className="mt-4">
                Total de Membros: {props.members.length} {/* Mostrando o total de membros */}
            </div>
        </>
    );
};

export default MemberDT;
