import React from "react";

const RolePermissionDT = ({ roles, functions, handlePermissionToggle }) => {

    return (
        <div style={{ border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', overflowX: 'auto' }}>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th style={{ borderRight: '1px solid #ddd' }}>#</th>
                        <th style={{ borderRight: '1px solid #ddd' }}>Tela</th>
                        <th style={{ borderRight: '1px solid #ddd' }}>Função</th>
                        {roles.map((role) => (
                            <th
                                key={role.id}
                                style={{
                                    textAlign: "center",
                                    borderLeft: '1px solid #ddd',
                                }}
                            >
                                {role.abreviation}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {functions.map((func, funcIndex) => (
                        <tr key={func.id}>
                            <td style={{ borderRight: '1px solid #ddd' }}>{funcIndex + 1}</td>
                            <td style={{ borderRight: '1px solid #ddd' }}>{func.screen}</td>
                            <td style={{ borderRight: '1px solid #ddd' }}>{func.description}</td>
                            {roles.map((role) => (
                                <td
                                    key={`${func.id}-${role.id}`}
                                    style={{
                                        textAlign: "center",
                                        borderLeft: '1px solid #ddd',
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        style={{ transform: 'scale(1.6)' }}
                                        checked={role.permissions.includes(func.id)}
                                        onChange={() => handlePermissionToggle(role.id, func.id)}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RolePermissionDT;
