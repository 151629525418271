import ApiService from '../apiservice';

class StateService extends ApiService {
    constructor() {
        super('/state');
    }

    getByCountry(id) {
        return this.get(`${this.apiurl}/byCountry/${id}`);
    }

    getStateById(id) {
        return this.get(`${this.apiurl}/${id}`);
    }

    getState(filter) {


        if (filter != null) {
            let params = `?`;
            let count = 0

            if (filter.id) {
                if (count > 0)
                    params += `&`
                params += `id=${filter.id}`
                count++;
            }

            if (filter.name) {
                params += `name=${filter.name}`;
                count++;
            }

            if (filter.countryId) {
                params += `countryId=${filter.countryId}`;
                count++;
            }

            if (filter.sigla) {
                if (count > 0)
                    params += `&`
                params += `sigla=${filter.sigla}`
            }
            return this.get(`${this.apiurl}${params}`)
        }
        return this.get(`${this.apiurl}/all`);

    }

}
export default StateService;