// event-register.js:

import React, { useState, useEffect, useMemo, useContext } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/form-group';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import EventService from '../../app/service/eventService';
import UserService from "../../app/service/userService";
import Event_TypeService from '../../app/service/event_TypeService';
import MemberService from '../../app/service/memberService';
import { messageSuccess, messageError } from '../../components/toastr';
import SelectMenu from "../../components/selectMenu";
import { Dialog } from 'primereact/dialog';
import DatePicker from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import { AuthContext } from '../../security/AuthContext';
import RolePermissionService from "../../app/service/rolePermissionService";
import * as Constant from '../../config/constants';

function RegisterEvent(props) {
    const eventService = useMemo(() => new EventService(), []);
    const event_TypeService = useMemo(() => new Event_TypeService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const userService = useMemo(() => new UserService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const [member, setMember] = useState('');
    const [type, setType] = useState('');
    const [degree, setDegree] = useState('');
    const [description, setDescription] = useState('');
    const [orderDay, setOrderDay] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [visible, setVisible] = useState(false);
    const eventPicked = localStorage.getItem('event-edit');
    const [eventTypes, setEventTypes] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [degreeList, setDegreeList] = useState([]);
    const selectOption = { label: 'Selecione', value: '0' };
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    registerLocale('pt-BR', ptBR);

    const handleStartDateChange = (date) => {
        setStartDate(date);
        if (date > endDate)
            setEndDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const confirmReservation = async () => {
        if (type === '' || type == null) {
            messageError("Favor escolher o tipo."); return;
        }
        if (startDate === '' || startDate == null) {
            messageError("Favor informar a data e hora de início do evento."); return;
        }
        if (startDate > endDate) {
            messageError("Datas inválidas. Favor corrigir."); return;
        }
        setVisible(true);
    }

    const handleConfirmation = async (confirmed) => {
        setVisible(false);

        if (confirmed) {

            if (
                (type == '' || type == null) ||
                (description == '' || description == null) ||
                (startDate == '' || startDate == null)
            ) {
                messageError("Corrija os campos obrigatórios.")
                return;
            }


            const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss");
            const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss");
            const eventData = {
                id: eventPicked ? eventPicked : null,
                type: type,
                description: description,
                orderDay: orderDay,
                degree: degree,
                dtStart: formattedStartDate,
                dtEnd: formattedEndDate
            }
            if (eventPicked) {
                await eventService.update(eventData).then((response) => {
                    if (props?.onEventSaved && typeof props.onEventSaved === 'function') {
                        props.onEventSaved();
                    } else {
                        navigate('/calendar');
                    }

                }).catch((error) => {
                    messageError(error.response.data);
                });
            } else {
                await eventService.new(eventData).then((response) => {
                    messageSuccess('Evento registrado com sucesso');
                    if (props?.onEventSaved && typeof props.onEventSaved === 'function') {
                        props.onEventSaved();
                    } else {
                        navigate('/calendar');
                    }  // Chama a função para fechar o modal
                }).catch((error) => {
                    messageError(error.response.data);
                });
            }

        } else {
            props.onEventCancel()
            return;
        }
    };

    const handleTypeChange = async (e) => {
        const selectedIndex = e.target.selectedIndex;
        const label = e.target.options[selectedIndex].text;
        const value = e.target.value;

        setType(value);
        setDescription(label); // Assuming you want to store the label as the description
    };

    useEffect(() => {
        const fetchData = async () => {
            //Verificar se o usuário está logado
            if (isAuthenticated) {
                if (user) {
                    if (user.role !== 1) {
                        const responseMember = await memberService.getById(user.member);
                        const responseCanEdit = await rolePermissionService.hasPermission(responseMember.data.lodgeRole, Constant.EDIT_EVENT);
                        if (!responseCanEdit.data) {
                            messageError("Acesso negado. Procure um administrador.")
                            navigate('/login');
                        }
                    }
                }
            } else {
                messageError("Acesso negado. Procure um administrador.")
                navigate('/login');
            }

            try {
                //Lista todos os tipos de evento
                const response = await event_TypeService.getAll();
                const listTypes = [
                    selectOption,
                    ...response.data.map(item => ({
                        label: item.type + ' ' + (item.description ? item.description : ''),
                        value: item.id
                    }))
                ];
                setEventTypes(listTypes);

                //Lista todos os membros
                const response2 = await memberService.getAll();
                const listMembers = [
                    selectOption,
                    ...response2.data.map(item => ({
                        label: item.name,
                        value: item.id
                    })).sort((a, b) => a.label.localeCompare(b.label))
                ];
                setMemberList(listMembers);

                //Lista todos os graus
                const listDegrees = [
                    { label: "Selecione", value: 0 },
                    { label: "1 - Aprendiz", value: 1 },
                    { label: "2 - Companheiro", value: 2 },
                    { label: "3 - Mestre", value: 3 },
                ];
                setDegreeList(listDegrees);


                if (eventPicked) {
                    const responseEvent = await eventService.getById(eventPicked);
                    const convertArrayToDate = (dateArray) => {
                        return new Date(dateArray[0], dateArray[1] - 1, dateArray[2], dateArray[3], dateArray[4]);
                    };
                    setType(responseEvent.data.type.id);
                    setDescription(responseEvent.data.description);
                    setDegree(responseEvent.data.degree);
                    setOrderDay(responseEvent.data.orderDay)
                    setStartDate(convertArrayToDate(responseEvent.data.dtStart));
                    setEndDate(convertArrayToDate(responseEvent.data.dtEnd));
                }
            } catch (error) {
                messageError("Erro ao buscar os tipos de evento");
            }
        };
        fetchData();
    }, []);

    return (
        <Container>
            <Card title="Editar Evento">
                <div className="row">
                    <div className="col-lg-16">
                        <div className="bs-component">
                            <fieldset>
                                <FormGroup label="Tipo: *" htmlFor="selectType">
                                    <br />
                                    <SelectMenu
                                        className="form-control"
                                        list={eventTypes}
                                        value={type}
                                        onChange={handleTypeChange}
                                    />
                                </FormGroup>
                                <br />
                                <FormGroup label="Grau: *" htmlFor="selectDegree">
                                    <br />
                                    <SelectMenu
                                        className="form-control"
                                        list={degreeList}
                                        value={degree}
                                        onChange={(e) => setDegree(e.target.value)}
                                        disabled={type === '7'}
                                    />
                                </FormGroup>
                                <br />
                                <div style={{ display: 'none' }}>
                                    <FormGroup label="Irmão: *" htmlFor="selectMember">
                                        <br />
                                        <SelectMenu
                                            className="form-control"
                                            list={memberList}
                                            value={member}
                                            onChange={(e) => setMember(e.target.value)}
                                        />
                                    </FormGroup>
                                </div>
                                <FormGroup label="Data/Hora de INÍCIO do evento: *" htmlFor="inputStartDate">
                                    <br />
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        locale="pt-BR"  // Defina o locale para português brasileiro
                                        timeCaption="Horário"
                                    />
                                </FormGroup>
                                <br />
                                <FormGroup label="Data/Hora do FIM do evento: *" htmlFor="inputEndDate">
                                    <br />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        locale="pt-BR"  // Defina o locale para português brasileiro
                                        timeCaption="Horário"
                                    />
                                </FormGroup>
                                <br />
                            </fieldset>
                        </div>
                    </div>
                </div>

                {/* Linha separada para o textarea "Ordem do dia" */}
                <div className="row mt-3">
                    <div className="col-lg-16">
                        <FormGroup label="Ordem do dia: " htmlFor="inputOrderDay">
                            <textarea
                                className="form-control"
                                id="orderDayInput1"
                                name="annotation"
                                value={orderDay}
                                placeholder="Qual a ordem do dia?"
                                onChange={(e) => setOrderDay(e.target.value)}
                                style={{ height: 'auto', width: '100%' }} // Garantir 100% da largura
                                rows="5"
                            />
                        </FormGroup>
                    </div>
                </div>

                <div className="mt-3">
                    <button
                        className="btn btn-success me-3" // 'me-4' adiciona margem à direita
                        onClick={confirmReservation}
                    >
                        Confirmar
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => navigate('/calendar')}
                    >
                        Cancelar
                    </button>
                </div>

                <Dialog
                    header="Confirmação"
                    visible={visible}
                    style={{ width: '25vw' }}
                    onHide={() => setVisible(false)}
                    modal={true}
                >
                    <p className="m-0">Deseja cadastrar o evento?</p>
                    <br />
                    <div className="p-d-flex p-jc-end">
                        <button
                            className="btn btn-success p-button-text"
                            onClick={() => handleConfirmation(true)}
                        >
                            Confirmar
                        </button>
                        {' '}
                        <button
                            className="btn btn-secondary p-button-text"
                            onClick={() => handleConfirmation(false)}
                        >
                            Cancelar
                        </button>
                    </div>
                </Dialog>
            </Card>
        </Container>
    );
}

export default RegisterEvent;