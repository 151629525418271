import ApiService from '../apiservice';

class CountryService extends ApiService {
    constructor() {
        super('/country');
    }

    getAll() {
        return this.get(`${this.apiurl}/all`);
    }

    getCountry(filter) {


        if (filter != null) {
            let params = `?`;
            let count = 0

            if (filter.name) {
                params += `name=${filter.name}`;
                count++;
            }

            if (filter.id) {
                if (count > 0)
                    params += `&`
                params += `id=${filter.id}`
                count++;
            }

            if (filter.sigla) {
                if (count > 0)
                    params += `&`
                params += `sigla=${filter.sigla}`
            }
            return this.get(`${this.apiurl}${params}`)
        }
        return this.get(`${this.apiurl}/all`);

    }

}
export default CountryService;