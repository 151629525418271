//Config.js:

import React, { useState, useEffect, useMemo, useContext } from "react";
import UserService from "../../app/service/userService.js";
import { messageSuccess, messageError } from "../../components/toastr";
import Card from "../../components/card";
import Container from "../../components/container";
import { useNavigate } from 'react-router-dom';
import { Button, Tab, Tabs } from 'react-bootstrap';
import LodgeRoleService from "../../app/service/lodgeRoleService.js";
import AppFunctionsService from "../../app/service/appFunctionsService.js";
import Event_TypeService from "../../app/service/event_TypeService.js";
import RolePermissionService from "../../app/service/rolePermissionService.js";
import EventTypeDT from "../eventType/eventTypeDT.js";
import RolePermissionDT from "../role/role-permissionDT.js";
import { AuthContext } from '../../security/AuthContext';
import MemberService from "../../app/service/memberService.js";

const Config = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const lodgeRoleService = useMemo(() => new LodgeRoleService(), []);
    const appFunctionsService = useMemo(() => new AppFunctionsService(), []);
    const eventTypeService = useMemo(() => new Event_TypeService(), []);
    const rolePermissionService = useMemo(() => new RolePermissionService(), []);
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("mandatoryAttendance");
    const [eventTypesList, setEventTypesList] = useState([]);
    const [lodgeRoles, setLodgeRoles] = useState([]);
    const [functions, setFunctions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!isAuthenticated || user.id !== 1) {

                    const responseMember = await memberService.getById(user.member)
                    if (responseMember.data.lodgeRole !== 1) {
                        navigate('/login');
                        return;
                    }
                }

                // Buscar tipos de sessão
                const allEventTypes = await eventTypeService.getAll();
                setEventTypesList(allEventTypes.data);

                // Buscar cargos e funções
                const rolesResponse = await lodgeRoleService.getAll();
                const functionsResponse = await appFunctionsService.getAll();
                // order functions by screen
                functionsResponse.data.sort((a, b) => a.screen.localeCompare(b.screen));
                // Buscar permissões associadas
                const rolesWithPermissions = await Promise.all(
                    rolesResponse.data.map(async (role) => {
                        try {
                            const permissionsResponse = await rolePermissionService.getPermissionsByRole(role.id);

                            // Verificar se permissionsResponse.data é um array
                            if (Array.isArray(permissionsResponse.data)) {
                                // Obter IDs das funções com permissão
                                const permissions = permissionsResponse.data.map(p => p.appFunctionId);
                                return { ...role, permissions };
                            } else {
                                // Se não for um array, definir permissions como array vazio
                                return { ...role, permissions: [] };
                            }
                        } catch (error) {
                            // Se não houver permissões, definir array vazio
                            return { ...role, permissions: [] };
                        }
                    })
                );

                // **Ajuste para limitar aos 8 primeiros cargos e adicionar o cargo com id 0 por último**

                // Obter o cargo com id 0
                const roleIdZero = rolesWithPermissions.find(role => role.id === 0);
                const roleId99 = rolesWithPermissions.find(role => role.id === 99);
                // Remover o cargo com id 0 da lista
                let rolesWithoutZero = rolesWithPermissions.filter(role => role.id !== 0 && role.id < 8);

                //Ordene por role.id
                rolesWithoutZero.sort((a, b) => a.id - b.id);

                // Obter os primeiros 8 cargos
                const firstEightRoles = rolesWithoutZero.slice(0, 8);

                // Combinar os cargos: os primeiros 8 e depois o cargo com id 0
                let limitedRoles = [...firstEightRoles];

                if (roleIdZero) {
                    limitedRoles.push(roleIdZero);
                }

                if (roleId99) {
                    limitedRoles.push(roleId99);
                }
                // Definir o estado com os cargos limitados
                setLodgeRoles(limitedRoles);

                // Definir as funções
                setFunctions(functionsResponse.data);

            } catch (error) {
                console.error('Erro ao carregar dados: ', error);
                messageError('Erro ao carregar dados.');
            }
        };

        fetchData();
    }, [isAuthenticated, navigate]);

    const handlePermissionToggle = async (roleId, appFunctionId) => {
        try {
            const role = lodgeRoles.find((r) => r.id === roleId);
            const hasPermission = role.permissions.includes(appFunctionId);

            const permission = {
                roleId,
                appFunctionId
            };

            await rolePermissionService.changePermission(permission);
            messageSuccess("Permissão alterada com sucesso.");

            // Atualizar as permissões no estado
            setLodgeRoles((prevRoles) =>
                prevRoles.map((r) =>
                    r.id === roleId
                        ? {
                            ...r,
                            permissions: hasPermission
                                ? r.permissions.filter((id) => id !== appFunctionId)
                                : [...r.permissions, appFunctionId],
                        }
                        : r
                )
            );
        } catch (error) {
            messageError('Erro ao atualizar permissões.');
        }
    };

    const EditEventType = (id) => {
        localStorage.setItem('eventType-edit', JSON.stringify(id));
        navigate('/eventType-register');
    };

    const DeleteEventType = async (id) => {
        try {
            await eventTypeService.deleteType(id);
            setEventTypesList((prev) => prev.filter((type) => type.id !== id));
            messageSuccess('Tipo de Sessão apagado com sucesso.');
        } catch (error) {
            messageError(error.response.data || 'Erro ao apagar tipo de sessão.');
        }
    };

    return (
        <Container>
            <Card title="Configurações">
                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="mb-3"
                >
                    {/* Aba Presenças Obrigatórias */}
                    <Tab eventKey="mandatoryAttendance" title="Presença Obrigatória">
                        <div style={{ paddingTop: '2vh' }}>
                            <h4>Tipos de Sessões</h4>
                            <EventTypeDT
                                types={eventTypesList}
                                editEventType={EditEventType}
                                deleteEventType={DeleteEventType}
                            />
                            <Button
                                className="btn-sm btn btn-info"
                                onClick={() => navigate('/eventType-register')}
                                style={{ fontSize: "14px", height: '35px', marginTop: '2vh', marginRight: '0.5vw' }}
                            >
                                Cadastrar novo
                            </Button>
                        </div>
                    </Tab>

                    {/* Aba Permissões */}
                    <Tab eventKey="permissions" title="Permissões">
                        <div style={{ paddingTop: '2vh' }}>
                            <h4>Permissões por Cargo</h4>
                            {lodgeRoles.length > 0 && functions.length > 0 ? (
                                <RolePermissionDT
                                    roles={lodgeRoles}
                                    functions={functions}
                                    handlePermissionToggle={handlePermissionToggle}
                                />
                            ) : (
                                <p>Carregando permissões...</p>
                            )}
                        </div>
                    </Tab>
                </Tabs>
            </Card>
        </Container>
    );
};

export default Config;
