import React, { useEffect, forwardRef } from 'react';
import MemberService from '../app/service/memberService';
import PRMTSService from '../app/service/prmtsService';

const AttendanceCertificate = forwardRef(({ event, visitor, onRenderComplete }, ref) => {
    const memberService = new MemberService();
    const prmtsService = new PRMTSService();

    useEffect(() => {
        const generateCertificateImage = async () => {
            try {
                const responseDaytime = await prmtsService.getByType('DAYTIME-SESSION');
                const sessionDayTime = responseDaytime.data.description;

                const canvas = ref.current;
                if (!canvas) {
                    console.error('Canvas não encontrado!');
                    return;
                }

                const context = canvas.getContext('2d');
                if (!context) {
                    console.error('Contexto do canvas não encontrado!');
                    return;
                }

                const memberRole1 = await memberService.getMemberByLodgeRole(1);
                const memberRole7 = await memberService.getMemberByLodgeRole(7);

                // Definir o tamanho do canvas
                const canvasWidth = 595;
                const canvasHeight = 842;
                canvas.width = canvasWidth;
                canvas.height = canvasHeight;

                // Desenhar o fundo branco
                context.fillStyle = '#FFFFFF';
                context.fillRect(0, 0, canvasWidth, canvasHeight);

                // Carregar a marca d'água
                const watermark = new Image();
                const watermarkSrc = `${process.env.PUBLIC_URL}/assets/marca_dagua_1_normal.png`;
                watermark.src = watermarkSrc;

                // Esperar a imagem carregar antes de desenhar
                await new Promise((resolve, reject) => {
                    watermark.onload = () => resolve();
                    watermark.onerror = () => {
                        console.error('Erro ao carregar a marca d\'água.');
                        reject(new Error('Erro ao carregar a marca d\'água.'));
                    };
                });

                // Calcular as dimensões para preencher a folha
                const watermarkScale = 0.9; // 90% da largura do canvas
                const watermarkWidth = canvasWidth * watermarkScale;
                const watermarkHeight = (watermark.height * watermarkWidth) / watermark.width;

                // Centralizar a marca d'água
                const xPositionWatermark = (canvasWidth - watermarkWidth) / 2;
                const yPositionWatermark = (canvasHeight - watermarkHeight) / 2;

                // Definir a opacidade da marca d'água
                context.globalAlpha = 0.6; // 60% de opacidade

                // Desenhar a marca d'água no canvas
                context.drawImage(watermark, xPositionWatermark, yPositionWatermark, watermarkWidth, watermarkHeight);

                // Resetar a opacidade para futuros desenhos
                context.globalAlpha = 1.0;

                // Agora, desenhar os textos sobre a marca d'água
                context.fillStyle = '#000000';
                context.textAlign = 'center';

                // Título
                context.font = '44px "GreatVibes"';
                context.fillText('Loja Maçônica', canvasWidth / 2, 80);
                context.fillText('Professor Hermínio Blackman', canvasWidth / 2, 130);
                context.fillText('nº 1761', canvasWidth / 2, 180);

                // Subtítulo
                context.font = '20px "Times New Roman"';
                context.fillText('FEDERADA AO GOB e JURISDICIONADA AO GOB-ES', canvasWidth / 2, 210);
                context.fillText(`R.E.A.A. - Reuniões às ${sessionDayTime}`, canvasWidth / 2, 230);
                context.fillText('Rua Presidente Lima, 1010, Centro, Vila Velha/ES', canvasWidth / 2, 250);
                context.fillText('CEP 29.100-330', canvasWidth / 2, 270);

                // Título do Certificado
                context.font = '32px "Times New Roman"';
                context.fillText('CERTIFICADO DE PRESENÇA', canvasWidth / 2, 325);

                // Inicializar tamanhos de fonte e variáveis
                let normalFontSize = 30;
                let boldItalicFontSize = 34;
                const minFontSize = 12; // Tamanho mínimo da fonte
                const yPositionStart = 380; // Posição Y inicial do texto
                const bottomMargin = 50; // Margem inferior
                const availableHeight = canvasHeight - yPositionStart - bottomMargin;
                let totalHeight;
                let lines;

                // Corpo do certificado
                const leftMargin = 40;
                const rightMargin = 40;
                const maxTextWidth = canvasWidth - leftMargin - rightMargin;

                const dtNow = new Date();
                const meses = [
                    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
                    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
                ];

                const dia = dtNow.getDate();
                const mesAtualEscrito = meses[dtNow.getMonth()];
                const ano = dtNow.getFullYear();
                const dataFormatada = `${dia} de ${mesAtualEscrito} de ${ano}`;

                // Data do Evento
                const mesEscrito = meses[event.dtStart[1] - 1];
                const dataEvento = `${event.dtStart[2]} de ${mesEscrito} de ${event.dtStart[0]}`;

                // Dividir o texto para aplicar estilos
                const textBeforeName = `Certificamos que o Ir∴ `;
                const visitorName = `${visitor.name}`;
                const textBetweenNameAndEvent = ` - CIM: ${visitor.cim} do quadro da Loja: ${visitor.lodge}, do Or∴ de ${visitor.lodgeState}, concedeu-nos a honra de sua visita na `;
                const eventDescription = `${event.description} de grau ${event.degree}`;
                const textBeforeDate = ` realizada no dia `;
                const eventDate = `${dataEvento}`;
                const textAfterDate = `. Contribuindo para estreitar os laços de fraternidade entre os maçons.`;

                // Função para criar objetos de palavras com fonte
                const createWordObjects = (text, font) => {
                    const words = text.trim().split(' ');
                    return words.map(word => ({ text: word, font: font }));
                };

                // Função para envolver texto
                const wrapText = (wordObjs, maxWidth) => {
                    const lines = [];
                    let currentLineWords = [];
                    let currentLineWidth = 0;

                    wordObjs.forEach((wordObj, index) => {
                        const wordText = wordObj.text;
                        const wordFont = wordObj.font;

                        context.font = wordFont;
                        const wordWidth = context.measureText(wordText).width;
                        const spaceWidth = context.measureText(' ').width;

                        if (currentLineWidth + wordWidth + spaceWidth > maxWidth && currentLineWords.length > 0) {
                            lines.push({
                                words: currentLineWords,
                                isLastLine: false
                            });
                            currentLineWords = [wordObj];
                            currentLineWidth = wordWidth + spaceWidth;
                        } else {
                            currentLineWords.push(wordObj);
                            currentLineWidth += wordWidth + spaceWidth;
                        }
                    });

                    if (currentLineWords.length > 0) {
                        lines.push({
                            words: currentLineWords,
                            isLastLine: true
                        });
                    }

                    return lines;
                };

                // Função para calcular a altura total do texto
                const computeTotalHeight = () => {
                    // Atualizar fontes
                    const normalFont = `${normalFontSize}px "Times New Roman"`;
                    const boldItalicFont = `italic bold ${boldItalicFontSize}px "Times New Roman"`;
                    const lineHeight = normalFontSize + 2; // Ajuste conforme necessário

                    // Recriar objetos de palavras
                    const wordsBeforeName = createWordObjects(textBeforeName, normalFont);
                    const wordsVisitorName = createWordObjects(visitorName, boldItalicFont);
                    const wordsBetweenNameAndEvent = createWordObjects(textBetweenNameAndEvent, normalFont);
                    const wordsEventDescription = createWordObjects(eventDescription, boldItalicFont);
                    const wordsBeforeDate = createWordObjects(textBeforeDate, normalFont);
                    const wordsEventDate = createWordObjects(eventDate, boldItalicFont);
                    const wordsAfterDate = createWordObjects(textAfterDate, normalFont);

                    const allWordObjs = [
                        ...wordsBeforeName,
                        ...wordsVisitorName,
                        ...wordsBetweenNameAndEvent,
                        ...wordsEventDescription,
                        ...wordsBeforeDate,
                        ...wordsEventDate,
                        ...wordsAfterDate
                    ];

                    // Envolver texto
                    lines = wrapText(allWordObjs, maxTextWidth);

                    // Calcular altura total
                    totalHeight = lines.length * lineHeight;

                    return { normalFont, boldItalicFont, lineHeight };
                };

                let fonts;

                // Ajustar tamanhos de fonte até que o texto caiba no espaço disponível
                do {
                    fonts = computeTotalHeight();
                    if ((yPositionStart + totalHeight) > 670 && normalFontSize > minFontSize) {
                        normalFontSize -= 1;
                        boldItalicFontSize -= 1;
                    } else {
                        break;
                    }
                } while (true);

                // Desenhar o texto no canvas com os tamanhos de fonte ajustados
                context.textAlign = 'left';
                let yPosition = yPositionStart;
                const { normalFont, boldItalicFont, lineHeight } = fonts;

                lines.forEach((lineObj) => {
                    const lineWords = lineObj.words;
                    const isLastLine = lineObj.isLastLine;

                    // Calcular a largura total das palavras
                    let totalWordsWidth = 0;
                    lineWords.forEach((wordObj) => {
                        context.font = wordObj.font;
                        totalWordsWidth += context.measureText(wordObj.text).width;
                    });

                    // Número de espaços entre as palavras
                    const numberOfSpaces = lineWords.length - 1;
                    const baseSpaceWidth = context.measureText(' ').width;

                    let extraSpacePerGap = baseSpaceWidth;
                    if (!isLastLine && numberOfSpaces > 0) {
                        // Calcular o espaço extra a ser distribuído
                        const extraSpace = maxTextWidth - totalWordsWidth - (baseSpaceWidth * numberOfSpaces);
                        extraSpacePerGap = baseSpaceWidth + (extraSpace / numberOfSpaces);
                    }

                    let xPosition = leftMargin;
                    lineWords.forEach((wordObj, index) => {
                        const wordText = wordObj.text;
                        const wordFont = wordObj.font;

                        context.font = wordFont;
                        context.fillText(wordText, xPosition, yPosition);

                        const wordWidth = context.measureText(wordText).width;
                        if (index < lineWords.length - 1) {
                            xPosition += wordWidth + extraSpacePerGap;
                        } else {
                            xPosition += wordWidth;
                        }
                    });
                    yPosition += lineHeight;
                });

                // Texto final
                yPosition += 20;
                context.textAlign = 'center';
                context.font = '26px "Times New Roman"';
                context.fillText(`Or∴ de Vila Velha/ES, ${dataFormatada}.`, canvasWidth / 2, yPosition);
                yPosition += 60;
                context.fillText(`V∴M∴ ${memberRole1.data.name}`, canvasWidth / 2, yPosition);
                yPosition += 60;
                context.fillText(`Chanc∴ ${memberRole7.data.name}`, canvasWidth / 2, yPosition);
                context.font = '14px "Times New Roman"';
                context.fillText('EMITIDO DIGITALMENTE', canvasWidth / 2, canvasHeight - 5);

                // Chamar a função de callback após gerar o certificado
                if (onRenderComplete) {
                    onRenderComplete();
                }

            } catch (error) {
                console.error('Erro ao gerar a imagem do certificado:', error);
            }
        };

        generateCertificateImage();
    }, [event, visitor, ref, onRenderComplete]);

    return <canvas ref={ref} style={{ display: 'none' }} />;
});

export default AttendanceCertificate;
