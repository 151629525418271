import React, { useState, useEffect, useMemo } from "react";
import { Button } from 'primereact/button';
import UserService from "../../app/service/userService.js";
import { useNavigate } from 'react-router-dom';
import MemberService from "../../app/service/memberService.js";
import { messageSuccess, messageError } from "../../components/toastr";
import TitleService from "../../app/service/titleService.js";

const formatWithLeadingZero = (value) => {
    return value < 10 ? `0${value}` : value;
};

const formatDate = (birthDateArray) => {
    if (!birthDateArray || birthDateArray.length !== 3) {
        return "Data de nascimento não disponível";
    }

    const [ano, mes, dia] = birthDateArray;
    const formattedDia = formatWithLeadingZero(dia);
    const formattedMes = formatWithLeadingZero(mes);

    return `${formattedDia}/${formattedMes}/${ano}`;
};

const MedalsDT = (props) => {
    const navigate = useNavigate();
    const userService = useMemo(() => new UserService(), []);
    const memberService = useMemo(() => new MemberService(), []);
    const titleService = useMemo(() => new TitleService(), []);
    const [rows, setRows] = useState([]);  // State para armazenar os rows
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (props.medals.length === 0) return;

        const fetchData = async () => {
            processMedals(props.medals);
        };

        fetchData();
    }, [props.medals]);

    async function processMedals(medals) {
        const visible = props.medals.length > 0;
        const medalsDetails = await Promise.all(medals.map(async (medal) => {
            const responseTitle = await titleService.getById(medal.title);
            return {
                ...medal,
                titleDetails: responseTitle.data,
            };
        }));

        // Ordenar as medalhas pela data de concessão (dtReceived)
        medalsDetails.sort((a, b) => new Date(a.dtReceived) - new Date(b.dtReceived));

        const newRows = medalsDetails.map((medal, index) => {
            return (
                <tr key={medal.id}>
                    <td style={{ width: '5%' }}>{index + 1}</td>
                    <td style={{ width: '35%' }}>{medal.titleDetails?.description} - {medal.titleDetails?.minYears} anos</td>
                    <td style={{ width: '25%' }}>{medal.titleDetails?.agency}</td>
                    <td style={{ width: '25%' }}>{formatDate(medal.dtReceived)}</td>
                    {visible && (
                        <td style={{ width: '10%' }}>
                            <Button
                                icon="pi pi-times"
                                rounded
                                outlined
                                severity="danger"
                                className="small-button btn btn-danger"
                                style={{ fontSize: '12px', padding: '3px 5px' }}
                                onClick={e => props.deleteMedal(medal.id)}
                            />
                        </td>
                    )}
                </tr>
            );
        });

        setRows(newRows);
    }

    return (
        <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '70%' }}>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th style={{ width: '5%' }}>#</th>
                        <th style={{ width: '35%' }}>Título / Condecoração</th>
                        <th style={{ width: '25%' }}>Emissor</th>
                        <th style={{ width: '25%' }}>Data de Concessão</th>
                        {visible && (
                            <th style={{ width: '10%' }}>Ações</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            <div className="mt-4">
                Total de Condecorações: {props.medals.length}
            </div>
        </div>
    );
};

export default MedalsDT;
